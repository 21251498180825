import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";

export default function TimeZoneControl(props: {
  className?: string;
  value: string;
  onChange: (event: SelectChangeEvent) => void;
}) {
  return (
    <FormControl className={props.className} fullWidth>
      <InputLabel id="time-zone-label">Time Zone</InputLabel>
      <Select
        variant="outlined"
        labelId="time-zone-label"
        id="timeZone"
        name="timeZone"
        label="Time Zone"
        value={props.value}
        onChange={props.onChange}
      >
        {(Intl as any).supportedValuesOf("timeZone").map((timeZone: string) => {
          const offset = new Intl.DateTimeFormat("en", {
            timeZone: timeZone,
            timeZoneName: "shortOffset",
          })
            .formatToParts()
            .find((part) => part.type === "timeZoneName")?.value;
          return (
            <MenuItem value={timeZone}>
              {timeZone} {offset && `(${offset})`}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
