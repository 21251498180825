import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState, useContext } from "react";
import Title from "../components/title";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@mui/material";
import LoadingBackdrop from "../components/loading-backdrop";
import { ErrorContext } from "../components/app-routes";
import { seedsApi } from "../services/api/seeds";
import { Seed, SeedLot } from "../models/seed";
import { DateTime } from "luxon";
import mixpanel from "mixpanel-browser";
import { goBackOrTo } from "../utils/history";
import SeedForm, { SeedFormValues } from "../components/seed-form";
import { StripedDataGrid } from "../components/striped-data-grid";
import { GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import * as yup from "yup";
import { Formik, Form, FastField, FieldProps } from "formik";
import { AppTextField } from "../components/text-field";
import { validationMessages } from "../utils/validation/messages";
import { seedLotsApi } from "../services/api/seed-lots";
import ConfirmDialog from "../components/confirm-dialog";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  actions: {
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

export interface SeedLotFormValues {
  number: string;
  supplier: string;
  archived: boolean;
  notes: string;
}

const SeedLotDialog = (props: {
  open: boolean;
  actionLabel: string;
  initialValues: SeedLotFormValues;
  onSubmit: (values: SeedLotFormValues) => void;
  onCancel: () => void;
}) => {
  return (
    <Dialog open={props.open} maxWidth={"sm"} fullWidth>
      <Formik
        initialValues={props.initialValues}
        validationSchema={yup.object({
          number: yup.string().required(validationMessages.Required),
          supplier: yup.string(),
          archived: yup.boolean(),
          notes: yup.string(),
        })}
        onSubmit={props.onSubmit}
      >
        {(form) => (
          <>
            <Form noValidate>
              <DialogTitle>Seed Lot</DialogTitle>
              <DialogContent>
                <Box>
                  <FormControl
                    variant="standard"
                    error={
                      form.touched.archived && Boolean(form.errors.archived)
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="archived"
                          color="primary"
                          onChange={form.handleChange}
                          checked={form.values.archived}
                        />
                      }
                      label="Archived"
                    />
                    {form.touched.archived && form.errors.archived && (
                      <FormHelperText>{form.errors.archived}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <FastField name="number">
                  {(fieldProps: FieldProps) => (
                    <AppTextField {...fieldProps} autoFocus label="Number" />
                  )}
                </FastField>
                <FastField
                  component={AppTextField}
                  name="supplier"
                  label="Supplier"
                />
                <FastField name="notes">
                  {(fieldProps: FieldProps) => (
                    <AppTextField
                      {...fieldProps}
                      multiline
                      label="Notes"
                      maxRows={4}
                      rows={4}
                    />
                  )}
                </FastField>
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={props.onCancel}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  {props.actionLabel}
                </Button>
              </DialogActions>
            </Form>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default function SeedPage() {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [busy, setBusy] = useState(false);
  const [seed, setSeed] = useState<Seed | null>(null);
  const [seedLots, setSeedLots] = useState<SeedLot[] | null>(null);
  const [seedLotChangeId, setSeedLotChangeId] = useState<number>(0);
  const [saved, setSaved] = useState(false);
  const [showAddLotDialog, setShowAddLotDialog] = useState(false);
  const { handleError } = useContext(ErrorContext);
  const [
    openConfirmTrashSeedLotDialog,
    setOpenConfirmTrashSeedLotDialog,
  ] = useState(false);
  const [seedLotToAction, setSeedLotToAction] = useState<SeedLot | null>(null);
  const [openEditLotDialog, setOpenEditLotDialog] = useState(false);

  useEffect(() => {
    async function getSeed() {
      setBusy(true);
      try {
        const seed = await seedsApi.getSingle(parseInt(id));
        setSeed(seed);
      } catch (error) {
        handleError(error);
      } finally {
        setBusy(false);
      }
    }
    getSeed();
  }, [id]);

  useEffect(() => {
    async function getSeedLots() {
      if (!seed) {
        return;
      }

      setBusy(true);
      try {
        const seedLots = await seedLotsApi.getAll(seed);
        setSeedLots(seedLots);
      } catch (error) {
        handleError(error);
      } finally {
        setBusy(false);
      }
    }
    getSeedLots();
  }, [seed, seedLotChangeId]);

  const handleSubmit = async (values: SeedFormValues) => {
    if (!seed) {
      throw new Error("Invalid state");
    }

    setBusy(true);

    try {
      await seedsApi.update({
        ...seed,
        name: values.name,
        variety: values.variety,
        notes: values.notes,
        archivedAt:
          !!seed.archivedAt === values.archived
            ? seed.archivedAt
            : values.archived
            ? DateTime.now().toISO()
            : null,
      });

      setSaved(true);

      mixpanel.track("Update Seed");

      goBackOrTo(history, "/home/seeds");
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  const onClickCancel = () => {
    goBackOrTo(history, "/home/seeds");
  };

  const onClickAddLot = () => {
    setShowAddLotDialog(true);
  };

  const onSubmitAddLotDialog = async (values: SeedLotFormValues) => {
    if (!seed) {
      throw new Error("Invalid state");
    }

    setBusy(true);

    try {
      await seedLotsApi.add(seed, {
        id: 0,
        createdAt: null,
        number: values.number,
        supplier: values.supplier,
        archivedAt: values.archived ? DateTime.now().toISO() : null,
        notes: values.notes,
      });

      setSeedLotChangeId(seedLotChangeId + 1);
      setShowAddLotDialog(false);

      mixpanel.track("Update Seed Lot");
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  const onCloseTrashSeedLotDialog = async (ok: boolean | undefined) => {
    if (!seed || !seedLotToAction) {
      throw new Error("Invalid state");
    }

    setOpenConfirmTrashSeedLotDialog(false);

    if (!ok) {
      return;
    }

    setBusy(true);

    try {
      await seedLotsApi.trash(seed, seedLotToAction);
      setSeedLotChangeId(seedLotChangeId + 1);
    } catch (error) {
      handleError(error);
    } finally {
      setSeedLotToAction(null);
      setBusy(false);
    }
  };

  const onClickTrashSeedLot = (id: number) => {
    if (!seedLots) {
      throw new Error("Invalid state");
    }

    const seedLot = seedLots.find((v) => v.id === id);

    if (!seedLot) {
      throw new Error("Invalid state");
    }

    setSeedLotToAction(seedLot);
    setOpenConfirmTrashSeedLotDialog(true);
  };

  const onSubmitEditLotDialog = async (values: SeedLotFormValues) => {
    if (!seed || !seedLotToAction) {
      throw new Error("Invalid state");
    }

    setOpenEditLotDialog(false);
    setBusy(true);

    try {
      await seedLotsApi.update(seed, {
        ...seedLotToAction,
        number: values.number,
        supplier: values.supplier,
        archivedAt:
          seedLotToAction.archivedAt && values.archived
            ? seedLotToAction.archivedAt
            : values.archived
            ? DateTime.now().toISO()
            : null,
        notes: values.notes,
      });
      setSeedLotChangeId(seedLotChangeId + 1);
    } catch (error) {
      handleError(error);
    } finally {
      setSeedLotToAction(null);
      setBusy(false);
    }
  };

  const onClickEditSeedLot = (id: number) => {
    if (!seedLots) {
      throw new Error("Invalid state");
    }

    const seedLot = seedLots.find((v) => v.id === id);

    if (!seedLot) {
      throw new Error("Invalid state");
    }

    setSeedLotToAction(seedLot);
    setOpenEditLotDialog(true);
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Title>Seed</Title>
          {seed && seedLots && (
            <>
              <SeedForm
                initialValues={{
                  archived: !!seed.archivedAt,
                  id: seed.id,
                  name: seed.name,
                  notes: seed.notes,
                  variety: seed.variety,
                }}
                saved={saved}
                onSubmit={handleSubmit}
              >
                <Box mt={2} className={classes.actions}>
                  <Button
                    variant="outlined"
                    disabled={busy}
                    onClick={onClickCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={busy}
                  >
                    Save
                  </Button>
                </Box>
              </SeedForm>
              <Box mt={2}>
                <Typography variant="h5" color="black" gutterBottom>
                  Lots
                </Typography>
                <Button
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={onClickAddLot}
                >
                  Add lot
                </Button>
                {seedLots.length > 0 && (
                  <StripedDataGrid
                    columns={[
                      {
                        field: "number",
                        headerName: "Number",
                        width: 200,
                      },
                      {
                        field: "supplier",
                        headerName: "Supplier",
                        width: 200,
                      },
                      {
                        field: "archived",
                        headerName: "Archived",
                        width: 100,
                        type: "boolean",
                      },
                      {
                        field: "createdAt",
                        headerName: "Created",
                        width: 200,
                        type: "dateTime",
                        renderCell: (p) =>
                          DateTime.fromJSDate(p.value).toLocaleString(
                            DateTime.DATETIME_MED
                          ),
                      },
                      {
                        field: "notes",
                        headerName: "Notes",
                        width: 200,
                        type: "string",
                      },
                      {
                        field: "actions",
                        type: "actions",
                        headerName: "",
                        width: 100,
                        minWidth: 100,
                        cellClassName: "actions",
                        flex: 1,
                        align: "right",
                        hideable: false,
                        getActions: ({ id, row }) => {
                          return [
                            <GridActionsCellItem
                              icon={<EditIcon />}
                              label="Edit"
                              className="textPrimary"
                              onClick={() => onClickEditSeedLot(row.id)}
                              color="inherit"
                            />,
                            <GridActionsCellItem
                              icon={<DeleteIcon />}
                              label="Delete"
                              onClick={() => onClickTrashSeedLot(row.id)}
                              color="inherit"
                            />,
                          ];
                        },
                      },
                    ]}
                    rows={seedLots.map((c) => ({
                      id: c.id,
                      number: c.number,
                      supplier: c.supplier,
                      archived: c.archivedAt ? true : false,
                      createdAt: DateTime.fromISO(c.createdAt!).toJSDate(),
                      notes: c.notes,
                    }))}
                    initialState={{
                      filter: undefined,
                      sorting: {
                        sortModel: [
                          {
                            field: "createdAt",
                            sort: "desc",
                          },
                        ],
                      },
                    }}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        csvOptions: {
                          allColumns: true,
                        },
                      },
                    }}
                    getRowClassName={(params) =>
                      params.indexRelativeToCurrentPage % 2 === 0
                        ? "even"
                        : "odd"
                    }
                    disableRowSelectionOnClick
                  />
                )}
              </Box>
            </>
          )}
        </Paper>
      </Grid>
      <LoadingBackdrop open={busy} />
      <SeedLotDialog
        actionLabel="Add"
        initialValues={{
          archived: false,
          number: "",
          supplier: "",
          notes: "",
        }}
        onCancel={() => setShowAddLotDialog(false)}
        open={showAddLotDialog}
        onSubmit={onSubmitAddLotDialog}
      />
      {seedLotToAction && (
        <SeedLotDialog
          actionLabel="Save"
          initialValues={{
            archived: !!seedLotToAction.archivedAt,
            number: seedLotToAction.number,
            supplier: seedLotToAction.supplier,
            notes: seedLotToAction.notes,
          }}
          onCancel={() => setOpenEditLotDialog(false)}
          open={openEditLotDialog}
          onSubmit={onSubmitEditLotDialog}
        />
      )}
      <ConfirmDialog
        open={openConfirmTrashSeedLotDialog}
        description={`Are you sure you want to trash the seed lot '${seedLotToAction?.number}'?`}
        onClose={onCloseTrashSeedLotDialog}
        title="Trash Seed Lot"
      />
    </Container>
  );
}
