import React, { useState, useContext, useEffect } from "react";
import Page from "../components/page";
import {
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  IconButton,
  Box,
  TextField,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DateTime } from "luxon";
import LoadingBackdrop from "../components/loading-backdrop";
import { UserContext, ErrorContext } from "../components/app-routes";
import { formatCurrency } from "./currency";
import { deliveriesApi, Delivery } from "../services/api/deliveries";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import mixpanel from "mixpanel-browser";
import { UpgradePlanDialog } from "../components/upgrade-plan-dialog";
import { useHistory } from "react-router-dom";
import { unitSystemLabel } from "./crops-page";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Grower } from "../models/grower";

function CustomerOrdersTable(props: {
  deliveriesByCustomer: Delivery[];
  grower: Grower;
}) {
  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    customerRow: {
      backgroundColor: "#d9d9d9",
    },
    headerCell: {
      fontWeight: "bolder",
    },
    dataHeaderRow: {
      backgroundColor: "#efefef",
    },
    productCell: {
      width: "25%",
    },
    sizeCell: {},
    secondaryCell: {},
    checkBoxCell: {
      width: "30px",
    },
  });
  const classes = useStyles();
  const theme = useTheme();
  const delivery = props.deliveriesByCustomer[0];

  return (
    <TableContainer
      className={classes.table}
      component={Paper}
      sx={{ marginTop: theme.spacing(2) }}
    >
      <Table size="small">
        <TableBody>
          <TableRow className={classes.customerRow}>
            <TableCell></TableCell>
            <TableCell className={classes.headerCell} colSpan={2}>
              {delivery.customerName}
            </TableCell>
            <TableCell align="right" className={classes.headerCell} colSpan={3}>
              Order Total:{" "}
              {formatCurrency(
                props.deliveriesByCustomer.reduce(
                  (total, delivery) =>
                    total + delivery.price * delivery.quantity,
                  0
                ),
                props.grower.CountryISO
              )}
            </TableCell>
          </TableRow>
          {delivery.customerAddressLine1 && (
            <TableRow className={classes.customerRow}>
              <TableCell></TableCell>
              <TableCell className={classes.headerCell} colSpan={5}>
                {delivery.customerAddressLine1}
              </TableCell>
            </TableRow>
          )}
          <TableRow className={classes.dataHeaderRow}>
            <TableCell
              classes={{
                root: classes.checkBoxCell,
              }}
            ></TableCell>
            <TableCell
              classes={{
                root: classes.productCell,
              }}
              className={classes.headerCell}
            >
              Product
            </TableCell>
            <TableCell
              align="center"
              classes={{
                root: classes.sizeCell,
              }}
              className={classes.headerCell}
            >
              Size
            </TableCell>
            <TableCell
              align="center"
              classes={{
                root: classes.secondaryCell,
              }}
              className={classes.headerCell}
            >
              Quantity
            </TableCell>
            <TableCell
              align="center"
              classes={{
                root: classes.secondaryCell,
              }}
              className={classes.headerCell}
            >
              Price
            </TableCell>
            <TableCell
              align="center"
              classes={{
                root: classes.secondaryCell,
              }}
              className={classes.headerCell}
            >
              Total
            </TableCell>
          </TableRow>
          {props.deliveriesByCustomer.map((d) => (
            <TableRow>
              <TableCell>
                <CheckBoxOutlineBlankIcon />
              </TableCell>
              <TableCell>{d.productName}</TableCell>
              <TableCell align="center">
                {d.productSize}
                {d.productWeight
                  ? ` (${d.productWeight} ${unitSystemLabel(props.grower)})`
                  : ``}
              </TableCell>
              <TableCell align="center">{d.quantity}</TableCell>
              <TableCell align="center">
                {formatCurrency(d.price, props.grower.CountryISO)}
              </TableCell>
              <TableCell align="center">
                {formatCurrency(d.quantity * d.price, props.grower.CountryISO)}
              </TableCell>
            </TableRow>
          ))}
          <TableRow></TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function DeliveriesPage() {
  const useStyles = makeStyles({
    exportButton: {
      marginLeft: "10px",
    },
  });
  const classes = useStyles();
  const [busy, setBusy] = useState(false);
  const [date, setDate] = useState(DateTime.now());
  const [deliveries, setDeliveries] = useState<Delivery[] | undefined>(
    undefined
  );
  const [openUpgradePlanDialog, setOpenUpgradePlanDialog] = useState(false);
  const context = useContext(UserContext);
  const { handleError } = useContext(ErrorContext);
  const grower = context.grower;
  let groupedDeliveries: { [key: string]: Delivery[] } | undefined = undefined;
  const history = useHistory();
  const theme = useTheme();

  if (!grower) {
    throw new Error("Invalid context");
  }

  if (deliveries) {
    groupedDeliveries = groupBy(deliveries, "customerDeliveryRoute");
  }

  useEffect(() => {
    mixpanel.track("View Deliveries Page");
  }, []);

  useEffect(() => {
    if (grower.Plan === "business") {
      return;
    }
    setOpenUpgradePlanDialog(true);
  }, []);

  useEffect(() => {
    if (grower.Plan !== "business") {
      return;
    }

    async function getData() {
      setBusy(true);
      try {
        setDeliveries(await deliveriesApi.getAll(date));
      } catch (error) {
        handleError(error);
      } finally {
        setBusy(false);
      }
    }
    getData();
  }, [date]);

  const handleDateChange = (date: DateTime | null) => {
    if (date === null) {
      return;
    }
    setDate(date);
  };

  const handleExportPDF = () => {
    async function post() {
      setBusy(true);
      try {
        await deliveriesApi.getPdf(date);

        mixpanel.track("Export Deliveries Pdf");
      } catch (error) {
        handleError(error);
      } finally {
        setBusy(false);
      }
    }
    post();
  };

  const onClickCancelUpgradePlan = () => {
    history.replace("/home");
  };

  return (
    <Page title={"Deliveries"}>
      <MobileDatePicker<DateTime>
        inputFormat="ccc, LLLL dd, y"
        value={date}
        onChange={handleDateChange}
        InputAdornmentProps={{
          position: "start",
        }}
        showToolbar={false}
        closeOnSelect={true}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            inputProps={{ ...params.inputProps, readOnly: true }}
          />
        )}
      />
      <IconButton onClick={() => setDate(date.minus({ days: 1 }))} size="large">
        <ArrowBackIosIcon />
      </IconButton>
      <IconButton onClick={() => setDate(date.plus({ days: 1 }))} size="large">
        <ArrowForwardIosIcon />
      </IconButton>
      <Button
        color="secondary"
        variant="contained"
        onClick={() => setDate(DateTime.now().startOf("day"))}
      >
        Today
      </Button>
      <Button
        color="secondary"
        className={classes.exportButton}
        variant="contained"
        onClick={() => handleExportPDF()}
      >
        Export PDF
      </Button>
      {groupedDeliveries && (
        <Box pt={2}>
          {Object.keys(groupedDeliveries).length > 0 ? (
            <>
              {Object.keys(groupedDeliveries)
                .sort((a, b) => a.localeCompare(b))
                .map((route) => {
                  if (!groupedDeliveries) {
                    throw new Error("Invalid state");
                  }

                  const deliveriesByCustomer: {
                    [key: string]: Delivery[];
                  } = groupBy(groupedDeliveries[route], "customerId");

                  const customerIdsSorted = Object.keys(
                    deliveriesByCustomer
                  ).sort((a, b) =>
                    deliveriesByCustomer[a][0].customerName.localeCompare(
                      deliveriesByCustomer[b][0].customerName
                    )
                  );

                  return (
                    <>
                      <Typography
                        variant="h5"
                        sx={{ marginTop: theme.spacing(2) }}
                        color="primary"
                      >
                        {route ? <>Route: {route}</> : <>No Route</>}
                      </Typography>

                      {customerIdsSorted.map((customerId) => (
                        <CustomerOrdersTable
                          deliveriesByCustomer={
                            deliveriesByCustomer[customerId]
                          }
                          grower={grower}
                        />
                      ))}
                    </>
                  );
                })}
            </>
          ) : (
            <Typography variant="body1">
              No deliveries on{" "}
              {date.toLocaleString({ month: "long", day: "numeric" })}.
            </Typography>
          )}
        </Box>
      )}
      <LoadingBackdrop open={busy} />
      <UpgradePlanDialog
        open={openUpgradePlanDialog}
        onClickCancel={onClickCancelUpgradePlan}
      />
    </Page>
  );
}

function groupBy(objectArray: any[], property: string) {
  return objectArray.reduce(function (acc, obj) {
    let key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}
