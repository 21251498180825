import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import React from "react";
import { Formik, Form, FastField, FieldProps } from "formik";
import * as yup from "yup";
import { FormControl, FormHelperText, Box } from "@mui/material";
import { AppTextField } from "./text-field";
import { validationMessages } from "../utils/validation/messages";
import { FormPrompt } from "./form-prompt";

export interface SeedFormValues {
  id: number;
  name: string;
  variety: string;
  notes: string;
  archived: boolean;
}

export default function SeedForm(props: {
  children: JSX.Element;
  initialValues: SeedFormValues;
  saved: boolean;
  onSubmit: (values: SeedFormValues) => void;
}) {
  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={yup.object({
        name: yup.string().required(validationMessages.Required),
        variety: yup.string(),
        notes: yup.string().max(5000),
        archived: yup.boolean(),
      })}
      onSubmit={props.onSubmit}
    >
      {(form) => (
        <>
          <FormPrompt form={form} saved={props.saved} />
          <Form noValidate>
            <Box>
              <FormControl
                variant="standard"
                error={form.touched.archived && Boolean(form.errors.archived)}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="archived"
                      color="primary"
                      onChange={form.handleChange}
                      checked={form.values.archived}
                    />
                  }
                  label="Archived"
                />
                {form.touched.archived && form.errors.archived && (
                  <FormHelperText>{form.errors.archived}</FormHelperText>
                )}
              </FormControl>
            </Box>
            <FastField name="name">
              {(fieldProps: FieldProps) => (
                <AppTextField {...fieldProps} autoFocus label="Name" />
              )}
            </FastField>
            <FastField
              component={AppTextField}
              name="variety"
              label="Variety"
            />
            <FastField name="notes">
              {(fieldProps: FieldProps) => (
                <AppTextField
                  {...fieldProps}
                  multiline
                  label="Notes"
                  maxRows={4}
                  rows={4}
                />
              )}
            </FastField>
            <div>{props.children}</div>
          </Form>
        </>
      )}
    </Formik>
  );
}
