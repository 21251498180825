import { apiProvider } from "./utilities/provider";

export const emailChangeApi = {
  change: (email: string): Promise<void> =>
    apiProvider.post(`users/email-change`, {
      data: {
        email,
      },
    }),
  confirm: (token: string): Promise<void> =>
    apiProvider.post(`users/email-change/${token}`),
};
