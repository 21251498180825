import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  TextField,
  Box,
  Button,
  Container,
  Typography,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { sessionsApi } from "../services/api/sessions";
import { LogoHeader } from "../components/logo-header";
import axios, { AxiosError } from "axios";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    textAlign: "center",
  },
  fieldContainer: {
    paddingTop: theme.spacing(2),
  },
}));

export function LogInPage(props: { onLoggedIn: (from?: string) => void }) {
  const location = useLocation<{ from: string }>();
  const classes = useStyles();
  const form = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Enter a valid email")
        .required("Email is required"),
      password: yup.string().required("Password is required"),
    }),
    onSubmit: () => {
      logIn();
    },
  });

  const [error, setError] = useState<string>("");
  const [busy, setBusy] = useState<boolean>(false);

  const logIn = async () => {
    setError("");
    setBusy(true);
    try {
      await sessionsApi.start(form.values.email, form.values.password);
      props.onLoggedIn(location.state?.from);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        setError("Incorrect email or invalid password");
      } else {
        setError(
          "Something went wrong.  Check your connection or try again later"
        );
      }
    } finally {
      setBusy(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <LogoHeader />
      <Paper elevation={3}>
        <div className={classes.formContainer}>
          <Typography gutterBottom variant="h5" component="h2">
            Log In
          </Typography>
          <form onSubmit={form.handleSubmit} noValidate>
            <Box mt={2}>
              <TextField
                id="email"
                name="email"
                type="email"
                label="Email"
                value={form.values.email}
                onChange={form.handleChange}
                fullWidth
                variant="outlined"
                autoFocus
                error={form.touched.email && Boolean(form.errors.email)}
                helperText={form.touched.email && form.errors.email}
              />
            </Box>
            <Box mt={2}>
              <TextField
                id="password"
                type="password"
                label="Password"
                value={form.values.password}
                onChange={form.handleChange}
                fullWidth
                variant="outlined"
                error={form.touched.password && Boolean(form.errors.password)}
                helperText={form.touched.password && form.errors.password}
              />
            </Box>
            {error && (
              <Box mt={2}>
                <Typography variant="body1" color="error">
                  {error}
                </Typography>
              </Box>
            )}
            <Box mt={2}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                type="submit"
                disabled={busy}
              >
                Log In
              </Button>
            </Box>
            <Box mt={2}>
              <Box p={1} component="span">
                <Link to="/sign-up">Sign Up</Link>
              </Box>
              <Box p={1} component="span">
                <Link to="/forgot-password">Forgot Password</Link>
              </Box>
            </Box>
          </form>
        </div>
      </Paper>
    </Container>
  );
}
