import React, { useState, useContext, useEffect, Fragment } from "react";
import Page from "../components/page";
import { Formik, Form, FastField, FieldProps, Field } from "formik";
import { AppTextField } from "../components/text-field";
import {
  MenuItem,
  Grid,
  Box,
  Button,
  Typography,
  TextField,
  useTheme,
  TableCell,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import * as yup from "yup";
import { validationMessages } from "../utils/validation/messages";
import {
  reportsApi,
  SalesReport,
  CropProductionReportAdvanced,
  CropProductionReportSimple,
  PackagingReportSimple,
  PackagingReportAdvanced,
  SalesReportData,
  OrdersSummaryReport,
} from "../services/api/reports";
import { DateTime } from "luxon";
import LoadingBackdrop from "../components/loading-backdrop";
import { UserContext, ErrorContext } from "../components/app-routes";
import { formatCurrency } from "./currency";
import mixpanel from "mixpanel-browser";
import { UpgradePlanDialog } from "../components/upgrade-plan-dialog";
import { useHistory } from "react-router-dom";
import { unitSystemLabel } from "./crops-page";
import makeStyles from "@mui/styles/makeStyles";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { GridColDef, GridToolbar } from "@mui/x-data-grid";
import { StripedDataGrid } from "../components/striped-data-grid";
import { v4 } from "uuid";
import { withStyles } from "@mui/styles";
import { Size } from "../models/size";
import { sizesApi } from "../services/api/sizes";

const StyledTableCell = withStyles({
  root: {
    borderBottom: "0.5px solid black",
    borderLeft: "0.5px solid black",
  },
})(TableCell);

interface FormValues {
  report: string;
  from: DateTime | null;
  to: DateTime | null;
}

const validationSchema = yup.object({
  report: yup.string().required(validationMessages.Required),
  from: yup
    .date()
    .typeError(validationMessages.Required)
    .required(validationMessages.Required),
  to: yup
    .date()
    .typeError(validationMessages.Required)
    .required(validationMessages.Required),
});

const border = "2px solid black";

const useStyles = makeStyles({
  tableContainer: {
    border: border,
  },
  table: {
    minWidth: 650,
  },
  customerHeaderCell: {
    backgroundColor: "#d9d9d9",
  },
  productHeaderCell: {
    backgroundColor: "#f6b26b",
  },
  customerBodyCell: {
    backgroundColor: "#f9cb9c",
  },
  sizeCell0: {
    backgroundColor: "#a4c2f4",
  },
  sizeCell1: {
    backgroundColor: "#ffe599",
  },
  sizeCell2: {
    backgroundColor: "#f9cb9c",
  },
  sizeCellTray: {
    backgroundColor: "#b4a7d6",
  },
  borderLeft: {
    borderLeft: border,
  },
  tableCellRoot: {
    borderBottom: "0.5px solid black",
  },
});

export default function ReportsPage() {
  const [busy, setBusy] = useState(false);
  const [ordersSummaryReport, setOrdersSummaryReport] = useState<
    OrdersSummaryReport | undefined
  >(undefined);
  const [sizes, setSizes] = useState<Size[] | undefined>(undefined);
  const [salesReport, setSalesReport] = useState<SalesReportData[] | undefined>(
    undefined
  );
  const [
    cropProductionReportAdvanced,
    setCropProductionReportAdvanced,
  ] = useState<CropProductionReportAdvanced | undefined>(undefined);
  const [cropProductionReportSimple, setCropProductionReportSimple] = useState<
    CropProductionReportSimple | undefined
  >(undefined);
  const [packagingReportSimple, setPackagingReportSimple] = useState<
    PackagingReportSimple | undefined
  >();
  const [packagingReportAdvanced, setPackagingReportAdvanced] = useState<
    PackagingReportAdvanced | undefined
  >();
  const [openUpgradePlanDialog, setOpenUpgradePlanDialog] = useState(false);
  const styles = useStyles();
  const { grower } = useContext(UserContext);
  const countryISO = grower?.CountryISO;
  const { handleError } = useContext(ErrorContext);
  const history = useHistory();
  const theme = useTheme();

  if (!grower || !countryISO) {
    throw new Error("Invalid grower");
  }

  const salesReportColumns: GridColDef[] = [
    { field: "OrderNumber", headerName: "Order Number", width: 200 },
    {
      field: "HarvestDate",
      headerName: "Harvest Date",
      width: 200,
      type: "date",
    },
    { field: "CustomerName", headerName: "Customer", width: 200 },
    { field: "CustomerType", headerName: "Customer Type", width: 200 },
    {
      field: "Product",
      headerName: "Product",
      width: 200,
      valueGetter: (ps) => {
        return `${ps.row.ProductName} - ${ps.row.SizeName}`;
      },
    },
    {
      field: "Price",
      headerName: "Price",
      width: 200,
      valueGetter: (ps) => {
        return formatCurrency(ps.row.Price, grower.CountryISO, {
          symbol: "",
          separator: "",
        });
      },
    },
    { field: "Quantity", headerName: "Quantity", width: 200 },
    {
      field: "Total",
      headerName: "Total",
      width: 200,
      valueGetter: (ps) => {
        return formatCurrency(ps.row.Total, grower.CountryISO, {
          symbol: "",
          separator: "",
        });
      },
    },
    {
      field: "Seeds",
      headerName: "Seeds",
      width: 200,
    },
    {
      field: "SeedLots",
      headerName: "Seed Lots",
      width: 200,
    },
  ];

  const packagingReportSimpleColumns: GridColDef[] = [
    {
      field: "Product",
      headerName: "Product Name",
      width: 200,
    },
    {
      field: "Size",
      headerName: "Product Size",
      width: 200,
    },
    {
      field: "Weight",
      headerName: `Product Weight (${unitSystemLabel(grower)})`,
      width: 200,
    },
    {
      field: "Quantity",
      headerName: "Quantity",
      width: 200,
    },
    {
      field: "HarvestDate",
      headerName: "Harvest Date",
      width: 200,
      type: "date",
    },
    {
      field: "Orders",
      headerName: "Orders Affected",
      width: 200,
    },
  ];

  const packagingReportAdvancedColumns: GridColDef[] = [
    {
      field: "Product",
      headerName: "Product Name",
      width: 200,
    },
    {
      field: "Orders",
      headerName: "Orders Affected",
      width: 200,
    },
    {
      field: "Size",
      headerName: "Product Size",
      width: 200,
    },
    {
      field: "Weight",
      headerName: `Product Weight (${unitSystemLabel(grower)})`,
      width: 200,
    },
    {
      field: "QuantityExpected",
      headerName: "Expected Qty",
      width: 200,
    },
    {
      field: "QuantityActual",
      headerName: "Actual Qty",
      width: 200,
    },
    {
      field: "DateExpected",
      headerName: "Exp. Pack. Date",
      width: 200,
      type: "date",
    },
    {
      field: "DateActual",
      headerName: "Act. Pack. Date",
      width: 200,
      type: "date",
    },
    {
      field: "HarvestDate",
      headerName: "Exp. Harvest Date",
      width: 200,
      type: "date",
    },
    {
      field: "CompletedBy",
      headerName: "Completed By",
      width: 200,
    },
    {
      field: "Notes",
      headerName: "Notes",
      width: 200,
    },
  ];

  const cropProductionReportSimpleColumns: GridColDef[] = [
    {
      field: "SowingDate",
      headerName: "Sowing Date",
      width: 200,
      type: "date",
    },
    {
      field: "Crop",
      headerName: "Crop",
      width: 200,
    },
    {
      field: "SowingRate",
      headerName: `Sowing Rate per Tray (${unitSystemLabel(grower)})`,
      width: 200,
    },
    {
      field: "TraysSowed",
      headerName: "Trays Sowed",
      width: 200,
    },
    {
      field: "ExpectedYield",
      headerName: `Expected Yield per Tray (${unitSystemLabel(grower)})`,
      width: 200,
    },
    {
      field: "TotalSeedWeight",
      headerName: `Total Seed Weight for Sowing (${unitSystemLabel(grower)})`,
      width: 200,
    },
    {
      field: "HarvestDate",
      headerName: "For Harvest On",
      width: 200,
      type: "date",
    },
    {
      field: "Orders",
      headerName: "Orders Affected",
      width: 200,
    },
  ];

  const cropProductionReportAdvancedColumns: GridColDef[] = [
    {
      field: "SowedCropName",
      headerName: "Crop Name",
      width: 200,
    },
    {
      field: "SowedSeedName",
      headerName: "Seed Name",
      width: 200,
    },
    {
      field: "SowedSeedVariety",
      headerName: "Seed Variety",
      width: 200,
    },
    {
      field: "SeedLots",
      valueGetter: (params) => {
        return (
          params.row.SoakedSeedLotNumbers ?? params.row.SowedSeedLotNumbers
        );
      },
      headerName: "Seed Lots",
      width: 200,
    },
    {
      field: "SowedDateActual",
      headerName: "Actual Sow Date",
      width: 200,
      type: "date",
    },
    {
      field: "SowedDateExpected",
      headerName: "Expected Sow Date",
      width: 200,
      type: "date",
    },
    {
      field: "HarvestedDateExpected",
      headerName: "Expected Harvest Date",
      width: 200,
      type: "date",
    },
    {
      field: "HarvestedDateActual",
      headerName: "Actual Harvest Date",
      width: 200,
      type: "date",
    },
    {
      field: "Orders",
      headerName: "Orders Affected",
      width: 200,
    },
    {
      field: "SoakedDateExpected",
      headerName: "Expected Soak Date",
      width: 200,
      type: "date",
    },
    {
      field: "SoakedDateActual",
      headerName: "Actual Soak Date",
      width: 200,
      type: "date",
    },
    {
      field: "SoakedBy",
      headerName: "Soaked By",
      width: 200,
    },
    {
      field: "SoakedNotes",
      headerName: "Soak Notes",
      width: 200,
    },
    {
      field: "SoakedTraysExpected",
      headerName: "Expected Soaked Tray Count",
      width: 200,
    },
    {
      field: "SoakedTraysActual",
      headerName: "Actual Soaked Tray Count",
      width: 200,
    },
    {
      field: "SowedBy",
      headerName: "Sowed By",
      width: 200,
    },
    {
      field: "SowedNotes",
      headerName: "Sow Notes",
      width: 200,
    },
    {
      field: "SowedTraysExpected",
      headerName: "Expected Sow Tray Count",
      width: 200,
    },
    {
      field: "SowedTraysActual",
      headerName: "Actual Sow Tray Count",
      width: 200,
    },
    {
      field: "SowedCropSowingRate",
      headerName: `Sowing Rate (${unitSystemLabel(grower)})`,
      width: 200,
    },
    {
      field: "SowedCropExpectedYield",
      width: 200,
      headerName: `Expected Yield per Tray (${unitSystemLabel(grower)})`,
    },
    {
      field: "SowedCropNotes",
      headerName: "Crop Notes",
      width: 200,
    },
    {
      field: "SowedSeedNotes",
      headerName: "Seed Notes",
      width: 200,
    },
    {
      field: "SeedLotNotes",
      valueGetter: (params) => {
        return params.row.SoakedSeedLotNotes ?? params.row.SowedSeedLotNotes;
      },
      headerName: "Seed Lot Notes",
      width: 200,
    },
    {
      field: "BlackoutDateExpected",
      headerName: "Expected Blackout Date",
      width: 200,
      type: "date",
    },
    {
      field: "BlackoutDateActual",
      headerName: "Actual Blackout Date",
      width: 200,
      type: "date",
    },
    {
      field: "BlackoutBy",
      headerName: "Blackout By",
      width: 200,
    },
    {
      field: "BlackoutNotes",
      headerName: "Blackout Notes",
      width: 200,
    },
    {
      field: "BlackoutTraysExpected",
      headerName: "Expected Blackout Tray Count",
      width: 200,
    },
    {
      field: "BlackoutTraysActual",
      headerName: "Actual Blackout Tray Count",
      width: 200,
    },
    {
      field: "UncoveredDateExpected",
      headerName: "Expected Uncover Date",
      width: 200,
      type: "date",
    },
    {
      field: "UncoveredDateActual",
      headerName: "Actual Uncover Date",
      width: 200,
      type: "date",
    },
    {
      field: "UncoveredBy",
      headerName: "Uncovered By",
      width: 200,
    },
    {
      field: "UncoveredNotes",
      headerName: "Uncover Notes",
      width: 200,
    },
    {
      field: "UncoveredTraysExpected",
      headerName: "Expected Uncover Tray Count",
      width: 200,
    },
    {
      field: "UncoveredTraysActual",
      headerName: "Actual Uncover Tray Count",
      width: 200,
    },
    {
      field: "HarvestedBy",
      headerName: "Harvested By",
      width: 200,
    },
    {
      field: "HarvestedNotes",
      headerName: "Harvest Notes",
      width: 200,
    },
    {
      field: "HarvestedTraysExpected",
      headerName: "Expected Harvest Tray Count",
      width: 200,
    },
    {
      field: "HarvestedTraysActual",
      headerName: "Actual Harvest Tray Count",
      width: 200,
    },
    {
      field: "HarvestedYieldExpected",
      headerName: `Expected Harvest Yield (${unitSystemLabel(grower)})`,
      width: 200,
    },
    {
      field: "HarvestedYieldActual",
      headerName: `Actual Harvest Yield (${unitSystemLabel(grower)})`,
      width: 200,
    },
  ];

  useEffect(() => {
    if (grower.Plan === "business") {
      return;
    }
    setOpenUpgradePlanDialog(true);
  }, []);

  const handleOnSubmit = async (values: FormValues) => {
    if (!values.from || !values.to) {
      throw new Error("Invalid state");
    }

    setBusy(true);
    setSalesReport(undefined);
    setCropProductionReportSimple(undefined);
    setCropProductionReportAdvanced(undefined);
    setPackagingReportAdvanced(undefined);
    setPackagingReportSimple(undefined);
    setOrdersSummaryReport(undefined);

    try {
      switch (values.report) {
        case "orders-summary":
          setOrdersSummaryReport(
            await reportsApi.getOrdersSummaryReport(values.from, values.to)
          );
          setSizes(await sizesApi.getAll());
          break;
        case "sales":
          const report = await reportsApi.getSalesReport(
            values.from,
            values.to
          );
          setSalesReport(report);
          break;
        case "crop-production":
          const cropProductionReport = await reportsApi.getCropProductionReport(
            values.from,
            values.to
          );

          if (grower.Workflow === "simple") {
            setCropProductionReportSimple(
              cropProductionReport as CropProductionReportSimple
            );
          } else {
            setCropProductionReportAdvanced(
              cropProductionReport as CropProductionReportAdvanced
            );
          }
          break;
        case "packaging":
          const packagingReport = await reportsApi.getPackagingReport(
            values.from,
            values.to
          );

          if (grower.Workflow === "simple") {
            setPackagingReportSimple(packagingReport as PackagingReportSimple);
          } else {
            setPackagingReportAdvanced(
              packagingReport as PackagingReportAdvanced
            );
          }
          break;
        default:
          throw new Error("invalid report");
      }

      mixpanel.track("Generate Report", {
        type: values.report,
      });
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  const onClickCancelUpgradePlan = () => {
    history.replace("/home");
  };

  return (
    <Page title={"Reports"}>
      <Formik
        initialValues={{
          report: "sales",
          from: null,
          to: null,
        }}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {(formProps) => (
          <Form noValidate>
            <Grid item xs={12}>
              <FastField name="report">
                {(fieldProps: FieldProps) => (
                  <AppTextField {...fieldProps} autoFocus select label="Report">
                    <MenuItem key="sales" value="sales">
                      Sales
                    </MenuItem>
                    <MenuItem key="crop-production" value="crop-production">
                      Crop Production
                    </MenuItem>
                    <MenuItem key="packaging" value="packaging">
                      Packaging
                    </MenuItem>
                    <MenuItem key="orders-summary" value="orders-summary">
                      Orders Summary
                    </MenuItem>
                  </AppTextField>
                )}
              </FastField>
              <Field name="from">
                {(fieldProps: FieldProps) => (
                  <Box mt={2}>
                    <MobileDatePicker<DateTime>
                      value={fieldProps.field.value}
                      onChange={(date: DateTime | null) => {
                        fieldProps.form.setFieldValue("from", date);
                      }}
                      inputFormat="MMMM d, y"
                      label="From"
                      InputAdornmentProps={{
                        position: "start",
                      }}
                      showToolbar={false}
                      closeOnSelect={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={fieldProps.field.name}
                          error={
                            !!fieldProps.meta.error && fieldProps.meta.touched
                          }
                          helperText={
                            fieldProps.meta.touched && fieldProps.meta.error
                          }
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                          }}
                        />
                      )}
                    />
                  </Box>
                )}
              </Field>
              <Field name="to">
                {(fieldProps: FieldProps) => (
                  <Box mt={2}>
                    <MobileDatePicker<DateTime>
                      value={fieldProps.field.value}
                      onChange={(date: DateTime | null) => {
                        fieldProps.form.setFieldValue("to", date);
                      }}
                      showToolbar={false}
                      closeOnSelect={true}
                      inputFormat="MMMM d, y"
                      label="To"
                      InputAdornmentProps={{
                        position: "start",
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={fieldProps.field.name}
                          fullWidth
                          error={
                            !!fieldProps.meta.error && fieldProps.meta.touched
                          }
                          helperText={
                            fieldProps.meta.touched && fieldProps.meta.error
                          }
                          variant="outlined"
                          inputProps={{ ...params.inputProps, readOnly: true }}
                        />
                      )}
                    />
                  </Box>
                )}
              </Field>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={busy}
                >
                  Generate
                </Button>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>
      {packagingReportSimple &&
        (packagingReportSimple.Rows.length > 0 ? (
          <StripedDataGrid
            sx={{ marginTop: theme.spacing(2) }}
            columns={packagingReportSimpleColumns}
            rows={packagingReportSimple.Rows.map((c) => ({
              id: v4(),
              ...c,
              HarvestDate: DateTime.fromISO(c.HarvestDate).toJSDate(),
            }))}
            initialState={{
              filter: undefined,
              sorting: {
                sortModel: [
                  {
                    field: "HarvestDate",
                    sort: "asc",
                  },
                  {
                    field: "Product",
                    sort: "asc",
                  },
                ],
              },
            }}
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                csvOptions: {
                  allColumns: true,
                  fileName: `packaging-report-${DateTime.now().toISODate()}.csv`,
                },
              },
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            disableRowSelectionOnClick
          />
        ) : (
          <Box mt={2}>
            <Typography variant="body1">
              No packaging between these dates
            </Typography>
          </Box>
        ))}
      {packagingReportAdvanced &&
        (packagingReportAdvanced.Rows.length > 0 ? (
          <StripedDataGrid
            sx={{ marginTop: theme.spacing(2) }}
            columns={packagingReportAdvancedColumns}
            rows={packagingReportAdvanced.Rows.map((c) => ({
              id: v4(),
              ...c,
              DateActual: c.DateActual
                ? DateTime.fromISO(c.DateActual).toJSDate()
                : undefined,
              DateExpected: c.DateExpected
                ? DateTime.fromISO(c.DateExpected).toJSDate()
                : undefined,
              HarvestDate: c.HarvestDate
                ? DateTime.fromISO(c.HarvestDate).toJSDate()
                : undefined,
            }))}
            initialState={{
              filter: undefined,
              sorting: {
                sortModel: [
                  {
                    field: "HarvestDate",
                    sort: "asc",
                  },
                  {
                    field: "Product",
                    sort: "asc",
                  },
                ],
              },
            }}
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                csvOptions: {
                  allColumns: true,
                  fileName: `packaging-report-${DateTime.now().toISODate()}.csv`,
                },
              },
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            disableRowSelectionOnClick
          />
        ) : (
          <Box mt={2}>
            <Typography variant="body1">
              No packaging between these dates
            </Typography>
          </Box>
        ))}
      {cropProductionReportSimple &&
        (cropProductionReportSimple.ReportData.length > 0 ? (
          <StripedDataGrid
            sx={{ marginTop: theme.spacing(2) }}
            columns={cropProductionReportSimpleColumns}
            rows={cropProductionReportSimple.ReportData.map((c) => ({
              id: c.Crop + c.HarvestDate,
              ...c,
              SowingDate: DateTime.fromISO(c.SowingDate).toJSDate(),
              HarvestDate: DateTime.fromISO(c.HarvestDate).toJSDate(),
            }))}
            initialState={{
              filter: undefined,
              sorting: {
                sortModel: [
                  {
                    field: "SowingDate",
                    sort: "asc",
                  },
                ],
              },
            }}
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                csvOptions: {
                  allColumns: true,
                  fileName: `crop-production-report-${DateTime.now().toISODate()}.csv`,
                },
              },
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            disableRowSelectionOnClick
          />
        ) : (
          <Box mt={2}>
            <Typography variant="body1">
              No crop production between these dates
            </Typography>
          </Box>
        ))}
      {cropProductionReportAdvanced &&
        (cropProductionReportAdvanced.ReportData.length > 0 ? (
          <StripedDataGrid
            sx={{ marginTop: theme.spacing(2) }}
            columns={cropProductionReportAdvancedColumns}
            rows={cropProductionReportAdvanced.ReportData.map((c) => ({
              id: v4(),
              ...c,
              SoakedDateExpected: c.SoakedDateExpected
                ? DateTime.fromISO(c.SoakedDateExpected).toJSDate()
                : undefined,
              SoakedDateActual: c.SoakedDateActual
                ? DateTime.fromISO(c.SoakedDateActual).toJSDate()
                : undefined,
              SowedDateExpected: c.SowedDateExpected
                ? DateTime.fromISO(c.SowedDateExpected).toJSDate()
                : undefined,
              SowedDateActual: c.SowedDateActual
                ? DateTime.fromISO(c.SowedDateActual).toJSDate()
                : undefined,
              BlackoutDateExpected: c.BlackoutDateExpected
                ? DateTime.fromISO(c.BlackoutDateExpected).toJSDate()
                : undefined,
              BlackoutDateActual: c.BlackoutDateActual
                ? DateTime.fromISO(c.BlackoutDateActual).toJSDate()
                : undefined,
              UncoveredDateExpected: c.UncoveredDateExpected
                ? DateTime.fromISO(c.UncoveredDateExpected).toJSDate()
                : undefined,
              UncoveredDateActual: c.UncoveredDateActual
                ? DateTime.fromISO(c.UncoveredDateActual).toJSDate()
                : undefined,
              HarvestedDateExpected: c.HarvestedDateExpected
                ? DateTime.fromISO(c.HarvestedDateExpected).toJSDate()
                : undefined,
              HarvestedDateActual: c.HarvestedDateActual
                ? DateTime.fromISO(c.HarvestedDateActual).toJSDate()
                : undefined,
            }))}
            initialState={{
              filter: undefined,
              sorting: {
                sortModel: [
                  {
                    field: "SowedDateExpected",
                    sort: "asc",
                  },
                ],
              },
              columns: {
                columnVisibilityModel: {
                  SoakedDateActual: false,
                  SoakedDateExpected: false,
                  SoakedBy: false,
                  SoakedNotes: false,
                  SoakedTraysExpected: false,
                  SoakedTraysActual: false,
                  SowedBy: false,
                  SowedNotes: false,
                  SowedTraysExpected: false,
                  SowedTraysActual: false,
                  SowedCropSowingRate: false,
                  SowedCropExpectedYield: false,
                  SowedCropNotes: false,
                  SowedSeedNotes: false,
                  SeedLotNotes: false,
                  BlackoutDateExpected: false,
                  BlackoutDateActual: false,
                  BlackoutBy: false,
                  BlackoutNotes: false,
                  BlackoutTraysExpected: false,
                  BlackoutTraysActual: false,
                  UncoveredDateExpected: false,
                  UncoveredDateActual: false,
                  UncoveredBy: false,
                  UncoveredNotes: false,
                  UncoveredTraysExpected: false,
                  UncoveredTraysActual: false,
                  HarvestedBy: false,
                  HarvestedNotes: false,
                  HarvestedTraysExpected: false,
                  HarvestedTraysActual: false,
                  HarvestedYieldExpected: false,
                  HarvestedYieldActual: false,
                },
              },
            }}
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                csvOptions: {
                  allColumns: true,
                  fileName: `crop-production-report-${DateTime.now().toISODate()}.csv`,
                },
              },
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            disableRowSelectionOnClick
          />
        ) : (
          <Box mt={2}>
            <Typography variant="body1">
              No crop production between these dates
            </Typography>
          </Box>
        ))}
      {salesReport &&
        (salesReport.length > 0 ? (
          <StripedDataGrid
            sx={{ marginTop: theme.spacing(2) }}
            columns={salesReportColumns}
            rows={salesReport.map((c) => ({
              id: v4(),
              ...c,
              HarvestDate: DateTime.fromISO(c.HarvestDate).toJSDate(),
            }))}
            initialState={{
              filter: undefined,
              sorting: {
                sortModel: [
                  {
                    field: "HarvestDate",
                    sort: "asc",
                  },
                  {
                    field: "Product",
                    sort: "asc",
                  },
                ],
              },
            }}
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                csvOptions: {
                  allColumns: true,
                  fileName: `sales-report-${DateTime.now().toISODate()}`,
                },
              },
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            disableRowSelectionOnClick
          />
        ) : (
          <Box mt={2}>
            <Typography variant="body1">
              No sales between these dates
            </Typography>
          </Box>
        ))}
      {ordersSummaryReport &&
        sizes &&
        (ordersSummaryReport.ProductSales.length > 0 ? (
          <Grid>
            <Box mt={2}>
              <TableContainer
                className={styles.tableContainer}
                component={Paper}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        className={styles.customerHeaderCell}
                        sx={{ position: "sticky", left: 0 }}
                        align="center"
                        rowSpan={4}
                      >
                        Customers
                      </StyledTableCell>
                      <StyledTableCell
                        className={styles.customerHeaderCell}
                        align="center"
                        rowSpan={4}
                      >
                        Customer Type
                      </StyledTableCell>
                      <StyledTableCell
                        className={styles.customerHeaderCell}
                        align="center"
                        rowSpan={3}
                        valign="bottom"
                      >
                        Total Sales
                      </StyledTableCell>
                      {ordersSummaryReport.ProductSales.sort(sort).map((v) => (
                        <StyledTableCell
                          classes={{
                            root: styles.tableCellRoot,
                          }}
                          className={`${styles.productHeaderCell}`}
                          style={{
                            borderLeft: "2px solid black",
                          }}
                          align="center"
                          colSpan={4}
                        >
                          {v.Name}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      {ordersSummaryReport.ProductSales.sort(sort).map((v) => (
                        <StyledTableCell
                          className={`${styles.productHeaderCell}`}
                          style={{
                            borderLeft: "2px solid black",
                          }}
                          align="center"
                          colSpan={4}
                        >
                          {formatCurrency(v.TotalSales, countryISO)}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      {ordersSummaryReport.ProductSales.sort(sort).map((p) => {
                        return (
                          <Fragment>
                            {sizes.map((s, i) => (
                              <StyledTableCell
                                className={`${
                                  styles[`sizeCell${i}` as keyof typeof styles]
                                }`}
                                style={
                                  i === 0
                                    ? {
                                        borderLeft: "2px solid black",
                                      }
                                    : {}
                                }
                                align="center"
                              >
                                {formatCurrency(
                                  ordersSummaryReport.ProductSizeSales.find(
                                    (pss) =>
                                      pss.ID === p.ID && pss.SizeID === s.id
                                  )?.TotalSales || 0,
                                  countryISO
                                )}
                              </StyledTableCell>
                            ))}
                            <StyledTableCell
                              className={styles.sizeCellTray}
                              align="center"
                            >
                              {formatCurrency(
                                ordersSummaryReport.ProductSizeSales.find(
                                  (pss) =>
                                    pss.ID === p.ID && pss.SizeType === "tray"
                                )?.TotalSales || 0,
                                countryISO
                              )}
                            </StyledTableCell>
                          </Fragment>
                        );
                      })}
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        className={styles.productHeaderCell}
                        align="center"
                      >
                        {formatCurrency(
                          ordersSummaryReport.TotalSales,
                          countryISO
                        )}
                      </StyledTableCell>
                      {ordersSummaryReport.ProductSales.sort(sort).map(() => (
                        <Fragment>
                          {sizes.map((s, i) => (
                            <StyledTableCell
                              className={`${
                                styles[`sizeCell${i}` as keyof typeof styles]
                              }`}
                              align="center"
                              style={
                                i === 0
                                  ? {
                                      borderLeft: "2px solid black",
                                    }
                                  : {}
                              }
                            >
                              {s.name.charAt(0).toUpperCase()}
                            </StyledTableCell>
                          ))}
                          <StyledTableCell
                            className={styles.sizeCellTray}
                            align="center"
                          >
                            T
                          </StyledTableCell>
                        </Fragment>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ordersSummaryReport.CustomerSales.sort(sort).map((v) => (
                      <TableRow>
                        <StyledTableCell
                          className={styles.customerBodyCell}
                          sx={{ position: "sticky", left: 0 }}
                          align="center"
                        >
                          {v.Name}
                        </StyledTableCell>
                        <StyledTableCell
                          className={styles.customerBodyCell}
                          align="center"
                        >
                          {v.Type}
                        </StyledTableCell>
                        <StyledTableCell
                          className={styles.customerBodyCell}
                          align="center"
                        >
                          {formatCurrency(v.TotalSales, countryISO)}{" "}
                        </StyledTableCell>
                        {ordersSummaryReport.ProductSales.sort(sort).map(
                          (ps) => (
                            <Fragment>
                              {sizes.map((s, i) => {
                                const sales =
                                  ordersSummaryReport.CustomerProductSizeSales.find(
                                    (cpss) =>
                                      cpss.ID === v.ID &&
                                      cpss.ProductID === ps.ID &&
                                      cpss.SizeID === s.id
                                  )?.TotalSales || 0;
                                return (
                                  <StyledTableCell
                                    className={`${
                                      sales !== 0
                                        ? styles[
                                            `sizeCell${i}` as keyof typeof styles
                                          ]
                                        : ""
                                    }`}
                                    style={
                                      i === 0
                                        ? {
                                            borderLeft: "2px solid black",
                                          }
                                        : {}
                                    }
                                    align="center"
                                  >
                                    {formatCurrency(sales, countryISO)}
                                  </StyledTableCell>
                                );
                              })}
                              {[0].map(() => {
                                const sales =
                                  ordersSummaryReport.CustomerProductSizeSales.find(
                                    (cpss) =>
                                      cpss.ID === v.ID &&
                                      cpss.ProductID === ps.ID &&
                                      cpss.SizeType === "tray"
                                  )?.TotalSales || 0;

                                return (
                                  <StyledTableCell
                                    className={
                                      sales !== 0 ? styles.sizeCellTray : ""
                                    }
                                  >
                                    {formatCurrency(sales, countryISO)}
                                  </StyledTableCell>
                                );
                              })}
                            </Fragment>
                          )
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        ) : (
          <Box mt={2}>
            <Typography variant="body1">
              No orders between these dates
            </Typography>
          </Box>
        ))}
      <LoadingBackdrop open={busy} />
      <UpgradePlanDialog
        open={openUpgradePlanDialog}
        onClickCancel={onClickCancelUpgradePlan}
      />
    </Page>
  );
}

function sort(a: { Name: string }, b: { Name: string }): number {
  return a.Name.localeCompare(b.Name);
}
