import Button from "@mui/material/Button";
import React, { useEffect, useState, useContext } from "react";
import {
  GridColDef,
  GridRowSelectionModel,
  GridPaginationModel,
  GridToolbar,
  useGridApiRef,
  GridDensity,
  GridInitialState,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridRowId,
} from "@mui/x-data-grid";
import {
  IconButton,
  CircularProgress,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "../components/confirm-dialog";
import LoadingBackdrop from "../components/loading-backdrop";
import { UserContext, ErrorContext } from "../components/app-routes";
import Page from "../components/page";
import { Seed } from "../models/seed";
import { seedsApi } from "../services/api/seeds";
import { ActiveFilterControls } from "../components/active-filter-controls";
import mixpanel from "mixpanel-browser";
import makeStyles from "@mui/styles/makeStyles";
import { Link, useHistory } from "react-router-dom";
import { getSearchParamNumber } from "../utils/history";
import { StripedDataGrid } from "../components/striped-data-grid";
import { Formik, Form, FastField, FieldProps } from "formik";
import { AppTextField } from "../components/text-field";
import title from "../components/title";
import { validationMessages } from "../utils/validation/messages";

const seedsGridStateStorageKey = "seedsGridState";
const seedsGridDensityStorageKey = "seedsGridDensity";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  gridContainer: {
    height: "750px",
    width: "100%",
    paddingTop: theme.spacing(2),
  },
}));

const SeedsDataGrid = React.memo(
  (props: {
    seeds: Seed[] | null;
    onSelectionChange: (params: GridRowSelectionModel) => void;
    initialPage?: number;
    onPaginationModelChange: (m: GridPaginationModel) => void;
    onClickRow: (id: GridRowId) => void;
  }) => {
    const context = useContext(UserContext);
    const grower = context.grower;
    const apiRef = useGridApiRef();
    const [initialState, setInitialState] = React.useState<GridInitialState>();

    const saveSnapshot = React.useCallback(() => {
      if (apiRef?.current?.exportState) {
        const currentState = apiRef.current.exportState();
        localStorage.setItem(
          seedsGridStateStorageKey,
          JSON.stringify(currentState)
        );
      }
    }, [apiRef]);

    React.useLayoutEffect(() => {
      const stateFromLocalStorage = localStorage?.getItem(
        seedsGridStateStorageKey
      );
      setInitialState(
        stateFromLocalStorage
          ? JSON.parse(stateFromLocalStorage)
          : {
              columns: {
                columnVisibilityModel: {
                  notes: false,
                },
              },
            }
      );

      // handle refresh and navigating away/refreshing
      window.addEventListener("beforeunload", saveSnapshot);

      return () => {
        // in case of an SPA remove the event-listener
        window.removeEventListener("beforeunload", saveSnapshot);
        saveSnapshot();
      };
    }, [saveSnapshot]);

    const density: GridDensity =
      (localStorage.getItem(seedsGridDensityStorageKey) as GridDensity) ||
      "standard";

    if (!grower) {
      throw new Error("Invalid context");
    }

    const columns: GridColDef[] = [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        hideable: false,
        headerName: "Checkbox",
      },
      {
        field: "name",
        headerName: "Name",
        width: 200,
      },
      {
        field: "variety",
        headerName: "Variety",
        width: 200,
      },
      {
        field: "notes",
        headerName: "Notes",
        width: 200,
      },
    ];

    if (!props.seeds) return null;

    if (!initialState) {
      return <CircularProgress />;
    }

    return (
      <StripedDataGrid
        apiRef={apiRef}
        columns={columns}
        rows={props.seeds.map((c) => ({
          id: c.id,
          name: c.name,
          variety: c.variety,
          notes: c.notes,
        }))}
        initialState={{
          ...initialState,
          filter: undefined,
          sorting: {
            sortModel: [
              {
                field: "name",
                sort: "asc",
              },
            ],
          },
          pagination: {
            paginationModel: {
              page: props.initialPage,
            },
          },
        }}
        checkboxSelection
        onRowSelectionModelChange={props.onSelectionChange}
        autoPageSize={true}
        onPaginationModelChange={props.onPaginationModelChange}
        slots={{
          toolbar: GridToolbar,
        }}
        density={density}
        onStateChange={({ density }) => {
          localStorage.setItem(seedsGridDensityStorageKey, density.value);
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            csvOptions: {
              allColumns: true,
            },
          },
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        disableRowSelectionOnClick
        onRowClick={(params) => {
          props.onClickRow(params.id);
        }}
      />
    );
  }
);

export default function SeedsPage() {
  const classes = useStyles();
  const [seeds, setSeeds] = useState<Seed[] | null>(null);
  const [selected, setSelected] = useState<number[] | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const [seedChangedCounter, setSeedsChangedCounter] = useState(0);
  const { handleError } = useContext(ErrorContext);
  const showArchivedCheckedStorageKey = "seedsShowArchivedChecked";
  const showActiveCheckedStorageKey = "seedsShowActiveChecked";
  const [showArchivedChecked, setShowArchivedChecked] = useState(
    window.localStorage.getItem(showArchivedCheckedStorageKey) !==
      false.toString()
  );
  const [showActiveChecked, setShowActiveChecked] = useState(
    window.localStorage.getItem(showActiveCheckedStorageKey) !==
      false.toString()
  );
  const history = useHistory();

  useEffect(() => {
    async function getSeeds() {
      try {
        const seeds = await seedsApi.getAll();
        setSeeds(seeds);
      } catch (error) {
        handleError(error);
      }
    }
    getSeeds();
  }, [seedChangedCounter]);

  const handleSelectionChange = (rowIds: GridRowSelectionModel) => {
    if (rowIds.length === 0) {
      setSelected(null);
    } else {
      setSelected(rowIds as number[]);
    }
  };

  const handleClickTrash = () => {
    setDialogOpen(true);
  };

  const handleTrashDialogClose = async (okTrash?: boolean) => {
    setDialogOpen(false);

    if (!okTrash) {
      return;
    }

    if (!selected) {
      throw new Error("Invalid state: selected");
    }

    setBusy(true);

    let trashed = 0;

    try {
      for (let i = 0; i < selected.length; i++) {
        try {
          await seedsApi.trash(selected[i]);
          trashed++;

          mixpanel.track("Delete Seed");
        } catch (error) {
          handleError(error);
        }
      }
      setSeedsChangedCounter(seedChangedCounter + trashed);
    } finally {
      setBusy(false);
    }
  };

  const getSeedFromId = (id: number): Seed => {
    const seed = seeds?.find((v) => v.id === id);

    if (!seed) {
      throw new Error("Invalid state");
    }

    return seed;
  };

  const handleShowActiveChanged = (checked: boolean) => {
    setShowActiveChecked(checked);
    window.localStorage.setItem(
      showActiveCheckedStorageKey,
      checked.toString()
    );
  };

  const handleShowArchivedChanged = (checked: boolean) => {
    setShowArchivedChecked(checked);
    window.localStorage.setItem(
      showArchivedCheckedStorageKey,
      checked.toString()
    );
  };

  const onClickRow = (id: GridRowId) => {
    const seed = getSeedFromId(id as number);
    history.push(`/home/seeds/${seed.id}`);
  };

  let initialPage = getSearchParamNumber(history, "page", 0);

  return (
    <Page title="Seeds">
      {seeds && (
        <>
          <div>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/home/seeds/add"
              disabled={busy}
            >
              Add
            </Button>
            <IconButton
              aria-label="delete"
              disabled={busy || !selected}
              onClick={handleClickTrash}
            >
              <DeleteIcon />
            </IconButton>
            <ActiveFilterControls
              showActiveChecked={showActiveChecked}
              showArchivedChecked={showArchivedChecked}
              onChangeShowActive={handleShowActiveChanged}
              onChangeShowArchived={handleShowArchivedChanged}
            />
          </div>
          <div className={classes.gridContainer}>
            <SeedsDataGrid
              seeds={seeds.filter(
                (seed) =>
                  (showActiveChecked && seed.archivedAt === null) ||
                  (showArchivedChecked && seed.archivedAt !== null)
              )}
              onSelectionChange={handleSelectionChange}
              initialPage={initialPage}
              onPaginationModelChange={(m) => {
                const params = new URLSearchParams(`page=${m.page}`);
                history.replace({
                  search: params.toString(),
                });
              }}
              onClickRow={onClickRow}
            />
          </div>
          {selected && (
            <ConfirmDialog
              title="Trash Seeds"
              description={`Are you sure you want to trash ${
                selected.length
              } seed${selected.length > 1 ? "s" : ""}?`}
              open={dialogOpen}
              onClose={handleTrashDialogClose}
            ></ConfirmDialog>
          )}
        </>
      )}
      <LoadingBackdrop open={busy} />
    </Page>
  );
}
