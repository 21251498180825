import React from "react";
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

export function SubscriptionPastDueDialog(props: {
  onClickGoToBillingPortal: () => void;
  onClickClose: () => void;
}) {
  return (
    <>
      <Container maxWidth="xs">
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Subscription Past Due
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Hello. Payment for your SeedLeaf subscription is now past due.
              Please go to our billing portal to pay. If you have any questions
              or need help, email us at{" "}
              <a href="mailto:support@seedleaf.co">support@seedleaf.co</a>.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClickClose}>Close</Button>
            <Button
              onClick={props.onClickGoToBillingPortal}
              color="primary"
              autoFocus
            >
              Go to Billing Portal
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
