import currency from "currency.js";
import { getAllInfoByISO } from "iso-country-currency";
import { amount } from "../models/order";

export function formatCurrency(
  value: number,
  countryISO: string,
  options?: currency.Format | currency.Options
): string {
  const info = getAllInfoByISO(countryISO);
  return toCurrency(value, countryISO).format(
    options || { symbol: info.symbol }
  );
}

export function toCurrency(
  amount: amount | string,
  countryISO: string
): currency {
  const options = {
    precision: isZeroDecimalCurrency(countryISO) ? 0 : 2,
    fromCents: typeof amount === "number",
  };

  return currency(amount, options);
}

const zeroDecimalCurrencies = [
  "BIF",
  "CLP",
  "DJF",
  "GNF",
  "JPY",
  "KMF",
  "KRW",
  "MGA",
  "PYG",
  "RWF",
  "UGX",
  "VND",
  "VUV",
  "XAF",
  "XOF",
  "XPF",
];

export function isZeroDecimalCurrency(countryISO: string): boolean {
  const info = getAllInfoByISO(countryISO);
  return zeroDecimalCurrencies.some((c) => c === info.currency);
}
