import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { emailVerificationsApi } from "../services/api/email-verifications";
import LoadingBackdrop from "../components/loading-backdrop";
import { AxiosError } from "axios";
import { ErrorContext } from "../components/app-routes";

export function EmailVerificationPage(props: { onComplete: () => void }) {
  const { id, token } = useParams<{ id: string; token: string }>();
  const [busy, setBusy] = useState(false);
  const [result, setResult] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const { handleError } = useContext(ErrorContext);

  useEffect(() => {
    const verifyEmail = async () => {
      setBusy(true);
      try {
        await emailVerificationsApi.verify(id, token);
        setResult(
          "Thanks, your email has been verified.  Click OK to continue."
        );
      } catch (error) {
        if ((error as AxiosError)?.isAxiosError) {
          const axiosError = error as AxiosError;
          if (axiosError.response?.status === 404) {
            setResult(
              "Sorry, something went wrong verifying your email address.  It might already be verified, or you might need to start the process again.  Click OK to continue."
            );
            return;
          }
        }
        setResult("Sorry, something bad happened");
        handleError(error);
      } finally {
        setBusy(false);
        setOpenDialog(true);
      }
    };
    verifyEmail();
  }, [id, token]);

  const handleClose = () => {
    props.onComplete();
  };

  return (
    <Container maxWidth="xs">
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Email Verification</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {result}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop open={busy} />
    </Container>
  );
}
