import React, { useState, useContext } from "react";
import { Box, Button, Container, Typography, Paper } from "@mui/material";
import { LogoHeader } from "../components/logo-header";
import { UserContext, ErrorContext } from "../components/app-routes";
import { emailVerificationsApi } from "../services/api/email-verifications";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    textAlign: "center",
  },
}));

export function VerifyEmailPage() {
  const classes = useStyles();
  const context = useContext(UserContext);
  const [resent, setResent] = useState(false);
  const { handleError } = useContext(ErrorContext);

  const handleResendEmail = async () => {
    try {
      await emailVerificationsApi.resendVerificationEmail();
      setResent(true);
    } catch (error) {
      handleError(error);
    }
  };

  const onClickBack = () => {
    window.history.back();
  };

  return (
    <Container maxWidth="xs">
      <LogoHeader />
      <Paper elevation={3}>
        <div className={classes.container}>
          <Typography gutterBottom variant="h5" component="h2" color="primary">
            Verify Your Email Address
          </Typography>
          <Typography variant="body1" color="textPrimary">
            We've sent an email to <strong>{context.user.Email}</strong>, to
            verify that we have the right address. Please click on the link in
            that email or click the button below to have the email sent again.
            If your SeedLeaf email address is incorrect, contact{" "}
            <a href="mailto:support@seedleaf.co">support@seedleaf.co</a>.
          </Typography>
          <Box p={2}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              disabled={resent}
              onClick={handleResendEmail}
            >
              {resent ? "Resent!" : "Resend Email"}
            </Button>
            <Button variant="text" fullWidth onClick={onClickBack}>
              Back
            </Button>
          </Box>
        </div>
      </Paper>
    </Container>
  );
}
