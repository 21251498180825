import { FormControlLabel, Checkbox } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

export const ActiveFilterControls = (props: {
  showActiveChecked: boolean;
  showArchivedChecked: boolean;
  onChangeShowActive: (checked: boolean) => void;
  onChangeShowArchived: (checked: boolean) => void;
}) => {
  const useStyles = makeStyles((theme) => ({
    controlLabel: {
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: theme.palette.primary.dark,
      borderRadius: "4px",
      paddingRight: theme.spacing(1),
    },
    container: {
      display: "inline",
      marginLeft: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <FormControlLabel
        className={classes.controlLabel}
        control={
          <Checkbox
            name="showActive"
            checked={props.showActiveChecked}
            onChange={(event, checked) => props.onChangeShowActive(checked)}
            color="primary"
          />
        }
        label="Show Active"
      />
      <FormControlLabel
        className={classes.controlLabel}
        control={
          <Checkbox
            checked={props.showArchivedChecked}
            onChange={(event, checked) => props.onChangeShowArchived(checked)}
            name="showArchived"
            color="primary"
          />
        }
        label="Show Archived"
      />
    </div>
  );
};
