import React from "react";
import { Typography, Tooltip as MatTooltip } from "@mui/material";
import styled from "@emotion/styled";

export const Tooltip = (props: { tooltipTitle: string; text: string }) => {
  const { tooltipTitle: tooltipTitle, text } = props;

  const Pre = styled.pre(() => ({
    whiteSpace: "pre-wrap",
  }));

  return (
    <>
      {tooltipTitle ? (
        <MatTooltip title={<Pre>{tooltipTitle}</Pre>} placement="bottom">
          <Typography
            variant="body1"
            component="span"
            sx={{
              textDecorationStyle: "dotted",
              textDecorationLine: tooltipTitle ? "underline" : "none",
            }}
          >
            {text}
          </Typography>
        </MatTooltip>
      ) : (
        <Typography variant="body1" component="span">
          {text}
        </Typography>
      )}
    </>
  );
};
