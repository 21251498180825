import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import TimeZoneControl from "./time-zone-control";

export default function SetTimeZone(props: {
  open: boolean;
  onClickOk: (timeZone: string) => void;
}) {
  const [timeZone, setTimeZone] = useState(
    new Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  return (
    <Dialog maxWidth={"sm"} open={props.open}>
      <DialogTitle>Set Time Zone</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please select the time zone in which production takes place. Going
          forward, this time zone will be used by SeedLeaf to schedule certain
          features, like notifications.
        </DialogContentText>
        <Box mt={2}>
          <TimeZoneControl
            value={timeZone}
            onChange={(e) => setTimeZone(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClickOk(timeZone)}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}
