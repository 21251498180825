import { apiProvider } from "./utilities/provider";

export const emailVerificationsApi = {
  verify: (id: string, token: string): Promise<void> =>
    apiProvider.post(`users/email-verifications/${id}`, {
      data: {
        Token: token,
      },
    }),
  resendVerificationEmail: () => apiProvider.post("users/email-verifications"),
};
