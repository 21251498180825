import { Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { sizesApi } from "../services/api/sizes";
import { cropsApi } from "../services/api/crops";
import { Crop } from "../models/crop";
import { Size } from "../models/size";
import Page from "../components/page";
import LoadingBackdrop from "../components/loading-backdrop";
import { productsApi, ProductTypes } from "../services/api/products";
import ProductForm, { ProductFormValues } from "../components/product-form";
import { UserContext, ErrorContext } from "../components/app-routes";
import { formatCurrency, toCurrency } from "./currency";
import { DateTime } from "luxon";
import mixpanel from "mixpanel-browser";
import { goBackOrTo } from "../utils/history";
import { Product } from "../models/product";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  actions: {
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

interface Data {
  crops: Crop[];
  productSizes: Size[];
}

export default function AddProductPage() {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState<Data | null>(null);
  const [busy, setBusy] = useState(false);
  const [saved, setSaved] = useState(false);
  const { grower } = useContext(UserContext);
  const { handleError } = useContext(ErrorContext);
  const { state } = useLocation<{ product: Product }>();

  if (!grower) {
    throw new Error("Invalid UserContext");
  }

  const initialValues = state
    ? {
        name: state.product.name,
        productCrops: state.product.productCrops.map((c) => ({
          id: 0,
          cropId: c.crop.id.toString(),
          blend: c.blend.toString(),
        })),
        productSizes: state.product.productSizes.map((pS) => ({
          id: 0,
          type: pS.type,
          price: formatCurrency(pS.price, grower.CountryISO, {
            symbol: "",
            separator: "",
          }),
          weight: pS.weight ? pS.weight?.toString() : undefined,
          sizeId: pS.size?.id.toString(),
        })),
        notes: state.product.notes,
        archived: state.product.archivedAt !== null,
      }
    : {
        name: "",
        productCrops: [
          {
            id: 0,
            cropId: "",
            blend: "100",
          },
        ],
        productSizes: [
          {
            id: 0,
            type: "",
            sizeId: "",
            price: "",
            weight: "",
          },
        ],
        notes: "",
        archived: false,
      };

  useEffect(() => {
    async function load() {
      setBusy(true);
      try {
        const productSizes = await sizesApi.getAll();
        const crops = await cropsApi.getAll();
        setData({
          crops,
          productSizes,
        });
      } catch (error) {
        handleError(error);
      } finally {
        setBusy(false);
      }
    }
    load();
  }, []);

  const handleSubmit = async (values: ProductFormValues) => {
    setBusy(true);

    try {
      await productsApi.add({
        id: 0,
        name: values.name,
        productCrops: values.productCrops.map((c) => ({
          id: 0,
          crop: (null as unknown) as Crop,
          cropId: parseInt(c.cropId),
          blend: parseFloat(c.blend),
        })),
        productSizes: values.productSizes.map((pS) => ({
          id: 0,
          type: pS.type as ProductTypes,
          weight: pS.weight ? parseFloat(pS.weight) : undefined,
          sizeId: pS.sizeId ? parseInt(pS.sizeId) : undefined,
          size: (null as unknown) as Size,
          price: toCurrency(pS.price, grower.CountryISO).intValue,
        })),
        notes: values.notes,
        archivedAt: values.archived ? DateTime.now().toISO() : null,
      });

      setSaved(true);

      mixpanel.track("Add Product");

      history.replace("/home/products");
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  return (
    <Page title={"Add Product"}>
      {data && (
        <ProductForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          crops={data.crops}
          sizes={data.productSizes}
          saved={saved}
        >
          <Box mt={2} className={classes.actions}>
            <Button
              variant="outlined"
              disabled={busy}
              onClick={() => goBackOrTo(history, "/home/products")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={busy}
            >
              Add
            </Button>
          </Box>
        </ProductForm>
      )}
      <LoadingBackdrop open={busy} />
    </Page>
  );
}
