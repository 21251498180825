import { apiProvider } from "./utilities/provider";
import { Crop } from "../../models/crop";
import { JsonDocument } from "./utilities/json";

export const cropsApi = {
  add: (crop: Crop): Promise<void> =>
    apiProvider.post("crops", {
      data: {
        ...crop,
        foo: "bar",
      },
    }),
  update: (crop: Crop): Promise<void> =>
    apiProvider.put("crops", crop.id, {
      data: crop,
    }),
  getAll: (): Promise<Crop[]> =>
    apiProvider.getAll("crops").then((d: JsonDocument<Crop[]>) => d.data),
  getSingle: (id: number): Promise<Crop> =>
    apiProvider.getSingle("crops", id).then((d: JsonDocument<Crop>) => d.data),
  trash: (id: number): Promise<void> =>
    apiProvider.remove("crops", id).then(() => {
      return;
    }),
};
