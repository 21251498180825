import React, { useState, useContext } from "react";
import {
  TextField,
  Box,
  Button,
  Container,
  Typography,
  Paper,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { LogoHeader } from "../components/logo-header";
import { passwordResetsApi } from "../services/api/password-resets";
import { validationMessages } from "../utils/validation/messages";
import { useHistory, useLocation } from "react-router-dom";
import { ErrorContext } from "../components/app-routes";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    textAlign: "center",
  },
  fieldContainer: {
    paddingTop: theme.spacing(2),
  },
}));

export function ResetPasswordPage() {
  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const form = useFormik({
    initialValues: {
      password1: "",
      password2: "",
    },
    validationSchema: yup.object({
      password1: yup
        .string()
        .required(validationMessages.Required)
        .min(8, "Password must be at least 8 characters"),
      password2: yup
        .string()
        .required(validationMessages.Required)
        .equals([yup.ref("password1")], "Must be equal"),
    }),
    onSubmit: () => {
      resetPassword();
    },
  });
  const { handleError } = useContext(ErrorContext);

  const [busy, setBusy] = useState<boolean>(false);

  const resetPassword = async () => {
    setBusy(true);
    try {
      const search = new URLSearchParams(location.search);
      const id = search.get("id"),
        token = search.get("token");

      if (!id || !token) {
        alert("Something is wrong.  Let's try again.");
        history.replace("/forgot-password");
        return;
      }

      await passwordResetsApi.reset(id, token, form.values.password1);
      alert("Your password has been reset.");
      history.push("/log-in");
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        alert(
          "Sorry, for security you need to request to reset your password again."
        );
        history.replace("/forgot-password");
      } else {
        handleError(error);
      }
    } finally {
      setBusy(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <LogoHeader />
      <Paper elevation={3}>
        <div className={classes.formContainer}>
          <Typography gutterBottom variant="h5" component="h2">
            Reset Password
          </Typography>
          <Typography variant="subtitle1">Enter a new password</Typography>
          <form onSubmit={form.handleSubmit} noValidate>
            <Box mt={2}>
              <TextField
                id="password1"
                name="password1"
                type="password"
                label="Password"
                value={form.values.password1}
                onChange={form.handleChange}
                fullWidth
                variant="outlined"
                autoFocus
                error={form.touched.password1 && Boolean(form.errors.password1)}
                helperText={form.touched.password1 && form.errors.password1}
              />
            </Box>
            <Box mt={2}>
              <TextField
                id="password2"
                name="password2"
                type="password"
                label="Confirm Password"
                value={form.values.password2}
                onChange={form.handleChange}
                fullWidth
                variant="outlined"
                error={form.touched.password2 && Boolean(form.errors.password2)}
                helperText={form.touched.password2 && form.errors.password2}
              />
            </Box>
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={busy}
                fullWidth
              >
                Reset Password
              </Button>
            </Box>
          </form>
        </div>
      </Paper>
    </Container>
  );
}
