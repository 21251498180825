export function getEnv(): "dev" | "staging" | "production" {
  const host = window.location.host;

  switch (host) {
    case "localhost:3000":
    case "localhost:8080":
      return "dev";
    case "staging-seedleaf.herokuapp.com":
      return "staging";
    case "app.seedleaf.co":
      return "production";
    default:
      throw new Error("Unexpected host");
  }
}
