import { Container, Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { ReactNode } from "react";
import Title from "./title";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

export default function Page(props: { children: ReactNode; title: string }) {
  const classes = useStyles();

  return (
    <Container maxWidth="md" className={classes.container}>
      <Grid item md={12}>
        <Paper className={classes.paper}>
          <Title>{props.title}</Title>
          {props.children}
        </Paper>
      </Grid>
    </Container>
  );
}
