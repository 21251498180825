import { apiProvider } from "./utilities/provider";
import { JsonDocument } from "./utilities/json";
import { Seed } from "../../models/seed";

export const seedsApi = {
  add: (seed: Seed): Promise<Seed> =>
    apiProvider
      .post("seeds", {
        data: seed,
      })
      .then((d: JsonDocument<Seed>) => d.data),
  update: (seed: Seed): Promise<Seed> =>
    apiProvider.put("seeds", seed.id, {
      data: seed,
    }),
  getSingle: (id: number): Promise<Seed> =>
    apiProvider.getSingle("seeds", id).then((d: JsonDocument<Seed>) => d.data),
  getAll: (): Promise<Seed[]> =>
    apiProvider.getAll("seeds").then((d: JsonDocument<Seed[]>) => d.data),
  trash: (id: number): Promise<any> => apiProvider.remove("seeds", id),
};
