import {
  MenuItem,
  Box,
  Divider,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import {
  Formik,
  Form,
  FieldProps,
  FastField,
  FieldArray,
  Field,
  useFormikContext,
} from "formik";
import * as yup from "yup";
import { AppTextField } from "../components/text-field";
import { validationMessages } from "../utils/validation/messages";
import { Customer } from "../models/customer";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { ProductTypes } from "../services/api/products";
import DeleteIcon from "@mui/icons-material/Delete";
import { Product } from "../models/product";
import { FormPrompt } from "./form-prompt";
import { UserContext } from "./app-routes";
import { getAllInfoByISO } from "iso-country-currency";
import { formatCurrency, toCurrency } from "../pages/currency";
import { v4 } from "uuid";
import { UpgradePlanDialog } from "./upgrade-plan-dialog";

export class SeedLeafError extends Error {
  constructor(public message: string, public data?: { [k: string]: string }) {
    super(message);
  }
}

export enum OrderRecurType {
  None = "none",
  Weekly = "weekly",
  BiWeekly = "bi-weekly",
  TriWeekly = "tri-weekly",
  QuadWeekly = "quad-weekly",
}

export interface OrderFormValues {
  customerId: string;
  dateType: "harvestDate" | "startDate";
  date: DateTime | null;
  recur?: {
    id: string;
    type: OrderRecurType;
    endDate: DateTime | null;
  };
  orderProducts: {
    id: string;
    productId: string;
    productSizeId: string;
    quantity: string;
    price: string;
  }[];
  notes: string;
  deliveryDateOffset: string;
}

const validationSchema = yup.object({
  customerId: yup.string().required(validationMessages.Required),
  date: yup
    .date()
    .required(validationMessages.Required)
    .typeError(validationMessages.Date),
  orderRecurId: yup.string(),
  recur: yup.object().when("date", (date: Date) => {
    return yup.object({
      id: yup.string(),
      type: yup.string(),
      endDate: yup.date().when("type", {
        is: (val: OrderRecurType) => {
          return val !== OrderRecurType.None;
        },
        then: yup
          .date()
          .nullable()
          .typeError(validationMessages.Date)
          .min(
            DateTime.fromJSDate(date).isValid
              ? date
              : DateTime.now().startOf("day").toJSDate(),
            "Must be after date"
          )
          .max(
            DateTime.now().startOf("day").plus({
              year: 2,
            }),
            "Must end no more than 2 years from today"
          )
          .required(validationMessages.Required),
        otherwise: yup.date().nullable(),
      }),
    });
  }),
  orderProducts: yup.array().of(
    yup.object().shape({
      productId: yup.string().required(validationMessages.Required),
      productSizeId: yup.string().required(validationMessages.Required),
      quantity: yup
        .number()
        .typeError(validationMessages.Number)
        .required(validationMessages.Required)
        .integer(validationMessages.Integer)
        .positive(validationMessages.Positive),
      price: yup.string().required(validationMessages.Required),
    })
  ),
  notes: yup.string().max(5000),
  deliveryDateOffset: yup.number(),
});

function useMounted() {
  const [isMounted, setIsMounted] = useState(false);
  React.useEffect(() => {
    setIsMounted(true);
  }, []);
  return isMounted;
}

const OrderProductsSizeField = (props: {
  name: string;
  productId?: number;
  products: Product[];
}) => {
  const { values, setFieldValue } = useFormikContext<OrderFormValues>();
  const [first, setFirst] = useState(true);
  const { name, productId, products } = props;

  // clear the size when the product is changed
  React.useEffect(() => {
    if (first) {
      setFirst(false);
      return;
    }
    setFieldValue(name, "");
  }, [productId]);

  return (
    <>
      <Field name={name}>
        {(fieldProps: FieldProps) => (
          <AppTextField {...fieldProps} select label={"Size"}>
            {productId ? (
              products
                .find((p) => p.id === productId)
                ?.productSizes.map((size) => (
                  <MenuItem key={size.id} value={size.id.toString()}>
                    {size.type === ProductTypes.Cut ? size.size?.name : "Tray"}
                  </MenuItem>
                ))
            ) : (
              <MenuItem key="none" value="none">
                Select a product
              </MenuItem>
            )}
          </AppTextField>
        )}
      </Field>
    </>
  );
};

const OrderProductsPriceField = (props: {
  name: string;
  productId?: string;
  productSizeId?: string;
  products: Product[];
}) => {
  const {
    values,
    setFieldValue,
    initialValues,
  } = useFormikContext<OrderFormValues>();
  const { grower } = useContext(UserContext);
  const isMounted = useMounted();
  const { productId, productSizeId, products, name } = props;

  if (!grower) {
    throw new SeedLeafError("Invalid UserContext");
  }

  // clear the price when the product changes except on the first render
  React.useEffect(() => {
    if (!isMounted) {
      return;
    }
    setFieldValue(name, "");
  }, [productId]);

  // clear the price when the product or size is not set
  // default the price when the size is changed
  React.useEffect(() => {
    if (!isMounted) {
      return;
    }
    if (!productId || !productSizeId) {
      setFieldValue(name, "");
      return;
    }

    let price: number | undefined;

    // Restore the price set with the order when the same
    // product and size are selected
    // if (productSizeId === initialValues.orderProducts[props.i]?.productSizeId) {
    //   price = toCurrency(
    //     initialValues.orderProducts[props.i].price,
    //     grower.CountryISO
    //   ).intValue;
    // } else {
    //   // Fill in with the default product size price
    //   price = props.products
    //     .find((p) => p.id === parseInt(productId))
    //     ?.productSizes.find((pS) => pS.id === parseInt(productSizeId))?.price;
    // }

    // Fill in with the default product size price
    price = products
      .find((p) => p.id === parseInt(productId))
      ?.productSizes.find((pS) => pS.id === parseInt(productSizeId))?.price;

    if (price === undefined) {
      throw new SeedLeafError("Can't find product or product size", {
        productId,
        productSizeId,
      });
    }

    setFieldValue(
      name,
      formatCurrency(price, grower.CountryISO, {
        symbol: "",
        separator: "",
      })
    );
  }, [productSizeId]);

  return (
    <>
      <Field
        component={AppTextField}
        name={name}
        label={`Price`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {getAllInfoByISO(grower.CountryISO).symbol}
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

const EndDateField = (props: { recurType?: OrderRecurType }) => {
  const { values, setFieldValue } = useFormikContext<OrderFormValues>();
  const [hasChanged, setHasChanged] = useState(false);
  const fieldName = "recur.endDate";

  // - clear the end date when a reccurance is removed
  // - set the end date to the end of the year when a reccurance is set if there's no value

  // end date might change when reccurance type changes

  useEffect(() => {
    // ignore initial change
    if (!hasChanged) {
      setHasChanged(true);
      return;
    }

    switch (props.recurType) {
      // clear end date when reccurance is disabled
      case OrderRecurType.None:
        setFieldValue(fieldName, null);
        break;
      default:
        // default end date to end of year when recurrance
        // is enabled and end date hasn't been set
        if (values.recur?.endDate) {
          break;
        }
        setFieldValue(
          fieldName,
          DateTime.fromObject({
            day: 31,
            month: 12,
            year: DateTime.now().year,
          })
        );
    }
  }, [props.recurType, setFieldValue]);

  return (
    <Field name={fieldName}>
      {(fieldProps: FieldProps) => (
        <DatePicker<DateTime>
          value={fieldProps.field.value}
          onChange={(date) => {
            setFieldValue(fieldName, date);
          }}
          minDate={DateTime.now()}
          label="End Date"
          InputAdornmentProps={{
            position: "start",
          }}
          inputFormat="yyyy/LL/dd"
          disabled={values.recur?.type === OrderRecurType.None}
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={fieldProps.meta.touched && fieldProps.meta.error}
              error={!!fieldProps.meta.error && fieldProps.meta.touched}
              fullWidth
              variant="outlined"
              name={fieldProps.field.name}
              inputProps={{
                ...params.inputProps,
                placeholder: "YYYY/MM/DD",
              }}
            />
          )}
        />
      )}
    </Field>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  actions: {
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

export default function OrderForm(props: {
  children: JSX.Element;
  initialValues: OrderFormValues;
  onSubmit: (values: OrderFormValues) => void;
  customers: Customer[];
  products: Product[];
  editing?: boolean;
  saved: boolean;
}) {
  const classes = useStyles();
  const { grower } = useContext(UserContext);
  const [openUpgradePlanDialog, setOpenUpgradePlanDialog] = useState(false);

  if (!grower) {
    throw new Error("Invalid context");
  }

  const handleClickRepeats = () => {
    if (grower.Plan === "hobby") {
      setOpenUpgradePlanDialog(true);
    }
  };

  const onClickCancelUpgradePlan = () => {
    setOpenUpgradePlanDialog(false);
  };

  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={validationSchema}
      onSubmit={props.onSubmit}
    >
      {(formProps) => (
        <>
          <FormPrompt form={formProps} saved={props.saved} />
          <Form noValidate>
            <FastField name="customerId">
              {(fieldProps: FieldProps) => (
                <AppTextField {...fieldProps} autoFocus select label="Customer">
                  {props.customers
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .filter((_value, i) =>
                      grower.Plan === "business" ? true : i < 5
                    )
                    .map((c) => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                </AppTextField>
              )}
            </FastField>
            <Grid container columnSpacing={{ xs: 0, md: 1 }}>
              <Grid item xs={12} md={4}>
                {!props.editing && (
                  <Box>
                    <Field name="dateType">
                      {(fieldProps: FieldProps) => (
                        <AppTextField {...fieldProps} select>
                          <MenuItem key="harvestDate" value="harvestDate">
                            Harvest Date
                          </MenuItem>
                          <MenuItem key="startDate" value="startDate">
                            Start Date
                          </MenuItem>
                        </AppTextField>
                      )}
                    </Field>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={props.editing ? 12 : 8}>
                <Field name="date">
                  {(fieldProps: FieldProps) => (
                    <Box mt={2}>
                      <DatePicker<DateTime>
                        value={fieldProps.field.value}
                        onChange={(date) => {
                          formProps.setFieldValue(fieldProps.field.name, date);
                        }}
                        minDate={DateTime.now()}
                        label={
                          formProps.values.dateType === "harvestDate"
                            ? "Harvest Date"
                            : "Start Date"
                        }
                        InputAdornmentProps={{
                          position: "start",
                        }}
                        inputFormat="yyyy/LL/dd"
                        disabled={props.editing}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={
                              fieldProps.meta.touched && fieldProps.meta.error
                            }
                            disabled={props.editing}
                            error={
                              !!fieldProps.meta.error && fieldProps.meta.touched
                            }
                            fullWidth
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "YYYY/MM/DD",
                            }}
                            name={fieldProps.field.name}
                          />
                        )}
                      />
                    </Box>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="deliveryDateOffset">
                  {(fieldProps: FieldProps) => (
                    <AppTextField {...fieldProps} select label="Delivery Date">
                      <MenuItem key="0" value="0">
                        Deliver on Harvest Day
                      </MenuItem>
                      <MenuItem key="1" value="1">
                        Deliver One Day After Harvest Day
                      </MenuItem>
                      <MenuItem key="2" value="2">
                        Deliver Two Days After Harvest Day
                      </MenuItem>
                      <MenuItem key="3" value="3">
                        Deliver Three Days After Harvest Day
                      </MenuItem>
                    </AppTextField>
                  )}
                </Field>
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <Field name={"recur.type"}>
                    {(fieldProps: FieldProps) => (
                      <AppTextField
                        {...fieldProps}
                        select
                        label={"Repeats"}
                        disabled={props.editing || grower.Plan === "hobby"}
                        onClick={handleClickRepeats}
                      >
                        <MenuItem key={"none"} value={"none"}>
                          Does Not Repeat
                        </MenuItem>
                        <MenuItem
                          key={OrderRecurType.Weekly}
                          value={OrderRecurType.Weekly}
                        >
                          Every Week
                        </MenuItem>
                        <MenuItem
                          key={OrderRecurType.BiWeekly}
                          value={OrderRecurType.BiWeekly}
                        >
                          Every Second Week
                        </MenuItem>
                        <MenuItem
                          key={OrderRecurType.TriWeekly}
                          value={OrderRecurType.TriWeekly}
                        >
                          Every Third Week
                        </MenuItem>
                        <MenuItem
                          key={OrderRecurType.QuadWeekly}
                          value={OrderRecurType.QuadWeekly}
                        >
                          Every Fourth Week
                        </MenuItem>
                      </AppTextField>
                    )}
                  </Field>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box mt={2}>
                  <EndDateField recurType={formProps.values.recur?.type} />
                </Box>
              </Grid>
            </Grid>
            <FieldArray
              name="orderProducts"
              render={(arrayHelpers) => (
                <div>
                  {formProps.values.orderProducts.map((c, i) => (
                    <Fragment key={c.id}>
                      <Box mt={2}>
                        <Divider />
                      </Box>
                      <Grid item xs={12}>
                        <Field name={`orderProducts[${i}].productId`}>
                          {(fieldProps: FieldProps) => (
                            <AppTextField
                              {...fieldProps}
                              select
                              label={"Product"}
                            >
                              {props.products
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((product) => (
                                  <MenuItem key={product.id} value={product.id}>
                                    {product.name}
                                  </MenuItem>
                                ))}
                            </AppTextField>
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <OrderProductsSizeField
                            name={`orderProducts[${i}].productSizeId`}
                            productId={
                              c.productId ? parseInt(c.productId) : undefined
                            }
                            products={props.products}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <OrderProductsPriceField
                            name={`orderProducts[${i}].price`}
                            productId={c.productId}
                            productSizeId={c.productSizeId}
                            products={props.products}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <FastField
                            component={AppTextField}
                            name={`orderProducts[${i}].quantity`}
                            label={`Quantity`}
                          />
                        </Box>
                      </Grid>
                      <Box pl={[0, 1]} pt={[1]}>
                        <IconButton
                          disabled={formProps.values.orderProducts.length === 1}
                          aria-label="delete"
                          onClick={() => arrayHelpers.remove(i)}
                          size="large"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                      <Box mt={2}>
                        <Divider />
                      </Box>
                    </Fragment>
                  ))}
                  <Box mt={2} className={classes.actions}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() =>
                        arrayHelpers.push({
                          id: v4(),
                          productId: "",
                          quantity: "",
                          price: "",
                        })
                      }
                    >
                      Add Product
                    </Button>
                  </Box>
                </div>
              )}
            />
            <FastField name="notes">
              {(fieldProps: FieldProps) => (
                <AppTextField
                  {...fieldProps}
                  multiline
                  label="Notes"
                  maxRows={4}
                  rows={4}
                />
              )}
            </FastField>
            <div>{props.children}</div>
            <UpgradePlanDialog
              open={openUpgradePlanDialog}
              onClickCancel={onClickCancelUpgradePlan}
            />
          </Form>
        </>
      )}
    </Formik>
  );
}
