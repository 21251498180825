import { Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Page from "../components/page";
import LoadingBackdrop from "../components/loading-backdrop";
import { CustomerType } from "../models/customer-type";
import { customerTypesApi } from "../services/api/customer-types";
import { customersApi } from "../services/api/customers";
import CustomerForm, { CustomerFormValues } from "../components/customer-form";
import { ErrorContext } from "../components/app-routes";
import { Customer } from "../models/customer";
import { DateTime } from "luxon";
import mixpanel from "mixpanel-browser";
import { goBackOrTo } from "../utils/history";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  actions: {
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

export default function AddCustomerPage() {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState<{
    customerTypes: CustomerType[];
    customers: Customer[];
  } | null>(null);
  const [busy, setBusy] = useState(false);
  const [saved, setSaved] = useState(false);
  const { handleError } = useContext(ErrorContext);

  useEffect(() => {
    async function load() {
      setBusy(true);
      try {
        const [customerTypes, customers] = await Promise.all([
          customerTypesApi.getAll(),
          customersApi.getAll(),
        ]);
        setData({ customerTypes, customers });
      } catch (error) {
        handleError(error);
      } finally {
        setBusy(false);
      }
    }
    load();
  }, []);

  const handleSubmit = async (values: CustomerFormValues) => {
    setBusy(true);
    try {
      await customersApi.add({
        id: 0,
        name: values.name,
        typeId: parseInt(values.typeId),
        contactFirstName: values.contactFirstName,
        contactLastName: values.contactLastName,
        contactEmail: values.contactEmail,
        contactPhoneNumber: values.contactPhoneNumber,
        businessEmail: values.businessEmail,
        businessPhoneNumber: values.businessPhoneNumber,
        otherPhoneNumber: values.otherPhoneNumber,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        locality: values.locality,
        postalCode: values.postalCode,
        region: values.region,
        country: values.country,
        website: values.website,
        generalComments: values.generalComments,
        deliveryInstructions: values.deliveryInstructions,
        paymentInstructions: values.paymentInstructions,
        deliveryRoute: values.deliveryRoute,
        archivedAt: values.archived ? DateTime.now().toISO() : null,
      });
      setSaved(true);

      mixpanel.track("Add Customer");

      history.replace("/home/customers");
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  return (
    <Page title={"Add Customer"}>
      {data && (
        <CustomerForm
          saved={saved}
          onSubmit={handleSubmit}
          customerTypes={data.customerTypes}
          customers={data.customers}
        >
          <Box mt={2} className={classes.actions}>
            <Button
              variant="outlined"
              disabled={busy}
              onClick={() => goBackOrTo(history, "/home/customers")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={busy}
            >
              Add
            </Button>
          </Box>
        </CustomerForm>
      )}
      <LoadingBackdrop open={busy} />
    </Page>
  );
}
