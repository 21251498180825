import { apiProvider } from "./utilities/provider";
import { Product } from "../../models/product";

export enum ProductTypes {
  Cut = "cut",
  Tray = "tray",
}

export const productsApi = {
  getSingle: (id: number): Promise<Product> =>
    apiProvider.getSingle("products", id),
  getAll: (): Promise<Product[]> =>
    apiProvider.getAll("products").then((d: { data: Product[] }) => {
      return d.data;
    }),
  add: (data: Product): Promise<void> => apiProvider.post("products", { data }),
  update: (data: Product): Promise<void> =>
    apiProvider.put("products", data.id, { data }),
  trash: (id: number): Promise<void> =>
    apiProvider.remove("products", id).then(() => {
      return;
    }),
};
