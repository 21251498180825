import { apiProvider } from "./utilities/provider";
import { Order } from "../../models/order";
import { JsonDocument } from "./utilities/json";

export const ordersApi = {
  add: (data: Order): Promise<void> =>
    apiProvider.post("orders", {
      data,
    }),
  update: (data: Order, updateRecurrences?: "following"): Promise<void> =>
    apiProvider.put("orders", data.id, {
      data: {
        order: data,
        updateRecurrences,
      },
    }),
  getSingle: (id: number): Promise<Order> =>
    apiProvider
      .getSingle("orders", id)
      .then((d: JsonDocument<Order>) => d.data),
  getAll: (from: string, to: string): Promise<Order[]> =>
    apiProvider
      .getAll("orders", {
        from,
        to,
      })
      .then((d: JsonDocument<Order[]>) => d.data),
  trash: (
    id: number,
    data?: {
      recurring: "thisAndFollowing";
    }
  ): Promise<void> =>
    apiProvider
      .remove(
        "orders",
        id,
        data && {
          data,
        }
      )
      .then(() => {
        return;
      }),
};
