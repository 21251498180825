import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  adaptV4Theme,
} from "@mui/material/styles";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { GridClassKey } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import LuxonUtils from "@date-io/luxon";
import { AppRoutes } from "./app-routes";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module "@mui/material/styles/overrides" {
  export interface ComponentNameToClassKey {
    MuiDataGrid: GridClassKey;
  }
}

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: "#5AAB61",
      },
      secondary: {
        main: "#e0e0e0",
      },
    },
    overrides: {
      MuiDataGrid: {
        root: {
          fontSize: "1rem",
        },
      },
    },
  })
);

function App() {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Router>
              <AppRoutes />
            </Router>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </div>
  );
}

export default App;
