import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/app";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { ExtraErrorData as ExtraErrorDataIntegration } from "@sentry/integrations";
import { getEnv } from "./env";
import { initMixpanel } from "./mixpanel";

export const sentryDSN =
  "https://72794a9291d74b00b42478dbc81fe4ef@o1060572.ingest.sentry.io/6050237";

const enableSentry = process.env.NODE_ENV === "production";

if (enableSentry) {
  const env = getEnv();

  const debug = env !== "production";

  Sentry.init({
    dsn: sentryDSN,
    integrations: [new ExtraErrorDataIntegration()],
    environment: env,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    debug,
  });
}

initMixpanel();

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary showDialog fallback={<div>Error</div>}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
