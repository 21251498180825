import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  GridColDef,
  GridRowSelectionModel,
  GridFilterModel,
  GridPaginationModel,
  GridToolbar,
  GridDensity,
  GridInitialState,
  useGridApiRef,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridRowId,
} from "@mui/x-data-grid";
import {
  IconButton,
  Box,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "../components/confirm-dialog";
import LoadingBackdrop from "../components/loading-backdrop";
import Page from "../components/page";
import { Order } from "../models/order";
import { ordersApi } from "../services/api/orders";
import { DateTime } from "luxon";
import {
  UpdateRecurringOrderDialog,
  UpdateRecurringOrderMethods,
} from "./order-page";
import { UserContext, ErrorContext } from "../components/app-routes";
import { formatCurrency } from "./currency";
import mixpanel from "mixpanel-browser";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { getSearchParamNumber } from "../utils/history";
import { StripedDataGrid } from "../components/striped-data-grid";

enum DateRange {
  Next7Days,
  Next30Days,
  RestOfYear,
  Today,
  Tomorrow,
  Custom,
  AllTime,
}

const DateRangeAllTimeFromDate = "2020-01-01";
const DateRangeAllTimeToDate = "2100-01-01";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  gridContainer: {
    height: "750px",
    width: "100%",
    paddingTop: theme.spacing(2),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
  },
  datePicker: {
    marginRight: theme.spacing(1),
  },
}));

const ordersGridStateStorageKey = "ordersGridState";
const ordersGridDensityStorageKey = "ordersGridDensity";

const columns: GridColDef[] = [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    hideable: false,
    headerName: "Checkbox",
    renderHeader: () => <></>,
  },
  {
    field: "customer",
    headerName: "Customer",
    width: 200,
  },
  {
    field: "harvestDate",
    headerName: "Harvest Date",
    width: 250,
    valueFormatter: (p) =>
      DateTime.fromISO(p.value as string)
        .toLocaleString({
          month: "long",
          day: "numeric",
          weekday: "short",
          year: "numeric",
        })
        .replace(".", ""),
    valueGetter: (params) => {
      return DateTime.fromISO(params.value);
    },
    type: "date",
  },
  {
    field: "orderTotal",
    headerName: "Order Total",
    width: 150,
  },
  {
    field: "products",
    headerName: "Products",
    width: 150,
  },
  {
    field: "notes",
    headerName: "Notes",
    width: 200,
  },
];

const OrdersDataGrid = React.memo(
  (props: {
    orders: Order[] | null;
    onSelectionChange: (params: GridRowSelectionModel) => void;
    filters?: {
      on?: string;
    };
    initialPage?: number;
    onPaginationModelChange: (m: GridPaginationModel) => void;
    rowSelectionModel?: GridRowSelectionModel;
  }) => {
    const history = useHistory();
    const { grower } = useContext(UserContext);

    if (!grower) {
      throw new Error("Invalid UserContext");
    }

    const apiRef = useGridApiRef();
    const [initialState, setInitialState] = React.useState<GridInitialState>();

    const saveSnapshot = React.useCallback(() => {
      if (apiRef?.current?.exportState) {
        const currentState = apiRef.current.exportState();
        localStorage.setItem(
          ordersGridStateStorageKey,
          JSON.stringify(currentState)
        );
      }
    }, [apiRef]);

    React.useLayoutEffect(() => {
      const stateFromLocalStorage = localStorage?.getItem(
        ordersGridStateStorageKey
      );
      setInitialState(
        stateFromLocalStorage
          ? JSON.parse(stateFromLocalStorage)
          : {
              columns: {
                columnVisibilityModel: {
                  notes: false,
                },
              },
            }
      );

      // handle refresh and navigating away/refreshing
      window.addEventListener("beforeunload", saveSnapshot);

      return () => {
        // in case of an SPA remove the event-listener
        window.removeEventListener("beforeunload", saveSnapshot);
        saveSnapshot();
      };
    }, [saveSnapshot]);

    const density: GridDensity =
      (localStorage.getItem(ordersGridDensityStorageKey) as GridDensity) ||
      "standard";

    if (!props.orders) return null;

    let filterModel: GridFilterModel | undefined;

    if (props.filters) {
      if (props.filters.on) {
        filterModel = {
          items: [
            {
              field: "harvestDate",
              operator: "is",
              value: props.filters.on,
            },
          ],
        };
      }
    }

    if (!initialState) {
      return <CircularProgress />;
    }

    return (
      <StripedDataGrid
        apiRef={apiRef}
        columns={columns}
        rows={props.orders.map((o) => ({
          id: o.id,
          order: o,
          customer: o.customer.name,
          harvestDate: o.harvestDate,
          orderTotal: formatCurrency(
            o.orderProducts.reduce(
              (total: number, cV) => cV.price * cV.quantity + total,
              0
            ),
            grower.CountryISO
          ),
          products: [
            ...new Set(o.orderProducts.map((oP) => oP.product?.name)),
          ].join(", "),
          notes: o.notes,
        }))}
        initialState={{
          ...initialState,
          columns: {
            ...initialState.columns,
            orderedFields: [],
          },
          filter: undefined,
          sorting: {
            sortModel: [
              {
                field: "harvestDate",
                sort: "asc",
              },
            ],
          },
          pagination: {
            paginationModel: {
              page: props.initialPage,
            },
          },
        }}
        filterModel={filterModel}
        onRowSelectionModelChange={props.onSelectionChange}
        autoPageSize={true}
        onPaginationModelChange={props.onPaginationModelChange}
        slots={{
          toolbar: GridToolbar,
        }}
        density={density}
        onStateChange={({ density }) => {
          localStorage.setItem(ordersGridDensityStorageKey, density.value);
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            csvOptions: {
              allColumns: true,
            },
          },
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        disableRowSelectionOnClick
        onRowClick={(params) => history.push(`/home/orders/${params.id}`)}
        checkboxSelection
        rowSelectionModel={props.rowSelectionModel}
      />
    );
  }
);

export default function OrdersPage() {
  const classes = useStyles();
  const [orders, setOrders] = useState<Order[] | null>(null);
  const [selected, setSelected] = useState<GridRowSelectionModel | undefined>(
    undefined
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const [totalTrashed, setTotalTrashed] = useState(0);
  const [
    openTrashRecurringOrderDialog,
    setOpenTrashRecurringOrderDialog,
  ] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { handleError } = useContext(ErrorContext);

  const searchParams = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);
  const [dateRange, setDateRange] = useState(getDateRange(searchParams));

  function getDateRange(searchParams: URLSearchParams): DateRange {
    const fromParam = searchParams.get("from");
    const toParam = searchParams.get("to");
    const dateRangeStorage = window.localStorage.getItem("ordersDateRange");

    if (fromParam && toParam) {
      const from = DateTime.fromISO(fromParam);
      const to = DateTime.fromISO(toParam);
      const today = DateTime.now();
      const tomorrow = today.plus({ day: 1 });

      if (from.hasSame(today, "day") && to.hasSame(today, "day")) {
        return DateRange.Today;
      } else if (from.hasSame(tomorrow, "day") && to.hasSame(tomorrow, "day")) {
        return DateRange.Tomorrow;
      } else if (
        from.hasSame(today, "day") &&
        to.hasSame(today.plus({ day: 30 }), "day")
      ) {
        return DateRange.Next30Days;
      } else if (
        from.hasSame(today, "day") &&
        to.hasSame(today.plus({ day: 7 }), "day")
      ) {
        return DateRange.Next7Days;
      } else if (
        from.hasSame(today, "day") &&
        to.hasSame(today.endOf("year"), "day")
      ) {
        return DateRange.RestOfYear;
      } else if (
        from.equals(DateTime.fromISO(DateRangeAllTimeFromDate)) &&
        to.equals(DateTime.fromISO(DateRangeAllTimeToDate))
      ) {
        return DateRange.AllTime;
      } else {
        return DateRange.Custom;
      }
    } else {
      return dateRangeStorage
        ? parseInt(dateRangeStorage)
        : DateRange.Next30Days;
    }
  }

  useEffect(() => {
    const now = DateTime.now();
    if (!searchParams.get("from")) {
      let from: DateTime;
      switch (dateRange) {
        case DateRange.Tomorrow:
          from = now.plus({ day: 1 });
          break;
        case DateRange.AllTime:
          from = DateTime.fromISO(DateRangeAllTimeFromDate);
          break;
        default:
          from = now;
      }
      searchParams.set("from", from.toISODate());
    }
    if (!searchParams.get("to")) {
      let to: DateTime;
      switch (dateRange) {
        case DateRange.Next30Days:
          to = now.plus({ days: 30 });
          break;
        case DateRange.Next7Days:
          to = now.plus({ days: 7 });
          break;
        case DateRange.RestOfYear:
          to = now.endOf("year");
          break;
        case DateRange.Today:
          to = now;
          break;
        case DateRange.Tomorrow:
          to = now.plus({ day: 1 });
          break;
        case DateRange.AllTime:
          to = DateTime.fromISO(DateRangeAllTimeToDate);
          break;
        default:
          to = now.plus({ day: 30 });
      }
      searchParams.set("to", to.toISODate());
    }
    history.replace({ search: searchParams.toString() });
  }, [searchParams]);

  useEffect(() => {
    async function getOrders() {
      const from = searchParams.get("from");
      const to = searchParams.get("to");

      if (!from || !to) {
        return;
      }

      setBusy(true);

      try {
        setOrders(await ordersApi.getAll(from, to));
      } catch (error) {
        handleError(error);
      } finally {
        setBusy(false);
      }
    }
    getOrders();
  }, [totalTrashed, searchParams]);

  const handleSelectionChange = (params: GridRowSelectionModel) => {
    if (params.length > 1) {
      const selectionSet = new Set(selected);
      const result = params.filter((s) => !selectionSet.has(s));
      setSelected(result);
    } else {
      setSelected(params);
    }
  };

  const handleClickTrash = () => {
    if (!selected || !orders) {
      throw new Error("Invalid state");
    }

    const order = orders.find((v) => v.id === selected[0]);

    if (!order) {
      throw new Error("Invalid state");
    }

    if (order.recur) {
      setOpenTrashRecurringOrderDialog(true);
      return;
    }

    setDialogOpen(true);
  };

  const handleTrashDialogClose = async (okTrash?: boolean) => {
    setDialogOpen(false);

    if (!okTrash) {
      return;
    }

    trashOrder();
  };

  const handleOnCloseTrashRecurringOrderDialog = (
    value?: UpdateRecurringOrderMethods
  ) => {
    setOpenTrashRecurringOrderDialog(false);

    if (!value) {
      return;
    }

    trashOrder(value);
  };

  const trashOrder = async (value?: UpdateRecurringOrderMethods) => {
    if (!selected || !orders) {
      throw new Error("Invalid state");
    }

    setBusy(true);

    try {
      await ordersApi.trash(
        selected[0] as number,
        value === UpdateRecurringOrderMethods.ThisAndFollowingOrders
          ? {
              recurring: "thisAndFollowing",
            }
          : undefined
      );

      mixpanel.track("Delete Order");
    } catch (error) {
      handleError(error);
    }

    setTotalTrashed(totalTrashed + 1);
    setBusy(false);
  };

  const handleFromDateChange = (date: DateTime | null) => {
    if (date === null) {
      return;
    }

    const toParam = searchParams.get("to");

    if (!toParam) {
      throw new Error("Invalid state");
    }

    searchParams.set("from", date.toISODate());
    history.replace({ search: searchParams.toString() });
  };

  const handleToDateChange = (date: DateTime | null) => {
    if (date === null) {
      return;
    }

    const fromParam = searchParams.get("from");

    if (!fromParam) {
      throw new Error("Invalid state");
    }

    searchParams.set("to", date.toISODate());
    history.replace({ search: searchParams.toString() });
  };

  const handleChangeDateRange = (event: SelectChangeEvent<DateRange>) => {
    const range = event.target.value as DateRange;
    setDateRange(range);

    window.localStorage.setItem("ordersDateRange", range.toLocaleString());

    const dates = getDatesFromRange(range);

    searchParams.set("to", dates.to);
    searchParams.set("from", dates.from);
    history.replace({ search: searchParams.toString() });
  };

  let page = getSearchParamNumber(history, "page", 0);

  return (
    <Page title={"Orders"}>
      <div className={classes.toolbar}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/home/orders/add"
          disabled={busy}
        >
          Add
        </Button>
        <IconButton
          aria-label="delete"
          disabled={selected === undefined || selected.length === 0}
          onClick={handleClickTrash}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
        <FormControl variant="standard" className={classes.formControl}>
          <Select
            variant="standard"
            value={dateRange}
            onChange={handleChangeDateRange}
          >
            <MenuItem value={DateRange.Next7Days}>Next 7 days</MenuItem>
            <MenuItem value={DateRange.Next30Days}>Next 30 days</MenuItem>
            <MenuItem value={DateRange.RestOfYear}>Rest of Year</MenuItem>
            <MenuItem value={DateRange.Today}>Today</MenuItem>
            <MenuItem value={DateRange.Tomorrow}>Tomorrow</MenuItem>
            <MenuItem value={DateRange.Custom}>Custom</MenuItem>
            <MenuItem value={DateRange.AllTime}>All Time</MenuItem>
          </Select>
        </FormControl>
      </div>
      {dateRange === DateRange.Custom && (
        <Box mt={2}>
          <MobileDatePicker<DateTime>
            showToolbar={false}
            closeOnSelect={true}
            value={DateTime.fromISO(searchParams.get("from") || "")}
            onChange={handleFromDateChange}
            label={"From"}
            className={classes.datePicker}
            inputFormat="MMMM d, y"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                inputProps={{ ...params.inputProps, readOnly: true }}
              />
            )}
          />
          <MobileDatePicker<DateTime>
            showToolbar={false}
            closeOnSelect={true}
            value={DateTime.fromISO(searchParams.get("to") || "")}
            onChange={handleToDateChange}
            label={"To"}
            className={classes.datePicker}
            inputFormat="MMMM d, y"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                inputProps={{ ...params.inputProps, readOnly: true }}
              />
            )}
          />
        </Box>
      )}
      <div className={classes.gridContainer}>
        <OrdersDataGrid
          orders={orders}
          onSelectionChange={handleSelectionChange}
          initialPage={page}
          onPaginationModelChange={(m) => {
            searchParams.set("page", m.page.toString());
            history.replace({
              search: searchParams.toString(),
            });
          }}
          rowSelectionModel={selected}
        />
      </div>
      <ConfirmDialog
        title="Trash Orders"
        description={`Are you sure you want to trash the selected order?`}
        open={dialogOpen}
        onClose={handleTrashDialogClose}
      ></ConfirmDialog>
      <UpdateRecurringOrderDialog
        open={openTrashRecurringOrderDialog}
        onClose={handleOnCloseTrashRecurringOrderDialog}
        title={"Trash Recurring Order"}
      />
      <LoadingBackdrop open={busy} />
    </Page>
  );
}

const getDatesFromRange = (range: DateRange): { from: string; to: string } => {
  let from: string, to: string;
  const today = DateTime.now().startOf("day");

  switch (range) {
    case DateRange.Next7Days:
    case DateRange.Custom:
      from = today.toISODate();
      to = today.plus({ days: 7 }).toISODate();
      break;
    case DateRange.Next30Days:
      from = today.toISODate();
      to = today.plus({ days: 30 }).toISODate();
      break;
    case DateRange.RestOfYear:
      from = today.toISODate();
      to = today.endOf("year").toISODate();
      break;
    case DateRange.Today:
      from = today.toISODate();
      to = from;
      break;
    case DateRange.Tomorrow:
      from = today.plus({ day: 1 }).toISODate();
      to = from;
      break;
    case DateRange.AllTime:
      from = DateRangeAllTimeFromDate;
      to = DateRangeAllTimeToDate;
      break;
    default:
      throw new Error("Invalid range");
  }

  return {
    from,
    to,
  };
};
