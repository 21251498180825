import React from "react";
import { makeStyles } from "@mui/styles";
import logo from "../assets/logo.png";

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    marginTop: theme.spacing(8),
  },
  logo: {
    width: "100%",
  },
}));

export function LogoHeader() {
  const classes = useStyles();

  return (
    <div className={classes.logoContainer}>
      <img alt="seed leaf logo" className={classes.logo} src={logo} />
    </div>
  );
}
