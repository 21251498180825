import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import { seedsApi } from "../services/api/seeds";
import { DateTime } from "luxon";
import mixpanel from "mixpanel-browser";
import { goBackOrTo } from "../utils/history";
import { ErrorContext } from "../components/app-routes";
import Page from "../components/page";
import SeedForm, { SeedFormValues } from "../components/seed-form";
import LoadingBackdrop from "../components/loading-backdrop";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
  },
  actions: {
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

export default function AddSeedPage() {
  const history = useHistory();
  const classes = useStyles();
  const [busy, setBusy] = useState(false);
  const [saved, setSaved] = useState(false);
  const { handleError } = useContext(ErrorContext);

  const onSubmit = async (values: SeedFormValues) => {
    setBusy(true);

    try {
      await seedsApi.add({
        id: 0,
        name: values.name,
        variety: values.variety,
        notes: values.notes,
        archivedAt: values.archived ? DateTime.now().toISO() : null,
        lots: [],
      });

      mixpanel.track("Add Seed");

      setSaved(true);

      history.replace("/home/seeds");
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  return (
    <Page title="Add Seed">
      {
        <SeedForm
          initialValues={{
            name: "",
            id: 0,
            archived: false,
            notes: "",
            variety: "",
          }}
          onSubmit={onSubmit}
          saved={saved}
        >
          <Box mt={2} className={classes.actions}>
            <Button
              variant="outlined"
              disabled={busy}
              onClick={() => goBackOrTo(history, "/home/seeds")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={busy}
            >
              Add
            </Button>
          </Box>
        </SeedForm>
      }
      <LoadingBackdrop open={busy} />
    </Page>
  );
}
