import { getEnv } from "./env";
import mixpanel from "mixpanel-browser";
import { baseURL } from "./services/api/utilities/provider";

export function initMixpanel() {
  let token: string, debug: boolean;

  switch (getEnv()) {
    case "dev":
      token = "392928bad62433bffe391490faf9a784";
      debug = true;
      break;
    case "staging":
      token = "be8058d513b24e4aa618164c3edfc152";
      debug = true;
      break;
    case "production":
      token = "e37444c4b2bab5f36cc9431638e25d4a";
      debug = false;
  }

  if (!token) {
    throw new Error("Mixpanel token not defined");
  }

  mixpanel.init(token, {
    debug,
    api_host: `${baseURL}mixpanel`,
    cross_subdomain_cookie: false,
    ignore_dnt: true,
  });
}
