import { Prompt } from "react-router-dom";
import { FormikProps } from "formik";
import React from "react";

export function FormPrompt<T>(props: { form: FormikProps<T>; saved: boolean }) {
  return (
    <Prompt
      message="Warning, there are unsaved changes on this page.  Press OK to continue, or Cancel to stay on the current page."
      when={
        !props.saved &&
        JSON.stringify(props.form.values) !==
          JSON.stringify(props.form.initialValues)
      }
    />
  );
}
