import React from "react";
import { Formik, Form, FastField, Field, FieldProps } from "formik";
import * as yup from "yup";
import { AppTextField } from "./text-field";
import { validationMessages } from "../utils/validation/messages";
import { CustomerType } from "../models/customer-type";
import {
  MenuItem,
  Grid,
  Box,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import { FormPrompt } from "./form-prompt";
import { Customer } from "../models/customer";
import Autocomplete from "@mui/material/Autocomplete";

export interface CustomerFormValues {
  name: string;
  typeId: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhoneNumber: string;
  businessEmail: string;
  businessPhoneNumber: string;
  otherPhoneNumber: string;
  addressLine1: string;
  addressLine2: string;
  locality: string;
  postalCode: string;
  region: string;
  country: string;
  website: string;
  generalComments: string;
  deliveryInstructions: string;
  paymentInstructions: string;
  deliveryRoute: string;
  archived: boolean;
}

const validationSchema = yup.object({
  name: yup.string().trim().required(validationMessages.Required),
  typeId: yup.string().required(validationMessages.Required),
  contactFirstName: yup.string(),
  contactLastName: yup.string(),
  contactEmail: yup.string().email(validationMessages.Email),
  contactPhoneNumber: yup.string(),
  businessEmail: yup.string().email(validationMessages.Email),
  businessPhoneNumber: yup.string(),
  otherPhoneNumber: yup.string(),
  addressLine1: yup.string(),
  addressLine2: yup.string(),
  locality: yup.string(),
  postalCode: yup.string(),
  region: yup.string(),
  country: yup.string(),
  website: yup.string(),
  generalComments: yup.string(),
  deliveryInstructions: yup.string(),
  paymentInstructions: yup.string(),
  deliveryRoute: yup.string(),
  archived: yup.boolean(),
});

export default function CustomerForm(props: {
  children: JSX.Element;
  initialValues?: CustomerFormValues;
  onSubmit: (values: CustomerFormValues) => void;
  customerTypes: CustomerType[];
  customers: Customer[];
  saved: boolean;
}) {
  const deliveryRoutes = [
    ...new Set(
      props.customers.filter((c) => c.deliveryRoute).map((c) => c.deliveryRoute)
    ),
  ];
  return (
    <>
      <Formik
        initialValues={
          props.initialValues ?? {
            name: "",
            typeId: "",
            contactFirstName: "",
            contactLastName: "",
            contactEmail: "",
            contactPhoneNumber: "",
            businessEmail: "",
            businessPhoneNumber: "",
            otherPhoneNumber: "",
            addressLine1: "",
            addressLine2: "",
            locality: "",
            postalCode: "",
            region: "",
            country: "",
            website: "",
            generalComments: "",
            deliveryInstructions: "",
            paymentInstructions: "",
            deliveryRoute: "",
            archived: false,
          }
        }
        validationSchema={validationSchema}
        onSubmit={props.onSubmit}
      >
        {(f) => (
          <>
            <FormPrompt form={f} saved={props.saved} />

            <Form noValidate>
              <Grid item xs={12}>
                <Box>
                  <FormControl
                    variant="standard"
                    error={f.touched.archived && Boolean(f.errors.archived)}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="archived"
                          color="primary"
                          onChange={f.handleChange}
                          checked={f.values.archived}
                        />
                      }
                      label="Archived"
                    />
                    {f.touched.archived && f.errors.archived && (
                      <FormHelperText>{f.errors.archived}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FastField name="name">
                  {(fieldProps: FieldProps) => (
                    <AppTextField {...fieldProps} autoFocus label="Name" />
                  )}
                </FastField>
              </Grid>
              <Grid item xs={12}>
                <Field name={`typeId`}>
                  {(fieldProps: FieldProps) => (
                    <AppTextField {...fieldProps} select label={"Type"}>
                      {props.customerTypes
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((customerType) => (
                          <MenuItem
                            key={customerType.id}
                            value={customerType.id}
                          >
                            {customerType.name}
                          </MenuItem>
                        ))}
                    </AppTextField>
                  )}
                </Field>
                <Autocomplete
                  options={deliveryRoutes}
                  onChange={(_e, value) =>
                    f.setFieldValue("deliveryRoute", value)
                  }
                  renderInput={(params) => (
                    <Field
                      {...params}
                      label="Delivery Route"
                      component={AppTextField}
                      name="deliveryRoute"
                    />
                  )}
                  freeSolo
                  value={props.initialValues?.deliveryRoute}
                />
                <FastField
                  component={AppTextField}
                  name="contactFirstName"
                  label="Contact First Name"
                />
                <FastField
                  component={AppTextField}
                  name="contactLastName"
                  label="Contact Last Name"
                />
                <FastField
                  component={AppTextField}
                  name="contactEmail"
                  label="Contact Email"
                  type="email"
                />
                <FastField
                  component={AppTextField}
                  name="contactPhoneNumber"
                  label="Contact Phone Number"
                />
                <FastField
                  component={AppTextField}
                  name="businessEmail"
                  label="Business Email"
                  type="email"
                />
                <FastField
                  component={AppTextField}
                  name="businessPhoneNumber"
                  label="Business Phone Number"
                />
                <FastField
                  component={AppTextField}
                  name="otherPhoneNumber"
                  label="Other Phone Number"
                />
                <FastField
                  component={AppTextField}
                  name="addressLine1"
                  label="Address Line 1 - Auto fills to Deliveries Sheet"
                />
                <FastField
                  component={AppTextField}
                  name="addressLine2"
                  label="Address Line 2"
                />
                <FastField
                  component={AppTextField}
                  name="locality"
                  label="City"
                />
                <FastField
                  component={AppTextField}
                  name="postalCode"
                  label="Postal Code"
                />
                <FastField
                  component={AppTextField}
                  name="region"
                  label="Province / State"
                />
                <FastField
                  component={AppTextField}
                  name="country"
                  label="Country"
                />
                <FastField
                  component={AppTextField}
                  name="website"
                  label="Website"
                />
                <FastField
                  component={AppTextField}
                  name="generalComments"
                  label="General Comments"
                />
                <FastField
                  component={AppTextField}
                  name="deliveryInstructions"
                  label="Delivery Instructions"
                />
                <FastField
                  component={AppTextField}
                  name="paymentInstructions"
                  label="Payment Instructions"
                />
              </Grid>

              <div>{props.children}</div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
