import axios from "axios";
import { handleResponse } from "./response";

export const baseURL =
  (process.env.NODE_ENV === "development" ? "http://localhost:8080" : "") +
  "/api/";

const instance = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

const getAll = (resource: string, params?: any) => {
  return instance.get(`${resource}`, { params: params }).then(handleResponse);
};

const getSingle = (resource: string, id: number) => {
  return instance.get(`${resource}/${id}`).then(handleResponse);
};

const post = (resource: string, model?: object) => {
  return instance.post(`${resource}`, model).then(handleResponse);
};

const put = (resource: string, id: number, model: object) => {
  return instance.put(`${resource}/${id}`, model).then(handleResponse);
};

const patch = (resource: string, id: number | undefined, model: object) => {
  return instance
    .patch(`${resource}${id ? "/" + id : ""}`, model)
    .then(handleResponse);
};

const remove = (resource: string, id?: number, model?: object) => {
  return instance
    .delete(`${resource}` + (id ? `/${id}` : ""), {
      data: model,
    })
    .then(handleResponse);
};

export const apiProvider = {
  getAll,
  getSingle,
  post,
  put,
  patch,
  remove,
};
