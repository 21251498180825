import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  GridColDef,
  DataGrid,
  GridRowSelectionModel,
  GridRenderCellParams,
  GridPaginationModel,
  GridToolbar,
  useGridApiRef,
  GridDensity,
  GridInitialState,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid";
import { CircularProgress, IconButton, useControlled } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Page from "../components/page";
import { productsApi } from "../services/api/products";
import LoadingBackdrop from "../components/loading-backdrop";
import { Product } from "../models/product";
import ConfirmDialog from "../components/confirm-dialog";
import { ErrorContext } from "../components/app-routes";
import { ActiveFilterControls } from "../components/active-filter-controls";
import mixpanel from "mixpanel-browser";
import { getSearchParamNumber } from "../utils/history";
import { StripedDataGrid } from "../components/striped-data-grid";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: "750px",
    width: "100%",
    paddingTop: theme.spacing(2),
  },
}));

const productsGridStateStorageKey = "productsGridState";
const productsGridDensityStorageKey = "productsGridDensity";

const columns: GridColDef[] = [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    hideable: false,
    headerName: "Checkbox",
  },
  {
    field: "name",
    headerName: "Name",
    width: 400,
  },
  {
    field: "crops",
    headerName: "Crops",
    flex: 1,
  },
  {
    field: "notes",
    headerName: "Notes",
    width: 200,
  },
];

const ProductsDataGrid = React.memo(
  (props: {
    initialPage: number | undefined;
    onPaginationModelChange: (model: GridPaginationModel) => void;
    products: Product[] | null | undefined;
    onSelectionChange: (params: GridRowSelectionModel) => void;
  }) => {
    const history = useHistory();
    const apiRef = useGridApiRef();
    const [initialState, setInitialState] = React.useState<GridInitialState>();

    const saveSnapshot = React.useCallback(() => {
      if (apiRef?.current?.exportState) {
        const currentState = apiRef.current.exportState();
        localStorage.setItem(
          productsGridStateStorageKey,
          JSON.stringify(currentState)
        );
      }
    }, [apiRef]);

    React.useLayoutEffect(() => {
      const stateFromLocalStorage = localStorage?.getItem(
        productsGridStateStorageKey
      );
      setInitialState(
        stateFromLocalStorage
          ? JSON.parse(stateFromLocalStorage)
          : {
              columns: {
                columnVisibilityModel: {
                  notes: false,
                },
              },
            }
      );

      // handle refresh and navigating away/refreshing
      window.addEventListener("beforeunload", saveSnapshot);

      return () => {
        // in case of an SPA remove the event-listener
        window.removeEventListener("beforeunload", saveSnapshot);
        saveSnapshot();
      };
    }, [saveSnapshot]);

    const density: GridDensity =
      (localStorage.getItem(productsGridDensityStorageKey) as GridDensity) ||
      "standard";

    if (!props.products) {
      return null;
    }

    if (!initialState) {
      return <CircularProgress />;
    }

    return (
      <StripedDataGrid
        apiRef={apiRef}
        columns={columns}
        rows={props.products.map((p) => ({
          id: p.id,
          name: p.name,
          crops: p.productCrops.map((pC) => pC.crop.name).join(),
          notes: p.notes,
        }))}
        initialState={{
          ...initialState,
          filter: undefined,
          sorting: {
            sortModel: [
              {
                field: "name",
                sort: "asc",
              },
            ],
          },
          pagination: {
            paginationModel: {
              page: props.initialPage,
            },
          },
        }}
        autoPageSize={true}
        checkboxSelection
        onRowSelectionModelChange={props.onSelectionChange}
        onPaginationModelChange={props.onPaginationModelChange}
        slots={{
          toolbar: GridToolbar,
        }}
        density={density}
        onStateChange={({ density }) => {
          localStorage.setItem(productsGridDensityStorageKey, density.value);
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            csvOptions: {
              allColumns: true,
            },
          },
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        disableRowSelectionOnClick
        onRowClick={(params) => history.push(`/home/products/${params.id}`)}
      />
    );
  }
);

export default function Products() {
  const classes = useStyles();
  const [products, setProducts] = useState<Product[] | null>(null);
  const [selected, setSelected] = useState<number[]>([]);
  const [totalTrashed, setTotalTrashed] = useState<number>(0);
  const [busy, setBusy] = useState(false);
  const [trashDialogOpen, setTrashDialogOpen] = useState(false);
  const { handleError } = useContext(ErrorContext);
  const showArchivedCheckedStorageKey = "productsShowArchivedChecked";
  const showActiveCheckedStorageKey = "productsShowActiveChecked";
  const [showArchivedChecked, setShowArchivedChecked] = useState(
    window.localStorage.getItem(showArchivedCheckedStorageKey) !==
      false.toString()
  );
  const [showActiveChecked, setShowActiveChecked] = useState(
    window.localStorage.getItem(showActiveCheckedStorageKey) !==
      false.toString()
  );
  const history = useHistory();

  useEffect(() => {
    async function getProducts() {
      setBusy(true);
      try {
        setProducts(await productsApi.getAll());
      } catch (error) {
        handleError(error);
      } finally {
        setBusy(false);
      }
    }
    getProducts();
  }, [totalTrashed]);

  const handleSelectionChange = (params: GridRowSelectionModel): void => {
    setSelected(params as number[]);
  };

  const handleClickTrash = () => {
    setTrashDialogOpen(true);
  };

  const handleTrashDialogClose = async (okTrash?: boolean) => {
    setTrashDialogOpen(false);

    if (!okTrash) {
      return;
    }

    setBusy(true);

    let trashed = 0;
    for (let i = 0; i < selected.length; i++) {
      try {
        await productsApi.trash(selected[i]);

        trashed++;

        mixpanel.track("Delete Product");
      } catch (error) {
        handleError(error);
        break;
      }
    }

    setTotalTrashed(totalTrashed + trashed);
    setBusy(false);
  };

  const handleShowActiveChanged = (checked: boolean) => {
    setShowActiveChecked(checked);
    window.localStorage.setItem(
      showActiveCheckedStorageKey,
      checked.toString()
    );
  };

  const handleShowArchivedChanged = (checked: boolean) => {
    setShowArchivedChecked(checked);
    window.localStorage.setItem(
      showArchivedCheckedStorageKey,
      checked.toString()
    );
  };

  return (
    <Page title={"Products"}>
      <div>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/home/products/add"
          disabled={busy}
        >
          Add
        </Button>
        <IconButton
          aria-label="delete"
          disabled={busy || selected.length === 0}
          onClick={handleClickTrash}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
        <ActiveFilterControls
          showActiveChecked={showActiveChecked}
          showArchivedChecked={showArchivedChecked}
          onChangeShowActive={handleShowActiveChanged}
          onChangeShowArchived={handleShowArchivedChanged}
        />
      </div>
      <div className={classes.gridContainer}>
        <ProductsDataGrid
          products={products?.filter(
            (p) =>
              (showActiveChecked && p.archivedAt === null) ||
              (showArchivedChecked && p.archivedAt !== null)
          )}
          onSelectionChange={handleSelectionChange}
          initialPage={getSearchParamNumber(history, "page", 0)}
          onPaginationModelChange={(m) => {
            const params = new URLSearchParams(`page=${m.page}`);
            history.replace({
              search: params.toString(),
            });
          }}
        />
      </div>
      <ConfirmDialog
        title="Trash Products"
        description={`Are you sure you want to trash ${
          selected.length
        } product${selected.length > 1 ? "s" : ""}?`}
        open={trashDialogOpen}
        onClose={handleTrashDialogClose}
      ></ConfirmDialog>
      <LoadingBackdrop open={busy} />
    </Page>
  );
}
