import React from "react";
import {
  Container,
  Box,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Link as RouterLink } from "react-router-dom";
import logo from "../assets/logo.png";
import MenuIcon from "@mui/icons-material/Menu";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    width: "100%",
  },
  actions: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  toolbar: {
    flexDirection: "column",
    alignItems: "end",
    justifyContent: "center",
  },
}));

export function LandingPage() {
  const classes = useStyles();

  return (
    <>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <Button variant="contained" component={RouterLink} to="/log-in">
            Log In
          </Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm">
        <Box className={classes.paper}>
          <img alt="seed leaf logo" className={classes.logo} src={logo} />
          <Box className={classes.actions} m={5} textAlign={"center"}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              component={RouterLink}
              to="/sign-up"
            >
              Sign Up
            </Button>
            <Box m={2}>
              <Typography variant="body1">
                30-day free trial then $125 US/year or $15 US/month
              </Typography>
              <Typography variant="caption">
                Get started without a credit card
              </Typography>
            </Box>
            <Box p={4}>
              <Typography variant="subtitle1">
                Need more info before signing up? Read about all{" "}
                <a href="https://seedleaf.co">SeedLeaf features here</a>.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
}
