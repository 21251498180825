import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState, useCallback, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Crop } from "../models/crop";
import { cropsApi } from "../services/api/crops";
import {
  GridColDef,
  GridRowSelectionModel,
  GridPaginationModel,
  GridToolbar,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridDensity,
  useGridApiRef,
  GridInitialState,
} from "@mui/x-data-grid";
import { CircularProgress, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "../components/confirm-dialog";
import LoadingBackdrop from "../components/loading-backdrop";
import { Grower } from "../models/grower";
import { UserContext, ErrorContext } from "../components/app-routes";
import Page from "../components/page";
import { ActiveFilterControls } from "../components/active-filter-controls";
import mixpanel from "mixpanel-browser";
import { getSearchParamNumber } from "../utils/history";
import { StripedDataGrid } from "../components/striped-data-grid";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  gridContainer: {
    height: "750px",
    width: "100%",
    paddingTop: theme.spacing(2),
  },
}));

const cropsGridStateStorageKey = "cropsGridState";
const cropsGridDensityStorageKey = "cropsGridDensity";

export const unitSystemLabel = (grower: Grower): string =>
  grower.UnitSystem === "metric" ? "g" : "oz";

export const unitSystemLabelOther = (grower: Grower): string =>
  grower.UnitSystem === "metric" ? "oz" : "g";

const CropsDataGrid = React.memo(
  (props: {
    crops: Crop[] | null | undefined;
    onSelectionChange: (params: GridRowSelectionModel) => void;
    onPaginationModelChange: (model: GridPaginationModel) => void;
    page?: number;
  }) => {
    const apiRef = useGridApiRef();
    const [initialState, setInitialState] = React.useState<GridInitialState>();
    const history = useHistory();

    const saveSnapshot = React.useCallback(() => {
      if (apiRef?.current?.exportState) {
        const currentState = apiRef.current.exportState();
        localStorage.setItem(
          cropsGridStateStorageKey,
          JSON.stringify(currentState)
        );
      }
    }, [apiRef]);

    React.useLayoutEffect(() => {
      const stateFromLocalStorage = localStorage?.getItem(
        cropsGridStateStorageKey
      );
      setInitialState(
        stateFromLocalStorage
          ? JSON.parse(stateFromLocalStorage)
          : {
              columns: {
                columnVisibilityModel: {
                  overnightSoak: false,
                  soakTimeMinutes: false,
                  germinationDays: false,
                  blackoutDays: false,
                  notes: false,
                },
              },
            }
      );

      // handle refresh and navigating away/refreshing
      window.addEventListener("beforeunload", saveSnapshot);

      return () => {
        // in case of an SPA remove the event-listener
        window.removeEventListener("beforeunload", saveSnapshot);
        saveSnapshot();
      };
    }, [saveSnapshot]);

    const density: GridDensity =
      (localStorage.getItem(cropsGridDensityStorageKey) as GridDensity) ||
      "standard";

    const context = useContext(UserContext);
    const grower = context.grower;

    if (!grower) {
      throw new Error("Invalid context");
    }

    const columns: GridColDef[] = [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        hideable: false,
        headerName: "Checkbox",
      },
      {
        field: "name",
        headerName: "Crop Name",
        width: 400,
      },
      {
        field: "seedName",
        headerName: "Seed Name",
        width: 150,
      },
      {
        field: "seedVariety",
        headerName: "Seed Variety",
        width: 150,
      },
      {
        field: "seedingRate",
        headerName: `Seeding Rate (${unitSystemLabel(grower)})`,
        width: 150,
      },
      {
        field: "daysToMaturity",
        headerName: "Days To Maturity",
        width: 150,
      },
      {
        field: "cutYield",
        headerName: `Cut Yield (${unitSystemLabel(grower)})`,
        width: 150,
      },
      {
        field: "blackoutDays",
        headerName: "Blackout Days",
        width: 150,
      },
      {
        field: "overnightSoak",
        headerName: "Overnight Soak",
        width: 150,
      },
      {
        field: "soakTimeMinutes",
        headerName: "Soak Time (Minutes)",
        width: 150,
      },
      {
        field: "germinationDays",
        headerName: "Germination Days",
        width: 150,
      },
      {
        field: "notes",
        headerName: "Notes",
        width: 150,
      },
    ];

    if (!props.crops) return null;

    if (!initialState) {
      return <CircularProgress />;
    }

    return (
      <StripedDataGrid
        apiRef={apiRef}
        columns={columns}
        rows={props.crops.map((c) => ({
          id: c.id,
          name: c.name,
          seedName: c.seed?.name,
          seedVariety: c.seed?.variety,
          seedingRate: c.seedingRate,
          overnightSoak: c.overnightSoak,
          soakTimeMinutes: c.soakTimeMinutes,
          germinationDays: c.germinationDays,
          blackoutDays: c.blackoutDays,
          daysToMaturity: c.daysToMaturity,
          cutYield: c.cutYield,
          notes: c.notes,
        }))}
        initialState={{
          ...initialState,
          filter: undefined,
          sorting: {
            sortModel: [
              {
                field: "name",
                sort: "asc",
              },
            ],
          },
          pagination: {
            paginationModel: {
              page: props.page,
            },
          },
        }}
        checkboxSelection
        disableRowSelectionOnClick
        onRowClick={(params) => history.push(`/home/crops/${params.id}`)}
        onRowSelectionModelChange={props.onSelectionChange}
        autoPageSize={true}
        onPaginationModelChange={props.onPaginationModelChange}
        slots={{
          toolbar: GridToolbar,
        }}
        density={density}
        onStateChange={({ density }) => {
          localStorage.setItem(cropsGridDensityStorageKey, density.value);
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            csvOptions: {
              allColumns: true,
            },
          },
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
      />
    );
  }
);

export default function CropsPage() {
  const classes = useStyles();
  const [crops, setCrops] = useState<Crop[] | null>(null);
  const [selected, setSelected] = useState<number[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const [totalTrashed, setTotalTrashed] = useState(0);
  const { handleError } = useContext(ErrorContext);
  const showArchivedCheckedStorageKey = "cropsShowArchivedChecked";
  const showActiveCheckedStorageKey = "cropsShowActiveChecked";
  const [showArchivedChecked, setShowArchivedChecked] = useState(
    window.localStorage.getItem(showArchivedCheckedStorageKey) !==
      false.toString()
  );
  const [showActiveChecked, setShowActiveChecked] = useState(
    window.localStorage.getItem(showActiveCheckedStorageKey) !==
      false.toString()
  );
  const history = useHistory();

  useEffect(() => {
    async function getCrops() {
      try {
        const crops = await cropsApi.getAll();
        setCrops(crops);
      } catch (error) {
        handleError(error);
      }
    }
    getCrops();
  }, [totalTrashed]);

  const handleSelectionChange = useCallback((params: GridRowSelectionModel) => {
    setSelected(params as number[]);
  }, []);

  const handleClickTrash = () => {
    setDialogOpen(true);
  };

  const handleTrashDialogClose = async (okTrash?: boolean) => {
    setDialogOpen(false);
    if (!okTrash) {
      return;
    }

    setBusy(true);

    let trashed = 0;
    for (let i = 0; i < selected.length; i++) {
      try {
        await cropsApi.trash(selected[i]);

        trashed++;

        mixpanel.track("Delete Crop");
      } catch (error) {
        handleError(error);
        break;
      }
    }

    setTotalTrashed(totalTrashed + trashed);
    setBusy(false);
  };

  const handleShowArchivedChanged = (checked: boolean) => {
    setShowArchivedChecked(checked);
    window.localStorage.setItem(
      showArchivedCheckedStorageKey,
      checked.toString()
    );
  };

  const handleShowActiveChanged = (checked: boolean) => {
    setShowActiveChecked(checked);
    window.localStorage.setItem(
      showActiveCheckedStorageKey,
      checked.toString()
    );
  };

  let page = getSearchParamNumber(history, "page", 0);

  return (
    <Page title="Crops">
      <div>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/home/crops/add"
          disabled={busy}
        >
          Add
        </Button>
        <IconButton
          aria-label="delete"
          disabled={busy || selected.length === 0}
          onClick={handleClickTrash}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
        <ActiveFilterControls
          showActiveChecked={showActiveChecked}
          showArchivedChecked={showArchivedChecked}
          onChangeShowActive={handleShowActiveChanged}
          onChangeShowArchived={handleShowArchivedChanged}
        />
      </div>
      <div className={classes.gridContainer}>
        <CropsDataGrid
          crops={crops?.filter(
            (c) =>
              (showActiveChecked && c.archivedAt === null) ||
              (showArchivedChecked && c.archivedAt !== null)
          )}
          onSelectionChange={handleSelectionChange}
          onPaginationModelChange={(m) => {
            const params = new URLSearchParams(`page=${m.page}`);
            history.replace({
              search: params.toString(),
            });
          }}
          page={page}
        />
      </div>
      <ConfirmDialog
        title="Trash Crops"
        description={`Are you sure you want to trash ${selected.length} crop${
          selected.length > 1 ? "s" : ""
        }?`}
        open={dialogOpen}
        onClose={handleTrashDialogClose}
      ></ConfirmDialog>
      <LoadingBackdrop open={busy} />
    </Page>
  );
}
