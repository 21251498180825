import { apiProvider } from "./utilities/provider";

export const passwordResetsApi = {
  create: (email: string): Promise<void> =>
    apiProvider.post("users/password-resets", {
      data: {
        Email: email,
      },
    }),
  reset: (id: string, token: string, password: string): Promise<void> =>
    apiProvider.post(`users/password-resets/${id}`, {
      data: {
        Password: password,
        Token: token,
      },
    }),
};
