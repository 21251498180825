import { apiProvider, baseURL } from "./utilities/provider";
import { DateTime } from "luxon";
import { JsonDocument } from "./utilities/json";
import { amount } from "../../models/order";

export interface SalesReportData {
  OrderNumber: number;
  CustomerName: string;
  CustomerType: string;
  Price: number;
  Quantity: number;
  Total: number;
  HarvestDate: string;
  ProductName: string;
  SizeName: string;
}

export interface SalesReport {
  data: SalesReportData[];
}

export interface CropProductionReportSimple {
  ReportData: [
    {
      SowingDate: string;
      Crop: string;
      SowingRate: number;
      TraysSowed: number;
      ExpectedYield: number;
      TotalSeedWeight: number;
      HarvestDate: string;
      SeedLots: string;
      Orders: string;
    }
  ];
}

export interface CropProductionReportAdvanced {
  ReportData: [
    {
      SoakedDateExpected?: string;
      SoakedDateActual?: string;
      SoakedBy?: string;
      SoakedNotes?: string;
      SoakedTraysExpected?: number;
      SoakedTraysActual?: number;
      SowedDateExpected?: string;
      SowedDateActual?: string;
      SowedBy?: string;
      SowedNotes?: string;
      SowedTraysExpected?: number;
      SowedTraysActual?: number;
      SowedCropSowingRate?: number;
      SowedCropName?: string;
      SowedCropExpectedYield?: number;
      SowedCropNotes?: string;
      SowedSeedName?: string;
      SowedSeedVariety?: string;
      SowedSeedNotes?: string;
      SowedSeedLotNumbers?: string;
      SowedSeedLotNotes?: string;
      SoakedSeedLotNumbers?: string;
      SoakedSeedLotNotes?: string;
      SeedLots?: string;
      SeedLotNotes?: string;
      BlackoutDateExpected?: string;
      BlackoutDateActual?: string;
      BlackoutBy?: string;
      BlackoutNotes?: string;
      BlackoutTraysExpected?: number;
      BlackoutTraysActual?: number;
      UncoveredDateExpected?: string;
      UncoveredDateActual?: string;
      UncoveredBy?: string;
      UncoveredNotes?: string;
      UncoveredTraysExpected?: number;
      UncoveredTraysActual?: number;
      HarvestedDateExpected?: string;
      HarvestedDateActual?: string;
      HarvestedBy?: string;
      HarvestedNotes?: string;
      HarvestedTraysActual?: number;
      HarvestedTraysExpected?: number;
      HarvestedYieldExpected?: number;
      HarvestedYieldActual?: number;
      Orders?: string;
    }
  ];
}

export interface PackagingReportSimple {
  Rows: {
    Product: string;
    Size: string;
    Weight?: number;
    Quantity: number;
    HarvestDate: string;
  }[];
}

export interface PackagingReportAdvanced {
  Rows: {
    DateActual?: string;
    DateExpected?: string;
    CompletedBy?: string;
    Notes?: string;
    Product?: string;
    Size?: string;
    Weight?: number;
    QuantityExpected?: number;
    QuantityActual?: number;
    HarvestDate?: string;
  }[];
}

export interface OrdersSummaryReport {
  From: string;
  To: string;
  TotalSales: amount;
  CustomerSales: [
    {
      Name: string;
      ID: number;
      TotalSales: amount;
      Type: string;
    }
  ];
  ProductSales: [
    {
      Name: string;
      ID: number;
      TotalSales: amount;
    }
  ];
  ProductSizeSales: [
    {
      ID: number;
      Name: string;
      TotalSales: amount;
      SizeType: string;
      SizeID?: number;
      SizeName?: string;
    }
  ];
  CustomerProductSizeSales: [
    {
      ID: number;
      Name: string;
      ProductID: number;
      ProductName: string;
      TotalSales: amount;
      SizeType: string;
      SizeID?: number;
      SizeName?: string;
    }
  ];
}

export const reportsApi = {
  getOrdersSummaryReport: (
    from: DateTime,
    to: DateTime
  ): Promise<OrdersSummaryReport> => {
    return getReport<void | OrdersSummaryReport>(from, to, "orders-summary");
  },
  getSalesReport: (
    from: DateTime,
    to: DateTime
  ): Promise<SalesReportData[]> => {
    return getReport<void | SalesReport>(from, to, "sales");
  },
  getCropProductionReport: (
    from: DateTime,
    to: DateTime
  ): Promise<CropProductionReportSimple | CropProductionReportAdvanced> => {
    return getReport<
      CropProductionReportSimple | CropProductionReportAdvanced | void
    >(from, to, "crop-production");
  },
  getPackagingReport: (
    from: DateTime,
    to: DateTime
  ): Promise<PackagingReportSimple | PackagingReportAdvanced> => {
    return getReport<PackagingReportSimple>(from, to, "packaging");
  },
};

function getReport<T>(from: DateTime, to: DateTime, route: string): any {
  const fromDate = from.toISODate();
  const toDate = to.toISODate();

  return apiProvider
    .getAll(`reports/${route}`, {
      from: fromDate,
      to: toDate,
    })
    .then((d: JsonDocument<T>) => d.data);
}
