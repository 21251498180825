import { apiProvider, baseURL } from "./utilities/provider";
import { JsonDocument } from "./utilities/json";
import { DateTime } from "luxon";
import { CompletedTask, Tasks, TasksData } from "../../models/tasks";

export const tasksApi = {
  getAll: (date: DateTime, period: string): Promise<TasksData> =>
    apiProvider
      .getAll("tasks", { date: date.toISODate(), period })
      .then((d: JsonDocument<TasksData>) => d.data),
  getOne: (id: number): Promise<CompletedTask> =>
    apiProvider
      .getSingle("tasks", id)
      .then((d: JsonDocument<CompletedTask>) => d.data),
  getPdf: (date: DateTime): void => {
    window.open(`${baseURL}tasks/pdf?date=${date.toISODate()}`);
  },
  complete: (data: CompletedTask): Promise<void> =>
    apiProvider.post("tasks", {
      data,
    }),
  update: (data: CompletedTask): Promise<void> =>
    apiProvider.put("tasks", data.id, {
      data,
    }),
  uncomplete: (id: number): Promise<void> => apiProvider.remove("tasks", id),
};
