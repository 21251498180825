import { apiProvider } from "./utilities/provider";
import { Size } from "../../models/size";

interface SizeDto {
  id: number;
  name: string;
}

export const sizesApi = {
  getAll: (): Promise<Size[]> =>
    apiProvider.getAll("sizes").then((d: SizeDto[]) => d.map(mapFromDto)),
};

const mapFromDto = (from: SizeDto): Size => ({
  id: from.id,
  name: from.name,
});
