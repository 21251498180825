import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import React, { ReactElement } from "react";

export default function ConfirmDialog(props: {
  title: string;
  description: string | ReactElement;
  open: boolean;
  onClose: (ok?: boolean) => void;
}) {
  const handleCancel = () => {
    props.onClose();
  };

  const handleOk = () => {
    props.onClose(true);
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleCancel}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle id="confirm-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          {props.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary" autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
