import React, { useState, useContext } from "react";
import {
  TextField,
  Box,
  Button,
  Container,
  Typography,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { LogoHeader } from "../components/logo-header";
import { passwordResetsApi } from "../services/api/password-resets";
import { ErrorContext } from "../components/app-routes";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    textAlign: "center",
  },
  fieldContainer: {
    paddingTop: theme.spacing(2),
  },
}));

export function ForgotPassword() {
  const classes = useStyles();
  const form = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Enter a valid email")
        .required("Email is required"),
    }),
    onSubmit: () => {
      sendPasswordResetEmail();
    },
  });

  const [error, setError] = useState<string>("");
  const [busy, setBusy] = useState<boolean>(false);
  const { handleError } = useContext(ErrorContext);

  const sendPasswordResetEmail = async () => {
    setError("");
    setBusy(true);
    try {
      await passwordResetsApi.create(form.values.email);
      alert("We sent you an email with instructions to reset your password.");
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <LogoHeader />
      <Paper elevation={3}>
        <div className={classes.formContainer}>
          <Typography gutterBottom variant="h5" component="h2">
            Forgot Password
          </Typography>
          <Typography variant="subtitle1">
            Complete the form below and we'll send you an email with
            instructions to reset your password.
          </Typography>
          <form onSubmit={form.handleSubmit} noValidate>
            <Box mt={2}>
              <TextField
                id="email"
                name="email"
                type="email"
                label="Email"
                value={form.values.email}
                onChange={form.handleChange}
                fullWidth
                variant="outlined"
                autoFocus
                error={form.touched.email && Boolean(form.errors.email)}
                helperText={form.touched.email && form.errors.email}
              />
            </Box>
            {error && (
              <Box mt={2}>
                <Typography variant="body1" color="error">
                  {error}
                </Typography>
              </Box>
            )}
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={busy}
              >
                Send Email
              </Button>
            </Box>
          </form>
        </div>
      </Paper>
    </Container>
  );
}
