import { apiProvider } from "./utilities/provider";
import { JsonDocument } from "./utilities/json";
import { Seed, SeedLot } from "../../models/seed";

export const seedLotsApi = {
  add: (seed: Seed, seedLot: SeedLot): Promise<Seed> =>
    apiProvider
      .post(`seeds/${seed.id}/lots`, {
        data: seedLot,
      })
      .then((d: JsonDocument<Seed>) => d.data),
  update: (seed: Seed, seedLot: SeedLot): Promise<SeedLot> =>
    apiProvider.put(`seeds/${seed.id}/lots`, seedLot.id, {
      data: seedLot,
    }),
  getAll: (seed: Seed): Promise<SeedLot[]> =>
    apiProvider
      .getAll(`seeds/${seed.id}/lots`)
      .then((d: JsonDocument<SeedLot[]>) => d.data),
  trash: (seed: Seed, seedLot: SeedLot): Promise<any> =>
    apiProvider.remove(`seeds/${seed.id}/lots`, seedLot.id),
};
