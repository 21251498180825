import {
  MenuItem,
  Button,
  Box,
  InputAdornment,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext } from "react";
import { Crop } from "../models/crop";
import { Size } from "../models/size";
import {
  Formik,
  Form,
  FieldProps,
  FormikProps,
  FieldArray,
  FastField,
} from "formik";
import * as yup from "yup";
import { AppTextField } from "../components/text-field";
import { validationMessages } from "../utils/validation/messages";
import { ProductTypes } from "../services/api/products";
import { unitSystemLabel } from "../pages/crops-page";
import { UserContext } from "./app-routes";
import { FormPrompt } from "./form-prompt";
import { getAllInfoByISO } from "iso-country-currency";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  actions: {
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

export interface ProductFormValues {
  name: string;
  productCrops: { id: number; cropId: string; blend: string }[];
  productSizes: {
    id: number;
    type: string;
    sizeId?: string;
    weight?: string;
    price: string;
  }[];
  notes: string;
  archived: boolean;
}

const validationSchema = yup.object({
  name: yup.string().trim().required(validationMessages.Required),
  productCrops: yup.array().of(
    yup.object().shape({
      cropId: yup.number().required(validationMessages.Required),
      blend: yup
        .number()
        .typeError(validationMessages.Number)
        .required(validationMessages.Required)
        .max(100, validationMessages.Max)
        .positive(validationMessages.Positive),
    })
  ),
  productSizes: yup.array().of(
    yup.object().shape({
      type: yup.string().trim().required(validationMessages.Required),
      sizeId: yup.number().when("type", {
        is: ProductTypes.Cut,
        then: yup.number().required(validationMessages.Required),
      }),
      weight: yup.number().when("type", {
        is: ProductTypes.Cut,
        then: yup
          .number()
          .typeError(validationMessages.Number)
          .required(validationMessages.Required)
          .positive(validationMessages.Positive),
      }),
      price: yup.string().required(validationMessages.Required),
    })
  ),
  notes: yup.string().max(5000),
  archived: yup.boolean(),
});

const Divider = () => {
  return (
    <hr style={{ borderTop: "1px", margin: "10px 0px", opacity: "50%" }} />
  );
};

export default function ProductForm(props: {
  children: JSX.Element;
  initialValues: ProductFormValues;
  onSubmit: (values: ProductFormValues) => void;
  crops: Crop[];
  sizes: Size[];
  saved: boolean;
}) {
  const classes = useStyles();
  const context = useContext(UserContext);
  const grower = context.grower;

  if (!grower) {
    throw new Error("Invalid context");
  }

  const onSubmit = (values: ProductFormValues) => {
    values.productSizes.forEach((pS) => {
      if (pS.type === "tray") {
        pS.sizeId = undefined; // clear if was cut
        pS.weight = undefined; // clear if was cut
      }
    });
    props.onSubmit(values);
  };

  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={validationSchema}
      validate={(values) => {
        if (
          values.productSizes.some((s) => s.type === ProductTypes.Tray) &&
          values.productCrops.length > 1
        ) {
          return {
            productCrops: values.productCrops.map(() => ({
              blend: "Blended products cannot be sold as trays",
            })),
          };
        }
        const sum = values.productCrops.reduce(
          (t, c) => parseFloat(c.blend) + t,
          0
        );
        if (sum !== 100) {
          return {
            productCrops: values.productCrops.map(() => ({
              blend: "Proportions do not total 100",
            })),
          };
        }
        return {};
      }}
      onSubmit={onSubmit}
    >
      {(formProps: FormikProps<ProductFormValues>) => (
        <>
          <FormPrompt form={formProps} saved={props.saved} />
          <Box>
            <FormControl
              variant="standard"
              error={
                formProps.touched.archived && Boolean(formProps.errors.archived)
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="archived"
                    color="primary"
                    onChange={formProps.handleChange}
                    checked={formProps.values.archived}
                  />
                }
                label="Archived"
              />
              {formProps.touched.archived && formProps.errors.archived && (
                <FormHelperText>{formProps.errors.archived}</FormHelperText>
              )}
            </FormControl>
          </Box>

          <Form noValidate>
            <FastField name="name">
              {(fieldProps: FieldProps) => (
                <AppTextField {...fieldProps} autoFocus label="Product Name" />
              )}
            </FastField>
            <div>
              <Divider />
              <FieldArray
                name="productCrops"
                render={(arrayHelpers) => (
                  <div>
                    {formProps.values.productCrops.map((c, i) => (
                      <div key={`productCrop.${i}`}>
                        {i > 0 && i < formProps.values.productCrops.length && (
                          <Divider />
                        )}
                        <FastField name={`productCrops[${i}].cropId`}>
                          {(fieldProps: FieldProps) => (
                            <AppTextField
                              {...fieldProps}
                              select
                              label={
                                "Crop" +
                                (formProps.values.productCrops.length > 1
                                  ? ` ${i + 1}`
                                  : "")
                              }
                            >
                              {props.crops
                                .filter(
                                  (c) =>
                                    c.archivedAt === null ||
                                    props.initialValues.productCrops.some(
                                      (pC) => parseInt(pC.cropId) === c.id
                                    )
                                )
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((crop) => (
                                  <MenuItem key={crop.id} value={crop.id}>
                                    {crop.name}
                                  </MenuItem>
                                ))}
                            </AppTextField>
                          )}
                        </FastField>
                        {formProps.values.productCrops.length > 1 && (
                          <FastField
                            component={AppTextField}
                            name={`productCrops[${i}].blend`}
                            label={`Crop ${i + 1} Proportion in Mix (%)`}
                          />
                        )}
                      </div>
                    ))}
                    <Box mt={2} className={classes.actions}>
                      {formProps.values.productCrops.length > 1 && (
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => {
                            arrayHelpers.remove(
                              formProps.values.productCrops.length - 1
                            );

                            formProps.values.productCrops[0].blend = "100";
                          }}
                        >
                          Remove Blend
                        </Button>
                      )}
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() =>
                          arrayHelpers.push({ id: 0, cropId: "", blend: "" })
                        }
                      >
                        Add Blend
                      </Button>
                    </Box>
                  </div>
                )}
              />
              <Divider />
              <FieldArray
                name="productSizes"
                render={(arrayHelpers) => (
                  <div>
                    {formProps.values.productSizes.map((_pS, i) => (
                      <div>
                        <FastField name={`productSizes[${i}].type`}>
                          {(props: FieldProps) => (
                            <AppTextField {...props} select label="Type">
                              <MenuItem
                                key={ProductTypes.Cut}
                                value={ProductTypes.Cut}
                              >
                                Cut
                              </MenuItem>
                              <MenuItem
                                key={ProductTypes.Tray}
                                value={ProductTypes.Tray}
                              >
                                Live Tray
                              </MenuItem>
                            </AppTextField>
                          )}
                        </FastField>
                        {formProps.values.productSizes[i].type && (
                          <div>
                            {formProps.values.productSizes[i].type ===
                              ProductTypes.Cut && (
                              <div>
                                <FastField name={`productSizes[${i}].sizeId`}>
                                  {(fieldProps: FieldProps) => (
                                    <AppTextField
                                      {...fieldProps}
                                      select
                                      label="Size"
                                    >
                                      {props.sizes.map((size) => (
                                        <MenuItem key={size.id} value={size.id}>
                                          {size.name}
                                        </MenuItem>
                                      ))}
                                    </AppTextField>
                                  )}
                                </FastField>
                                <FastField
                                  component={AppTextField}
                                  name={`productSizes[${i}].weight`}
                                  label={`Weight (${unitSystemLabel(grower)})`}
                                />
                              </div>
                            )}
                            <FastField name={`productSizes[${i}].price`}>
                              {(fieldProps: FieldProps) => (
                                <AppTextField
                                  {...fieldProps}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {
                                          getAllInfoByISO(grower.CountryISO)
                                            .symbol
                                        }
                                      </InputAdornment>
                                    ),
                                  }}
                                  label="Price"
                                />
                              )}
                            </FastField>
                          </div>
                        )}
                        <Box mt={2} className={classes.actions}>
                          <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => arrayHelpers.remove(i)}
                          >
                            Remove Size
                          </Button>
                        </Box>

                        <Divider />
                      </div>
                    ))}
                    <Box mt={2} className={classes.actions}>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() =>
                          arrayHelpers.push({
                            id: 0,
                            sizeId: "",
                            weight: "",
                            price: "",
                          })
                        }
                      >
                        Add Size
                      </Button>
                    </Box>
                    <FastField name="notes">
                      {(fieldProps: FieldProps) => (
                        <AppTextField
                          {...fieldProps}
                          multiline
                          label="Notes"
                          maxRows={4}
                          rows={4}
                        />
                      )}
                    </FastField>
                  </div>
                )}
              />
            </div>
            <div>{props.children}</div>
          </Form>
        </>
      )}
    </Formik>
  );
}
