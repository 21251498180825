import { apiProvider, baseURL } from "./utilities/provider";
import { JsonDocument } from "./utilities/json";
import { DateTime } from "luxon";

export interface Delivery {
  customerId: number;
  customerName: string;
  customerAddressLine1: string;
  customerDeliveryRoute: string;
  productName: string;
  productSize: string;
  productWeight?: number;
  quantity: number;
  price: number;
}

export const deliveriesApi = {
  getAll: (date: DateTime): Promise<Delivery[]> =>
    apiProvider
      .getAll("deliveries", { date: date.toISODate() })
      .then((d: JsonDocument<Delivery[]>) => d.data),
  getPdf: (date: DateTime): void => {
    window.open(`${baseURL}deliveries/pdf?date=${date.toISODate()}`);
  },
};
