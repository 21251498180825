import { apiProvider } from "./utilities/provider";
import { JsonDocument } from "./utilities/json";
import { CustomerType } from "../../models/customer-type";

const resourceName = "customer-types";

export const customerTypesApi = {
  getAll: (): Promise<CustomerType[]> =>
    apiProvider
      .getAll(resourceName)
      .then((doc: JsonDocument<CustomerType[]>) => doc.data),
};
