export enum validationMessages {
  Required = "Required",
  Positive = "Must be a positive number",
  Integer = "Must be an integer",
  Number = "Must be a number",
  Date = "Must be a date",
  Min = "Must be at least ${min}", // eslint-disable-line no-template-curly-in-string
  Max = "Must be no more than ${max}", // eslint-disable-line no-template-curly-in-string
  Email = "Must be an email",
  String = "Must be a string",
}
