import { apiProvider } from "./utilities/provider";
import { User } from "../../models/user";
import { JsonDocument } from "./utilities/json";

export const usersApi = {
  create: (name: string, email: string, password: string) =>
    apiProvider.post("users", { name, email, password }),
  get: (): Promise<User> =>
    apiProvider.getAll("users").then((d: JsonDocument<User>) => d.data),
};
