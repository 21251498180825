import { apiProvider } from "./utilities/provider";
import { Customer } from "../../models/customer";
import { JsonDocument } from "./utilities/json";

const resourceName = "customers";

export const customersApi = {
  add: (data: Customer): Promise<void> =>
    apiProvider.post(resourceName, {
      data,
    }),
  getAll: (): Promise<Customer[]> =>
    apiProvider
      .getAll(resourceName)
      .then((doc: JsonDocument<Customer[]>) => doc.data),
  getSingle: (id: number): Promise<Customer> =>
    apiProvider
      .getSingle(resourceName, id)
      .then((json: JsonDocument<Customer>) => json.data),
  update: (id: number, data: Customer): Promise<void> =>
    apiProvider.put(resourceName, id, { data }),
  trash: (id: number): Promise<void> =>
    apiProvider.remove(resourceName, id).then(() => {
      return;
    }),
};
