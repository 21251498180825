import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import LoadingBackdrop from "../components/loading-backdrop";
import { Customer } from "../models/customer";
import { customersApi } from "../services/api/customers";
import Page from "../components/page";
import { CustomerType } from "../models/customer-type";
import { customerTypesApi } from "../services/api/customer-types";
import CustomerForm, { CustomerFormValues } from "../components/customer-form";
import { ErrorContext } from "../components/app-routes";
import { DateTime } from "luxon";
import mixpanel from "mixpanel-browser";
import { goBackOrTo } from "../utils/history";

const useStyles = makeStyles((theme) => ({
  actions: {
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

export default function CustomerPage() {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [busy, setBusy] = useState(false);
  const [data, setData] = useState<{
    customers: Customer[];
    customer: Customer;
    customerTypes: CustomerType[];
  } | null>(null);
  const [saved, setSaved] = useState(false);
  const { handleError } = useContext(ErrorContext);

  useEffect(() => {
    async function getData() {
      setBusy(true);
      try {
        const [customers, customerTypes] = await Promise.all([
          customersApi.getAll(),
          customerTypesApi.getAll(),
        ]);
        const customer = customers.find((c) => c.id === parseInt(id));
        if (!customer) {
          throw new Error("Invalid state: customer not found");
        }
        setData({ customers, customer, customerTypes });
      } catch (error) {
        handleError(error);
      } finally {
        setBusy(false);
      }
    }
    getData();
  }, [id]);

  const handleSubmit = async (values: CustomerFormValues) => {
    if (!data?.customer) {
      throw new Error("Invalid state");
    }

    setBusy(true);

    try {
      await customersApi.update(data.customer.id, {
        id: data.customer.id,
        name: values.name,
        typeId: parseInt(values.typeId),
        contactFirstName: values.contactFirstName,
        contactLastName: values.contactLastName,
        contactEmail: values.contactEmail,
        contactPhoneNumber: values.contactPhoneNumber,
        businessEmail: values.businessEmail,
        businessPhoneNumber: values.businessPhoneNumber,
        otherPhoneNumber: values.otherPhoneNumber,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        locality: values.locality,
        postalCode: values.postalCode,
        region: values.region,
        country: values.country,
        website: values.website,
        generalComments: values.generalComments,
        deliveryInstructions: values.deliveryInstructions,
        paymentInstructions: values.paymentInstructions,
        deliveryRoute: values.deliveryRoute,
        archivedAt:
          !!data.customer.archivedAt === values.archived
            ? data.customer.archivedAt
            : values.archived
            ? DateTime.now().toISO()
            : null,
      });

      setSaved(true);

      mixpanel.track("Update Customer");

      goBackOrTo(history, "/home/customers");
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  return (
    <Page title={"Customer"}>
      {data && (
        <CustomerForm
          customers={data.customers}
          customerTypes={data.customerTypes}
          initialValues={{
            name: data.customer.name,
            typeId: data.customer.typeId.toString(),
            contactFirstName: data.customer.contactFirstName,
            contactLastName: data.customer.contactLastName,
            contactEmail: data.customer.contactEmail,
            contactPhoneNumber: data.customer.contactPhoneNumber,
            businessEmail: data.customer.businessEmail,
            businessPhoneNumber: data.customer.businessPhoneNumber,
            otherPhoneNumber: data.customer.otherPhoneNumber,
            addressLine1: data.customer.addressLine1,
            addressLine2: data.customer.addressLine2,
            locality: data.customer.locality,
            postalCode: data.customer.postalCode,
            region: data.customer.region,
            country: data.customer.country,
            website: data.customer.website,
            generalComments: data.customer.generalComments,
            deliveryInstructions: data.customer.deliveryInstructions,
            paymentInstructions: data.customer.paymentInstructions,
            deliveryRoute: data.customer.deliveryRoute,
            archived: data.customer.archivedAt !== null,
          }}
          onSubmit={handleSubmit}
          saved={saved}
        >
          <Box mt={2} className={classes.actions}>
            <Button
              variant="outlined"
              component={Link}
              to="/home/customers"
              disabled={busy}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={busy}
            >
              Save
            </Button>
          </Box>
        </CustomerForm>
      )}
      <LoadingBackdrop open={busy} />
    </Page>
  );
}
