import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import React, { useState, useContext, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { cropsApi } from "../services/api/crops";
import { Box } from "@mui/material";
import LoadingBackdrop from "./loading-backdrop";
import CropForm, {
  CropFormValues,
  convertToUnitSystem,
  otherUnitSystem,
} from "./crop-form";
import Page from "./page";
import { ErrorContext, UserContext } from "./app-routes";
import { Seed } from "../models/seed";
import { seedsApi } from "../services/api/seeds";
import { DateTime } from "luxon";
import mixpanel, { init } from "mixpanel-browser";
import { goBackOrTo } from "../utils/history";
import { Crop } from "../models/crop";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
  },
  actions: {
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

export default function AddCrop() {
  const history = useHistory();
  const classes = useStyles();
  const [busy, setBusy] = useState(false);
  const [saved, setSaved] = useState(false);
  const { handleError } = useContext(ErrorContext);
  const [seeds, setSeeds] = useState<Seed[] | undefined>(undefined);
  const { state } = useLocation<{ crop: Crop }>();
  const context = useContext(UserContext);
  const grower = context.grower;

  if (!grower) {
    throw new Error("Invalid context");
  }

  const initialValues: CropFormValues = state
    ? {
        name: state.crop.name,
        seedId: state.crop.seedId,
        seedingRate: state.crop.seedingRate.toString(),
        seedingRateOther: convertToUnitSystem(
          otherUnitSystem(grower.UnitSystem),
          state.crop.seedingRate
        ).toFixed(2),
        overnightSoak: state.crop.overnightSoak,
        soakTimeMinutes: state.crop.soakTimeMinutes.toString(),
        germinationDays: state.crop.germinationDays.toString(),
        blackoutDays: state.crop.blackoutDays.toString(),
        daysToMaturity: state.crop.daysToMaturity.toString(),
        cutYield: state.crop.cutYield?.toString(),
        notes: state.crop.notes,
        archived: !!state.crop.archivedAt,
      }
    : {
        name: "",
        seedId: 0,
        seedingRate: "",
        seedingRateOther: "",
        overnightSoak: false,
        soakTimeMinutes: "",
        germinationDays: "",
        blackoutDays: "",
        daysToMaturity: "",
        cutYield: "",
        notes: "",
        archived: false,
      };

  useEffect(() => {
    async function load() {
      setBusy(true);
      try {
        const seeds = await seedsApi.getAll();
        setSeeds(seeds);
      } catch (error) {
        handleError(error);
      } finally {
        setBusy(false);
      }
    }
    load();
  }, []);

  return (
    <Page title="Add Crop">
      {seeds && (
        <CropForm
          initialValues={initialValues}
          seeds={seeds}
          onSubmit={(values) => {
            setBusy(true);

            cropsApi
              .add({
                id: 0,
                name: values.name,
                seedId: values.seedId,
                seedingRate: parseFloat(values.seedingRate),
                overnightSoak: values.overnightSoak,
                soakTimeMinutes: parseInt(values.soakTimeMinutes),
                germinationDays: parseInt(values.germinationDays),
                blackoutDays: parseInt(values.blackoutDays),
                daysToMaturity: parseInt(values.daysToMaturity),
                cutYield: values.cutYield
                  ? parseFloat(values.cutYield)
                  : undefined,
                notes: values.notes,
                archivedAt: values.archived ? DateTime.now().toISO() : null,
              })
              .then(() => {
                mixpanel.track("Add Crop");

                setSaved(true);

                history.replace("/home/crops");
              })
              .catch((error) => {
                handleError(error);
              })
              .finally(() => {
                setBusy(false);
              });
          }}
          saved={saved}
        >
          <Box mt={2} className={classes.actions}>
            <Button
              variant="outlined"
              disabled={busy}
              onClick={() => goBackOrTo(history, "/home/crops")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={busy}
            >
              Add
            </Button>
          </Box>
        </CropForm>
      )}
      <LoadingBackdrop open={busy} />
    </Page>
  );
}
