import { Button, CircularProgress, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState, useCallback, useContext } from "react";
import { Customer } from "../models/customer";
import { customersApi } from "../services/api/customers";
import LoadingBackdrop from "../components/loading-backdrop";
import {
  DataGrid,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridDensity,
  GridInitialState,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridToolbar,
  useGridApiRef,
} from "@mui/x-data-grid";
import { Link, useHistory } from "react-router-dom";
import AppPage from "../components/page";
import ConfirmDialog from "../components/confirm-dialog";
import DeleteIcon from "@mui/icons-material/Delete";
import { ErrorContext, UserContext } from "../components/app-routes";
import { ActiveFilterControls } from "../components/active-filter-controls";
import mixpanel from "mixpanel-browser";
import { UpgradePlanDialog } from "../components/upgrade-plan-dialog";
import { getSearchParamNumber } from "../utils/history";
import { StripedDataGrid } from "../components/striped-data-grid";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: "750px",
    width: "100%",
    paddingTop: theme.spacing(2),
  },
}));

const customersGridStateStorageKey = "customersGridState";
const customersGridDensityStorageKey = "customersGridDensity";

const columns: GridColDef[] = [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    hideable: false,
    headerName: "Checkbox",
  },
  {
    field: "name",
    headerName: "Name",
    width: 200,
  },
  {
    field: "type",
    headerName: "Type",
    width: 200,
  },
  {
    field: "contactName",
    headerName: "Contact Name",
    width: 200,
  },
  {
    field: "contactEmail",
    headerName: "Email",
    width: 200,
    renderCell: (params: GridRenderCellParams) => (
      <a target="_blank" href={`mailto:${params.value}`}>
        {params.value}
      </a>
    ),
  },
  {
    field: "addressLine1",
    headerName: "Address Line 1",
    width: 150,
  },
  {
    field: "addressLine2",
    headerName: "Address Line 2",
    width: 150,
  },
  {
    field: "locality",
    headerName: "City",
    width: 150,
  },
  {
    field: "region",
    headerName: "Province / State",
    width: 150,
  },
  {
    field: "country",
    headerName: "Country",
    width: 150,
  },
  {
    field: "postalCode",
    headerName: "Postal Code",
    width: 150,
  },
  {
    field: "businessEmail",
    headerName: "Business Email",
    width: 150,
    renderCell: (params: GridRenderCellParams) => (
      <a target="_blank" href={`mailto:${params.value}`}>
        {params.value}
      </a>
    ),
  },
  {
    field: "businessPhone",
    headerName: "Business Phone",
    width: 150,
  },
  {
    field: "contactFirstName",
    headerName: "Contact First Name",
    width: 150,
  },
  {
    field: "contactLastName",
    headerName: "Contact Last Name",
    width: 150,
  },
  {
    field: "contactPhoneNumber",
    headerName: "Contact Phone Number",
    width: 150,
  },
  {
    field: "deliveryInstructions",
    headerName: "Delivery Instructions",
    width: 150,
  },
  {
    field: "deliveryRoute",
    headerName: "Delivery Route",
    width: 150,
  },
  {
    field: "generalComments",
    headerName: "General Comments",
    width: 150,
  },
  {
    field: "otherPhoneNumber",
    headerName: "Other Phone Number",
    width: 150,
  },
  {
    field: "paymentInstructions",
    headerName: "Payment Instructions",
    width: 150,
  },
  {
    field: "website",
    headerName: "Website",
    width: 150,
    renderCell: (params: GridRenderCellParams) => (
      <a target="_blank" href={`${params.value}`}>
        {params.value}
      </a>
    ),
  },
];

const CustomersDataGrid = React.memo(
  (props: {
    customers: Customer[] | null | undefined;
    onSelectionChange: (params: GridRowSelectionModel) => void;
    onPaginationModelChange: (m: GridPaginationModel) => void;
    initialPage?: number;
  }) => {
    const history = useHistory();
    const apiRef = useGridApiRef();
    const [initialState, setInitialState] = React.useState<GridInitialState>();

    const saveSnapshot = React.useCallback(() => {
      if (apiRef?.current?.exportState) {
        const currentState = apiRef.current.exportState();
        localStorage.setItem(
          customersGridStateStorageKey,
          JSON.stringify(currentState)
        );
      }
    }, [apiRef]);

    React.useLayoutEffect(() => {
      const stateFromLocalStorage = localStorage?.getItem(
        customersGridStateStorageKey
      );
      setInitialState(
        stateFromLocalStorage
          ? JSON.parse(stateFromLocalStorage)
          : {
              columns: {
                columnVisibilityModel: {
                  addressLine1: false,
                  addressLine2: false,
                  locality: false,
                  region: false,
                  country: false,
                  postalCode: false,
                  businessEmail: false,
                  businessPhone: false,
                  contactFirstName: false,
                  contactLastName: false,
                  contactPhoneNumber: false,
                  deliveryInstructions: false,
                  deliveryRoute: false,
                  generalComments: false,
                  otherPhoneNumber: false,
                  paymentInstructions: false,
                  website: false,
                },
              },
            }
      );

      // handle refresh and navigating away/refreshing
      window.addEventListener("beforeunload", saveSnapshot);

      return () => {
        // in case of an SPA remove the event-listener
        window.removeEventListener("beforeunload", saveSnapshot);
        saveSnapshot();
      };
    }, [saveSnapshot]);

    const density: GridDensity =
      (localStorage.getItem(customersGridDensityStorageKey) as GridDensity) ||
      "standard";

    if (!props.customers) return null;

    if (!initialState) {
      return <CircularProgress />;
    }

    return (
      <StripedDataGrid
        apiRef={apiRef}
        columns={columns}
        rows={props.customers.map((c) => ({
          id: c.id,
          name: c.name,
          type: c.type?.name,
          contactName: `${c.contactFirstName} ${c.contactLastName}`,
          contactEmail: c.contactEmail,
          addressLine1: c.addressLine1,
          addressLine2: c.addressLine2,
          locality: c.locality,
          region: c.region,
          country: c.country,
          postalCode: c.postalCode,
          businessEmail: c.businessEmail,
          businessPhone: c.businessPhoneNumber,
          contactFirstName: c.contactFirstName,
          contactLastName: c.contactLastName,
          contactPhoneNumber: c.contactPhoneNumber,
          deliveryInstructions: c.deliveryInstructions,
          deliveryRoute: c.deliveryRoute,
          generalComments: c.generalComments,
          otherPhoneNumber: c.otherPhoneNumber,
          paymentInstructions: c.paymentInstructions,
          website: c.website,
        }))}
        initialState={{
          ...initialState,
          filter: undefined,
          sorting: {
            sortModel: [
              {
                field: "name",
                sort: "asc",
              },
            ],
          },
          pagination: {
            paginationModel: {
              page: props.initialPage,
            },
          },
        }}
        checkboxSelection
        onRowSelectionModelChange={props.onSelectionChange}
        autoPageSize={true}
        onPaginationModelChange={props.onPaginationModelChange}
        slots={{
          toolbar: GridToolbar,
        }}
        density={density}
        onStateChange={({ density }) => {
          localStorage.setItem(customersGridDensityStorageKey, density.value);
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            csvOptions: {
              allColumns: true,
            },
          },
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        disableRowSelectionOnClick
        onRowClick={(params, event) => {
          if (event.target instanceof HTMLAnchorElement) {
            return;
          }
          history.push(`/home/customers/${params.id}`);
        }}
      />
    );
  }
);

export default function CustomersPage() {
  const classes = useStyles();
  const [customers, setCustomers] = useState<Customer[] | null>(null);
  const [changeId, setChangeId] = React.useState(0);
  const [busy, setBusy] = useState(false);
  const [selected, setSelected] = useState<number[]>([]);
  const [trashDialogOpen, setTrashDialogOpen] = useState(false);
  const { handleError } = useContext(ErrorContext);
  const showArchivedCheckedStorageKey = "customersShowArchivedChecked";
  const showActiveCheckedStorageKey = "customersShowActiveChecked";
  const [showArchivedChecked, setShowArchivedChecked] = useState(
    window.localStorage.getItem(showArchivedCheckedStorageKey) !==
      false.toString()
  );
  const [showActiveChecked, setShowActiveChecked] = useState(
    window.localStorage.getItem(showActiveCheckedStorageKey) !==
      false.toString()
  );
  const { grower } = useContext(UserContext);
  const [openUpgradePlanDialog, setOpenUpgradePlanDialog] = useState(false);
  const history = useHistory();

  if (!grower) {
    throw new Error("Invalid context");
  }

  const onClickCancelUpgradePlan = () => {
    setOpenUpgradePlanDialog(false);
  };

  const handleTrashDialogClose = async (okTrash?: boolean) => {
    setTrashDialogOpen(false);

    if (!okTrash) {
      return;
    }

    setBusy(true);

    let trashed = 0;
    for (let i = 0; i < selected.length; i++) {
      try {
        await customersApi.trash(selected[i]);

        trashed++;

        mixpanel.track("Delete Customer");
      } catch (error) {
        handleError(error);
        break;
      }
    }

    setChangeId(changeId + trashed);
    setBusy(false);
  };

  useEffect(() => {
    async function getCustomers() {
      setBusy(true);
      try {
        const customers = await customersApi.getAll();
        setCustomers(customers);
      } catch (error) {
        handleError(error);
      } finally {
        setBusy(false);
      }
    }
    getCustomers();
  }, [changeId]);

  const handleSelectionChange = useCallback((params: GridRowSelectionModel) => {
    setSelected(params as number[]);
  }, []);

  const handleShowActiveChanged = (checked: boolean) => {
    setShowActiveChecked(checked);
    window.localStorage.setItem(
      showActiveCheckedStorageKey,
      checked.toString()
    );
  };

  const handleShowArchivedChanged = (checked: boolean) => {
    setShowArchivedChecked(checked);
    window.localStorage.setItem(
      showArchivedCheckedStorageKey,
      checked.toString()
    );
  };

  const handleOnClickAdd = () => {
    if (grower.Plan === "hobby" && customers && customers.length >= 5) {
      setOpenUpgradePlanDialog(true);
      return;
    }
    history.push("/home/customers/add");
  };

  const page = getSearchParamNumber(history, "page", 0);

  return (
    <AppPage title={"Customers"}>
      <div>
        <Button variant="contained" color="primary" onClick={handleOnClickAdd}>
          Add
        </Button>
        <IconButton
          aria-label="trash"
          disabled={busy || selected.length === 0}
          onClick={() => setTrashDialogOpen(true)}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
        <ActiveFilterControls
          showActiveChecked={showActiveChecked}
          showArchivedChecked={showArchivedChecked}
          onChangeShowActive={handleShowActiveChanged}
          onChangeShowArchived={handleShowArchivedChanged}
        />
      </div>

      <div className={classes.gridContainer}>
        <CustomersDataGrid
          customers={customers?.filter(
            (c) =>
              (showActiveChecked && c.archivedAt === null) ||
              (showArchivedChecked && c.archivedAt !== null)
          )}
          onSelectionChange={handleSelectionChange}
          initialPage={page}
          onPaginationModelChange={(m) => {
            const params = new URLSearchParams(`page=${m.page}`);
            history.replace({
              search: params.toString(),
            });
          }}
        />
      </div>
      <LoadingBackdrop open={busy} />
      <ConfirmDialog
        title={`Trash Customer${selected.length > 1 ? "s" : ""}`}
        description={`Are you sure you want to trash ${
          selected.length
        } customer${selected.length > 1 ? "s" : ""}?`}
        open={trashDialogOpen}
        onClose={handleTrashDialogClose}
      ></ConfirmDialog>
      <UpgradePlanDialog
        open={openUpgradePlanDialog}
        onClickCancel={onClickCancelUpgradePlan}
        text="Sorry, the Hobby Plan is limited to 5 customers."
      />
    </AppPage>
  );
}
