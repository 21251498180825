import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link as RouterLink } from "react-router-dom";
import { Icon } from "@mui/material";

export function ListItemLink(props: {
  icon: JSX.Element;
  iconEnd?: JSX.Element;
  to: string;
  primary: React.ReactNode;
  onClick: () => void;
}) {
  const { icon, iconEnd, primary, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref: any) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <ListItem button onClick={props.onClick} component={renderLink as any}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={primary} />
      {iconEnd ? <Icon>{iconEnd}</Icon> : null}
    </ListItem>
  );
}
