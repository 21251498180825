import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import LoadingBackdrop from "../components/loading-backdrop";
import { AxiosError } from "axios";
import { ErrorContext } from "../components/app-routes";
import { emailChangeApi } from "../services/api/email-change";
import mixpanel from "mixpanel-browser";

export function EmailChangePage(props: { onComplete: () => void }) {
  const { token } = useParams<{ token: string }>();
  const [busy, setBusy] = useState(false);
  const [result, setResult] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const { handleError } = useContext(ErrorContext);

  useEffect(() => {
    const changeEmail = async () => {
      setBusy(true);
      try {
        await emailChangeApi.confirm(token);
        mixpanel.track("Confirm Email Change");
        setResult(
          "Thanks, your email has been changed.  Click OK to continue."
        );
      } catch (error) {
        if ((error as AxiosError)?.isAxiosError) {
          const axiosError = error as AxiosError;
          if (axiosError.response?.status === 404) {
            setResult(
              "Sorry, something went wrong changing the email address.  It might have already been changed, or you might need to start the process again.  Click OK to continue."
            );
            return;
          }
        }
        setResult("Sorry, something went wrong.  Please try again");
        handleError(error);
      } finally {
        setBusy(false);
        setOpenDialog(true);
      }
    };
    changeEmail();
  }, [token]);

  const handleClose = () => {
    props.onComplete();
  };

  return (
    <Container maxWidth="xs">
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Email Change</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {result}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop open={busy} />
    </Container>
  );
}
