import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, {
  ChangeEvent,
  ChangeEventHandler,
  useContext,
  useEffect,
} from "react";
import {
  Formik,
  Form,
  FastField,
  FieldProps,
  useFormikContext,
  useField,
  Field,
} from "formik";
import * as yup from "yup";
import {
  FormControl,
  FormHelperText,
  Box,
  MenuItem,
  TextField,
} from "@mui/material";
import { AppTextField } from "./text-field";
import { validationMessages as vm } from "../utils/validation/messages";
import { unitSystemLabel, unitSystemLabelOther } from "../pages/crops-page";
import { UserContext } from "./app-routes";
import { FormPrompt } from "./form-prompt";
import { Seed } from "../models/seed";
import { Grower, UnitSystem } from "../models/grower";

const cropValidationScheme = yup.object({
  name: yup.string().trim().required(vm.Required),
  seedId: yup
    .number()
    .typeError("Select a seed")
    .required(vm.Required)
    .min(1, vm.Required),
  seedingRate: yup
    .number()
    .typeError(vm.Number)
    .positive(vm.Positive)
    .required(vm.Required),
  seedingRateOther: yup.number().typeError(vm.Number).positive(vm.Positive),
  overnightSoak: yup.boolean().required(vm.Required),
  soakTimeMinutes: yup
    .number()
    .typeError(vm.Number)
    .integer(vm.Integer)
    .min(0, vm.Min)
    .required(vm.Required),
  germinationDays: yup
    .number()
    .typeError(vm.Number)
    .integer(vm.Integer)
    .positive(vm.Positive)
    .required(vm.Required),
  blackoutDays: yup
    .number()
    .typeError(vm.Number)
    .integer(vm.Integer)
    .min(0, vm.Min)
    .required(vm.Required),
  daysToMaturity: yup
    .number()
    .typeError(vm.Number)
    .integer(vm.Integer)
    .positive(vm.Positive)
    .required(vm.Required),
  cutYield: yup.number().typeError(vm.Number).positive(vm.Positive),
  notes: yup.string().max(5000),
  archived: yup.boolean().required(vm.Required),
});

export interface CropFormValues {
  name: string;
  seedId: number;
  seedingRate: string;
  seedingRateOther: string;
  overnightSoak: boolean;
  soakTimeMinutes: string;
  germinationDays: string;
  blackoutDays: string;
  daysToMaturity: string;
  cutYield?: string;
  notes: string;
  archived: boolean;
}

export const gramsToOz = (grams: number): number => {
  return grams / 28.35;
};

export const ozToGrams = (oz: number): number => {
  return oz * 28.35;
};

export const convertToUnitSystem = (
  unitSystem: UnitSystem,
  value: number
): number => {
  return unitSystem === "metric" ? ozToGrams(value) : gramsToOz(value);
};

export const otherUnitSystem = (unitSystem: UnitSystem): UnitSystem => {
  return unitSystem === "imperial" ? "metric" : "imperial";
};

const SeedingRateField = () => {
  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleChange,
  } = useFormikContext<CropFormValues>();

  const context = useContext(UserContext);
  const grower = context.grower;

  if (!grower) {
    throw new Error("Invalid context");
  }

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleChange(e);

    let seedingRateOther: string;

    const seedingRate = parseFloat(e.target.value);

    if (Number.isNaN(seedingRate)) {
      seedingRateOther = "";
    } else {
      seedingRateOther = convertToUnitSystem(
        otherUnitSystem(grower.UnitSystem),
        seedingRate
      ).toFixed(2);
    }

    setFieldValue("seedingRateOther", seedingRateOther, false);
  };

  return (
    <Box mt={2}>
      <TextField
        id="seedingRate"
        name="seedingRate"
        label={`Seeding Rate (${unitSystemLabel(grower)})`}
        value={values.seedingRate}
        fullWidth
        variant="outlined"
        error={touched.seedingRate && Boolean(errors.seedingRate)}
        helperText={touched.seedingRate && errors.seedingRate}
        onChange={onChange}
      />
    </Box>
  );
};

const SeedingRateOtherField = () => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
  } = useFormikContext<CropFormValues>();

  const context = useContext(UserContext);
  const grower = context.grower;

  if (!grower) {
    throw new Error("Invalid context");
  }

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleChange(e);

    let seeingRateFieldValue: string;

    const seedingRateOther = parseFloat(e.target.value);

    if (Number.isNaN(seedingRateOther)) {
      seeingRateFieldValue = "";
    } else {
      seeingRateFieldValue = convertToUnitSystem(
        grower.UnitSystem,
        seedingRateOther
      ).toFixed(2);
    }

    setFieldValue("seedingRate", seeingRateFieldValue, false);
  };

  return (
    <Box mt={2}>
      <TextField
        id="seedingRateOther"
        name="seedingRateOther"
        label={`Seeding Rate (${unitSystemLabelOther(grower)})`}
        value={values.seedingRateOther}
        fullWidth
        variant="outlined"
        error={touched.seedingRateOther && Boolean(errors.seedingRateOther)}
        helperText={touched.seedingRateOther && errors.seedingRateOther}
        onChange={onChange}
      />
    </Box>
  );
};

export default function CropForm(props: {
  children: JSX.Element;
  initialValues: CropFormValues;
  saved: boolean;
  onSubmit: (values: CropFormValues) => void;
  seeds: Seed[];
}) {
  const context = useContext(UserContext);
  const grower = context.grower;

  if (!grower) {
    throw new Error("Invalid context");
  }

  const seedOptionLabel = (seed?: Seed) =>
    seed ? `${seed.name} ${seed.variety ? " - " + seed.variety : ""}` : "";

  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={cropValidationScheme}
      onSubmit={props.onSubmit}
    >
      {(form) => (
        <>
          <FormPrompt form={form} saved={props.saved} />
          <Form noValidate autoComplete="off">
            <Box>
              <FormControl
                variant="standard"
                error={form.touched.archived && Boolean(form.errors.archived)}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="archived"
                      color="primary"
                      onChange={form.handleChange}
                      checked={form.values.archived}
                    />
                  }
                  label="Archived"
                />
                {form.touched.archived && form.errors.archived && (
                  <FormHelperText>{form.errors.archived}</FormHelperText>
                )}
              </FormControl>
            </Box>
            <FastField name="name">
              {(fieldProps: FieldProps) => (
                <AppTextField {...fieldProps} autoFocus label="Crop Name" />
              )}
            </FastField>

            <FastField name={`seedId`}>
              {(fieldProps: FieldProps) => (
                <AppTextField {...fieldProps} select label="Seed">
                  {props.seeds
                    .filter(
                      (s) =>
                        s.archivedAt === null ||
                        props.initialValues.seedId === s.id
                    )
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((v) => (
                      <MenuItem key={v.id} value={v.id}>
                        {seedOptionLabel(v)}
                      </MenuItem>
                    ))}
                </AppTextField>
              )}
            </FastField>

            <SeedingRateField />
            <SeedingRateOtherField />

            <FastField
              component={AppTextField}
              name="soakTimeMinutes"
              label="Soak Time (Minutes)"
            />
            <FastField
              component={AppTextField}
              name="germinationDays"
              label="Days to Germinate"
            />
            <FastField
              component={AppTextField}
              name="blackoutDays"
              label="Days in Blackout"
            />
            <FastField
              component={AppTextField}
              name="daysToMaturity"
              label="Days to Maturity"
            />
            <FastField
              component={AppTextField}
              name="cutYield"
              label={`Cut Yield (${unitSystemLabel(grower)})`}
            />
            <Box mt={2}>
              <FormControl
                variant="standard"
                error={
                  form.touched.overnightSoak &&
                  Boolean(form.errors.overnightSoak)
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="overnightSoak"
                      color="primary"
                      onChange={form.handleChange}
                      checked={form.values.overnightSoak}
                    />
                  }
                  label="Overnight Soak"
                />
                {form.touched.overnightSoak && form.errors.overnightSoak && (
                  <FormHelperText>{form.errors.overnightSoak}</FormHelperText>
                )}
              </FormControl>
            </Box>
            <FastField name="notes">
              {(fieldProps: FieldProps) => (
                <AppTextField
                  {...fieldProps}
                  multiline
                  label="Notes"
                  maxRows={4}
                  rows={4}
                />
              )}
            </FastField>
            <div>{props.children}</div>
          </Form>
        </>
      )}
    </Formik>
  );
}
