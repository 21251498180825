export function goBackOrTo(history: any, url: string) {
  if (history.length > 1) {
    history.goBack()
  } else {
    history.replace(url);
  }
}

export function getSearchParam(history: any, name: string): string | null {
  const search = new URLSearchParams(history.location?.search);
  return search.get(name);
}

export function getSearchParamNumber(history: any, name: string, defaultValue: number): number {
  let value = getSearchParam(history, name);
  if (value) {
    return parseInt(value);
  }
  return defaultValue;
}
