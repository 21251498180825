import { FieldProps, getIn } from "formik";
import { TextFieldProps, TextField, Box } from "@mui/material";
import React from "react";

export const AppTextField: React.FC<FieldProps & TextFieldProps> = (props) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { error, helperText, field, form, ...rest } = props;

  return (
    <Box mt={2}>
      <TextField
        fullWidth
        variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={
          helperText ?? (isTouched && errorMessage ? errorMessage : undefined)
        }
        {...rest}
        {...field}
      />
    </Box>
  );
};
