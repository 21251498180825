import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Checkbox,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { usersApi } from "../services/api/users";
import { LogoHeader } from "../components/logo-header";
import { ErrorContext } from "../components/app-routes";
import LoadingBackdrop from "../components/loading-backdrop";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    textAlign: "center",
  },
  fieldContainer: {
    paddingTop: theme.spacing(2),
  },
}));

export function SignUpPage(props: { onSignUp: () => void }) {
  const classes = useStyles();
  const form = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      terms: false,
    },
    validationSchema: yup.object({
      name: yup.string().required("Name is required"),
      email: yup
        .string()
        .email("Enter a valid email")
        .required("Email is required"),
      password: yup
        .string()
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
      terms: yup.bool().oneOf([true]),
    }),
    onSubmit: () => {
      signUp();
    },
  });
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const { handleError } = useContext(ErrorContext);

  const signUp = async () => {
    setBusy(true);

    try {
      await usersApi.create(
        form.values.name,
        form.values.email,
        form.values.password
      );
      props.onSignUp();
      return;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 409) {
        setError("An account already exists for this email.");
      } else {
        handleError(error);
      }
    } finally {
      setBusy(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <LogoHeader />
      <Paper elevation={3}>
        <div className={classes.formContainer}>
          <Typography gutterBottom variant="h5" component="h2">
            Create your SeedLeaf account
          </Typography>
          <form onSubmit={form.handleSubmit} noValidate>
            <Box mt={2}>
              <TextField
                id="name"
                name="name"
                type="text"
                label="First and Last Name"
                value={form.values.name}
                onChange={form.handleChange}
                fullWidth
                variant="outlined"
                autoFocus
                error={form.touched.name && Boolean(form.errors.name)}
                helperText={form.touched.name && form.errors.name}
              />
            </Box>
            <Box mt={2}>
              <TextField
                id="email"
                name="email"
                type="email"
                label="Email"
                value={form.values.email}
                onChange={form.handleChange}
                fullWidth
                variant="outlined"
                error={form.touched.email && Boolean(form.errors.email)}
                helperText={form.touched.email && form.errors.email}
              />
            </Box>
            <Box mt={2}>
              <TextField
                id="password"
                type="password"
                name="password"
                label="Password"
                value={form.values.password}
                onChange={form.handleChange}
                fullWidth
                variant="outlined"
                error={form.touched.password && Boolean(form.errors.password)}
                helperText={form.touched.password && form.errors.password}
              />
            </Box>
            <Box mt={2} textAlign="left">
              <FormControl
                variant="standard"
                error={form.touched.terms && Boolean(form.errors.terms)}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="terms"
                      color="primary"
                      onChange={form.handleChange}
                      checked={form.values.terms}
                    />
                  }
                  label={
                    <Typography
                      variant="caption"
                      color={
                        form.touched.terms && form.errors.terms
                          ? "error"
                          : "textPrimary"
                      }
                    >
                      I have read, understand and agree to SeedLeaf's{" "}
                      <a
                        target="_blank"
                        href="https://seedleaf.co/terms-of-use/"
                      >
                        Terms of Use
                      </a>{" "}
                      and{" "}
                      <a
                        target="_blank"
                        href="https://seedleaf.co/privacy-policy"
                      >
                        Privacy Policy
                      </a>
                    </Typography>
                  }
                />
              </FormControl>
            </Box>
            {error && (
              <Box mt={2}>
                <Typography variant="body1" color="error">
                  {error}
                </Typography>
              </Box>
            )}
            <Box mt={2}>
              <Button
                variant="contained"
                fullWidth={true}
                color="primary"
                type="submit"
                disabled={busy}
              >
                Sign Up
              </Button>
              <Box m={1}>
                <Typography variant="caption">
                  30-day Business Plan free trial then continue for $15 US/month
                  or switch to the Hobby Plan for free.
                </Typography>
              </Box>
            </Box>
            <Box mt={2}>
              <Typography variant="caption">
                Already have an account? <Link to="/log-in">Log In</Link>
              </Typography>
            </Box>
          </form>
        </div>
      </Paper>
      <LoadingBackdrop open={busy} />
    </Container>
  );
}
