import { apiProvider } from "./utilities/provider";
import { PortalSession } from "../../models/portal-session";
import { JsonDocument } from "./utilities/json";

export const billingApi = {
  createPortalSession: (): Promise<PortalSession> =>
    apiProvider
      .post("billing/portal-sessions")
      .then((d: JsonDocument<PortalSession>) => d.data),
};
