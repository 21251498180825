import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { cropsApi } from "../services/api/crops";
import { Crop } from "../models/crop";
import { Box, useTheme } from "@mui/material";
import LoadingBackdrop from "../components/loading-backdrop";
import { Product } from "../models/product";
import { productsApi, ProductTypes } from "../services/api/products";
import ProductForm, { ProductFormValues } from "../components/product-form";
import Page from "../components/page";
import { Size } from "../models/size";
import { sizesApi } from "../services/api/sizes";
import { formatCurrency, toCurrency } from "./currency";
import { UserContext, ErrorContext } from "../components/app-routes";
import { DateTime } from "luxon";
import mixpanel from "mixpanel-browser";
import { goBackOrTo } from "../utils/history";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const useStyles = makeStyles((theme) => ({
  actions: {
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

export default function ProductPage() {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const [busy, setBusy] = useState(false);
  const [product, setProduct] = useState<Product | null>(null);
  const [crops, setCrops] = useState<Crop[] | null>(null);
  const [productSizes, setProductSizes] = useState<Size[] | null>(null);
  const history = useHistory();
  const [saved, setSaved] = useState(false);
  const { grower } = useContext(UserContext);
  const { handleError } = useContext(ErrorContext);
  const theme = useTheme();

  if (!grower) {
    throw new Error("Invalid UserContext");
  }

  useEffect(() => {
    async function getProduct() {
      setBusy(true);
      try {
        const results = await Promise.all([
          productsApi.getSingle(parseInt(id)),
          cropsApi.getAll(),
          sizesApi.getAll(),
        ]);
        setProduct(results[0]);
        setCrops(results[1]);
        setProductSizes(results[2]);
      } catch (error) {
        handleError(error);
      } finally {
        setBusy(false);
      }
    }
    getProduct();
  }, [id]);

  const handleSubmit = async (values: ProductFormValues) => {
    if (!product) {
      throw new Error("Invalid state");
    }
    setBusy(true);
    try {
      await productsApi.update({
        id: product.id,
        name: values.name,
        productCrops: values.productCrops.map((c) => ({
          id: c.id,
          crop: (null as unknown) as Crop,
          cropId: parseInt(c.cropId),
          blend: parseFloat(c.blend),
        })),
        productSizes: values.productSizes.map((pS) => ({
          id: pS.id,
          type: pS.type as ProductTypes,
          weight: pS.weight ? parseFloat(pS.weight) : undefined,
          sizeId: pS.sizeId ? parseInt(pS.sizeId) : undefined,
          size: (null as unknown) as Size,
          price: toCurrency(pS.price, grower.CountryISO).intValue,
        })),
        notes: values.notes,
        archivedAt:
          !!product.archivedAt === values.archived
            ? product.archivedAt
            : values.archived
            ? DateTime.now().toISO()
            : null,
      });

      setSaved(true);

      mixpanel.track("Update Product");

      goBackOrTo(history, "/home/products");
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  return (
    <Page title={"Product"}>
      {product && crops && productSizes && (
        <>
          <div>
            <Button
              sx={{ marginBottom: theme.spacing(2) }}
              variant="contained"
              color="primary"
              component={Link}
              to={{
                pathname: "/home/products/add",
                state: {
                  product: {
                    ...product,
                    name: `${product.name} (COPY)`,
                  },
                },
              }}
              disabled={busy}
              startIcon={<ContentCopyIcon />}
              onClick={() => mixpanel.track("Duplicate Product")}
            >
              Duplicate
            </Button>
          </div>

          <ProductForm
            initialValues={{
              name: product.name,
              productCrops: product.productCrops.map((c) => ({
                id: c.id,
                cropId: c.crop.id.toString(),
                blend: c.blend.toString(),
              })),
              productSizes: product.productSizes.map((pS) => ({
                id: pS.id,
                type: pS.type,
                price: formatCurrency(pS.price, grower.CountryISO, {
                  symbol: "",
                  separator: "",
                }),
                weight: pS.weight ? pS.weight?.toString() : undefined,
                sizeId: pS.size?.id.toString(),
              })),
              notes: product.notes,
              archived: product.archivedAt !== null,
            }}
            onSubmit={handleSubmit}
            crops={crops}
            sizes={productSizes}
            saved={saved}
          >
            <Box mt={2} className={classes.actions}>
              <Button
                variant="outlined"
                disabled={busy}
                onClick={() => goBackOrTo(history, "/home/products")}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={busy}
              >
                Save
              </Button>
            </Box>
          </ProductForm>
        </>
      )}
      <LoadingBackdrop open={busy} />
    </Page>
  );
}
