import React, { useState, useContext } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Paper,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { LogoHeader } from "../components/logo-header";
import { growersApi } from "../services/api/growers";
import LoadingBackdrop from "../components/loading-backdrop";
import { validationMessages } from "../utils/validation/messages";
import { getAllISOCodes, getAllInfoByISO } from "iso-country-currency";
import { ErrorContext } from "../components/app-routes";
import axios, { AxiosError } from "axios";
import mixpanel from "mixpanel-browser";
import TimeZoneControl from "../components/time-zone-control";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
  },
  formControl: {
    width: "100%",
  },
  header: {
    textAlign: "center",
  },
}));

export function InitialSetupPage(props: { onSetup: () => void }) {
  const [busy, setBusy] = useState(false);
  const { handleError } = useContext(ErrorContext);
  const classes = useStyles();
  const form = useFormik({
    initialValues: {
      growerName: "",
      unitSystem: "",
      country: "",
      promotionCode: "",
      timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    validationSchema: yup.object({
      growerName: yup.string().trim().required(validationMessages.Required),
      unitSystem: yup.string().required(validationMessages.Required),
      country: yup.string().required(validationMessages.Required),
      promotionCode: yup.string().trim(),
      timeZone: yup.string().required(validationMessages.Required),
    }),
    onSubmit: () => {
      setupGrower();
    },
  });

  const setupGrower = async () => {
    setBusy(true);

    const currencyISO = getAllInfoByISO(form.values.country).currency;

    try {
      await growersApi.create({
        name: form.values.growerName,
        unitSystem: form.values.unitSystem,
        countryIso: form.values.country,
        currencyIso: currencyISO,
        promotionCode: form.values.promotionCode,
        timeZone: form.values.timeZone,
      });

      props.onSetup();

      mixpanel.track("Initial Set Up", {
        promotionCode: form.values.promotionCode,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<{ message?: string }>;

        const invalidPromotionCodeError = axiosError.response?.status === 400;

        if (invalidPromotionCodeError) {
          alert(axiosError.response?.data.message || "Invalid promotion code");
          return;
        }
      }
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <LogoHeader />
      <Paper elevation={3}>
        <div className={classes.formContainer}>
          <Typography
            className={classes.header}
            gutterBottom
            variant="h5"
            component="h2"
          >
            Initial Setup
          </Typography>
          <form onSubmit={form.handleSubmit} noValidate>
            <Box mt={2}>
              <TextField
                id="growerName"
                name="growerName"
                type="text"
                label="Farm Name"
                value={form.values.growerName}
                onChange={form.handleChange}
                fullWidth
                variant="outlined"
                autoFocus
                error={
                  form.touched.growerName && Boolean(form.errors.growerName)
                }
                helperText={form.touched.growerName && form.errors.growerName}
              />
            </Box>
            <Box mt={2}>
              <FormControl
                className={classes.formControl}
                variant="outlined"
                error={
                  form.touched.unitSystem && Boolean(form.errors.unitSystem)
                }
              >
                <InputLabel id="unit-system-label">Unit System</InputLabel>
                <Select
                  variant="outlined"
                  labelId="unit-system-label"
                  id="unitSystem"
                  name="unitSystem"
                  value={form.values.unitSystem}
                  onChange={form.handleChange}
                  label="Unit System"
                >
                  <MenuItem value={"metric"}>Metric</MenuItem>
                  <MenuItem value={"imperial"}>Imperial</MenuItem>
                </Select>
                {form.errors.unitSystem &&
                  form.touched.unitSystem &&
                  form.errors.unitSystem && (
                    <FormHelperText>{form.errors.unitSystem}</FormHelperText>
                  )}
              </FormControl>
            </Box>
            <Box mt={2}>
              <FormControl
                className={classes.formControl}
                variant="outlined"
                error={form.touched.country && Boolean(form.errors.country)}
              >
                <InputLabel id="country-label">Country</InputLabel>
                <Select
                  variant="outlined"
                  labelId="country-label"
                  id="country"
                  name="country"
                  value={form.values.country}
                  onChange={form.handleChange}
                  label="Country"
                >
                  {getAllISOCodes()
                    .sort((a, b) => a.countryName.localeCompare(b.countryName))
                    .map((v) => (
                      <MenuItem
                        value={v.iso}
                      >{`${v.countryName} (${v.symbol})`}</MenuItem>
                    ))}
                </Select>
                {form.errors.country &&
                  form.touched.country &&
                  form.errors.country && (
                    <FormHelperText>{form.errors.country}</FormHelperText>
                  )}
              </FormControl>
            </Box>
            <Box mt={2}>
              <TimeZoneControl
                className={classes.formControl}
                value={form.values.timeZone}
                onChange={form.handleChange}
              />
            </Box>
            <Box mt={2}>
              <TextField
                id="promotionCode"
                name="promotionCode"
                type="text"
                label="Promotion Code"
                value={form.values.promotionCode}
                onChange={form.handleChange}
                fullWidth
                variant="outlined"
                error={
                  form.touched.promotionCode &&
                  Boolean(form.errors.promotionCode)
                }
                helperText={
                  form.touched.promotionCode && form.errors.promotionCode
                }
              />
            </Box>
            <Box mt={2} textAlign="center">
              <Button
                fullWidth={true}
                variant="contained"
                color="primary"
                type="submit"
              >
                Setup
              </Button>
            </Box>
          </form>
        </div>
      </Paper>
      <LoadingBackdrop open={busy} />
    </Container>
  );
}
