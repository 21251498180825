import React from "react";
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { Grower } from "../models/grower";

export enum SubscriptionActions {
  DowngradeFree = "downgrade-free",
  RestartFree = "restart-free",
  CheckoutBusiness = "checkout-business",
}

export function SubscriptionErrorDialog(props: {
  onClickGoToBillingPortal: () => void;
  onClickSubscriptionAction: (action: SubscriptionActions) => void;
  onClickLogOut: () => void;
  grower?: Grower;
}) {
  const { grower } = props;

  if (!grower) {
    throw new Error("Invalid props");
  }

  if (
    grower.StripeSubscriptionStatus === "active" ||
    grower.StripeSubscriptionStatus === "trialing" ||
    grower.StripeSubscriptionStatus === "unpaid"
  ) {
    throw new Error("Invalid subscription state");
  }

  let title: string;
  let contextText: JSX.Element;
  let actions: JSX.Element | null;

  switch (grower.StripeSubscriptionStatus) {
    case "paused":
      title = "Your trial has ended.  What's next?";
      contextText = (
        <div>
          Your SeedLeaf trial has ended. If you would like to continue with the
          Business Plan, please add a payment method at the billing portal.
          Otherwise, you can switch to our free Hobby Plan.
          <br />
          <br />
          <div>
            If you have any questions or need help, email us at{" "}
            <a href="mailto:support@seedleaf.co">support@seedleaf.co</a>.
          </div>
        </div>
      );
      actions = (
        <React.Fragment>
          <Button
            onClick={() => {
              props.onClickSubscriptionAction(
                SubscriptionActions.DowngradeFree
              );
            }}
          >
            Downgrade to Hobby
          </Button>
          <Button
            onClick={props.onClickGoToBillingPortal}
            color="primary"
            autoFocus
          >
            Add Payment Method
          </Button>
        </React.Fragment>
      );
      break;
    case "canceled":
      title = "Your subscription has ended.  What's next?";
      contextText = (
        <div>
          Your previous subscription has ended. You can continue with the free
          Hobby Plan or purchase the Business Plan. <br />
          <br />
          <div>
            If you have any questions or need help, email us at{" "}
            <a href="mailto:support@seedleaf.co">support@seedleaf.co</a>.
          </div>
        </div>
      );
      actions = (
        <React.Fragment>
          <Button
            onClick={() => {
              props.onClickSubscriptionAction(SubscriptionActions.RestartFree);
            }}
          >
            Continue with Hobby
          </Button>
          <Button
            onClick={() => {
              props.onClickSubscriptionAction(
                SubscriptionActions.CheckoutBusiness
              );
            }}
            color="primary"
            autoFocus
          >
            Purchase Business
          </Button>
        </React.Fragment>
      );
      break;
    default:
      title = "There's a problem with your subscription.";
      contextText = (
        <div>
          Sorry, your SeedLeaf subscription is having trouble.{" "}
          <div>
            Please email us at{" "}
            <a href="mailto:support@seedleaf.co">support@seedleaf.co</a>.
          </div>
        </div>
      );
      actions = null;
      break;
  }

  return (
    <>
      <Container maxWidth="xs">
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {contextText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClickLogOut}>Log Out</Button>
            {actions}
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
