import React, { useContext, useState } from "react";
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { billingApi } from "../services/api/billing";
import { ErrorContext } from "./app-routes";

export function UpgradePlanDialog(props: {
  open: boolean;
  onClickCancel: () => void;
  text?: string;
}) {
  const { handleError } = useContext(ErrorContext);
  const [busy, setBusy] = useState(false);

  const goToBillingPortal = async () => {
    setBusy(true);
    try {
      const session = await billingApi.createPortalSession();
      window.location.assign(session.Url);
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  const onClickUpgrade = () => {
    goToBillingPortal();
  };

  return (
    <>
      <Container maxWidth="xs">
        <Dialog
          open={props.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Upgrade Plan</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {props.text
                ? props.text
                : "Sorry, this feature is only available with the Business Plan."}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button disabled={busy} onClick={props.onClickCancel}>
              Cancel
            </Button>
            <Button disabled={busy} onClick={onClickUpgrade} color="primary">
              Upgrade
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
